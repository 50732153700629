.root{
    width: 100%;
    border-radius: 10px;
    border-bottom: 1px solid #6d7bb2 ;
    border-right: 1px solid #6d7bb2 ;
    border-left: 1px solid #6d7bb2 ;
    border-top: 1px solid #263571  ;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -3.62%, rgba(255, 255, 255, 0.20) 100%);
    color: #fff ;
    font-weight: 400 ;
    font-size: 14px;
    height: 40px ;
    border-radius: 4px ;
    opacity: 1 ;
    padding-right: 25px ;
    margin-bottom: 10px;
    padding-left: 10px;
    margin-top: 5px;

}

.root:focus {
    background: transparent !important;
    border:1px solid #6B1DA5 !important;
    box-shadow: none!important ;
}

.root:hover {
    background: linear-gradient(0deg, rgba(96, 46, 166, 0.2) 0%, rgba(201, 119, 214, 0.2) 88.64%);
    box-shadow: 0px 0px 10px 0px rgba(118, 61, 176, 1);
    border: 1px solid #4b47b8 ;
}

.loginEyeIcon{
    position: absolute;
    margin-top: 18px;
    margin-left: -40px;
    color:white;
} 

.errorMessage{
    color:#EF7B10;
    font-size: 10px;
    font-weight: 400;
}

.passwordInputWrapper {
    position: relative;
}

.passwordIcon {
    position: absolute;
    top: 10px;
    right: -30px;
}

.passwordIconImage {
    width: 20px;
    height: auto;
    margin-right: 4px;
}
/*conflict-resolution-will be removed later*/