.card {
    background-color: #fff;
    padding: 12px 6px;
    border-radius: 5px;
    cursor: pointer;
}

.cardSelected {
    background-color: #e7ecf7;
}

.fenceName {
    font-size: 0.875rem;
    margin: 0;
    margin-bottom: 4px;
    color: #1f2a5d;
    font-weight: 500;
}

.fenceTypeWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}
.fenceTypeIcon {
    flex: 0 0 22px;
    width: 22px;
    height: auto;
    margin-left: -4px;
}
.fenceTypeLabel {
    flex: 1 1 auto;
    margin: 0;
    color: #1f2a5d;
    font-size: 0.75rem;
    font-weight: 500;
}

.fenceInfoStack {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.fenceInfoTable {
    width: 100%;
    font-size: 0.75rem !important;
}
.fenceInfoTable tr td {
    text-align: left;
    color: #5f6ca8 !important;
    font-family: Poppins !important;
    padding: 2px 0;
    vertical-align: unset;
}
.fenceInfoTable tr td:nth-child(1) {
    width: 130px;
}
.fenceInfoTable tr td:nth-child(2) {
    font-size: 0.875rem !important;
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 500 !important;
    width: 10px;
}
.expandIcon {
    flex: 0 0 20px;
    width: 20px;
    height: auto;
}

.cardFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.statusWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}
.statusWrapper .alertButton {
    border: unset;
    background-color: transparent;
    flex: 0 0 auto;
    color: #0000EE;
    font-size: 0.75rem;
    padding: 0;
    text-decoration: underline;
}
.statusWrapper .alertButton:disabled {
    color: #22046b;
    text-decoration: none;
}
.statusWrapper span {
    flex: 0 0 auto;
    padding: 2px 8px;
    font-size: 0.75rem;
    font-weight: 500;
    background-color: #FFEEEE;
    color: #E8595A;
    border-radius: 4px;
}
.statusWrapper.configured span {
    color: #54B948;
    background-color: #E4FBE2;
}

.actionButton {
    background-color: #22046b;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
}

.geofenceInfoStack {
    display: flex;
    justify-content: space-between;
}

.expandArrowStyle {
    position: relative;
    top: 10px;
    right: 3px;
}

.geofenceCardBorder {
    margin: 4px 0px;
}

.alertStatus {
    padding: 5px 0px;
}

.configured {
    color: #0000ee;
    font-family: Poppins !important;
    font-weight: 500;
    font-size: 12px;
}

.notConfigured {
    color: #f44336;
    font-family: Poppins !important;
    font-weight: 500;
    font-size: 12px;
}

.listingPanelContainer {
    padding: 0px;
    width: 340px !important;
    background: #ffffff;
    height: 630px;
    border-radius: 10px;
    position: absolute;
    top: 3%;
    z-index: 402;
    left: 1%;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}

.listingHeaderPanel {
    padding: 15px 15px 0px 15px;
    border-bottom: 8px solid #e7ecf7;
}

.listingCard {
    display: flex;
    justify-content: space-between;
}

.listingCardTitle {
    font-size: 18px;
    font-weight: 700;
    color: #1f2a5d;
}

.createGeofenceButton {
    background-color: #22046b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    width: 68px;
    height: 30px;
}

.listingSearchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.searchContainerGeofence {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    flex-grow: 1;
    margin-left: 0px;
    border: 2px solid #92a1e5;
    flex-grow: 0;
    height: 40px;
    border-radius: 11px;
    max-width: 210px;
}

.vobSearchIcon {
    margin-right: 5px;
    color: #555;
    margin: 0px 12px;
}

.vobSearchInput {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
}

.geofenceListingCards {
    padding: 2px 5px 10px 10px;
    overflow: scroll;
    height: 490px;
    overflow-x: hidden;
    margin-bottom: 10px;
    margin-top: 4px;
}

.geofenceListingCards::-webkit-scrollbar {
    width: 6px;
}

.geofenceListingCards::-webkit-scrollbar-thumb {
    background-color: #22046b;
    border-radius: 10px;
}

.geofenceListingCards::-webkit-scrollbar-track {
    background-color: #e7e7e7;
}

.geofenceDetailModal {
    position: absolute;
    top: 90px;
    width: 343px;
    height: 450px;
    background-color: white;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
    padding: 15px 2px;
    z-index: 100;
    left: 105%;
    border-radius: 10px;
}

.closeModalButton {
    position: absolute;
    top: 12px;
    right: 12px;
    border: none;
    cursor: pointer;
}

.modelTitle {
    border-bottom: 1px solid #b4c2e3;
    margin: 0px 12px;
}

.uploadPhotoContainer {
    /* max-height: 480px; */
    /* overflow: scroll; */
    /* overflow-x: hidden; */
    padding: 0px 12px;
}

.uploadPhotoContainer::-webkit-scrollbar {
    width: 6px;
}

.uploadPhotoContainer::-webkit-scrollbar-thumb {
    background-color: #22046b;
    border-radius: 10px;
}

.uploadPhotoContainer::-webkit-scrollbar-track {
    background-color: #e7e7e7;
}

.uploadFenceTitle {
    color: #1f2a5d !important;
    font-weight: 500 !important;
}

.amenityNameDetail {
    color: #1f2a5d !important;
    font-size: 12px !important;
    font-family: Poppins !important;
}

.adressDetail {
    width: 70% !important;
}

.adressDetailTitle {
    width: 30% !important;
    color: #1f2a5d !important;
    font-weight: 500 !important;
}

.amenitiesDetailContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
}

.amenityBox {
    width: 96px;
}

.editButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.amenityIcon {
    padding-right: 5px;
}

.filterSelectGeofence {
    left: 114px;
    margin-top: -10px;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
    z-index: 2;
    width: 210px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}

.geofenceFilterData {
    display: flex;
    align-items: center;
}

.filterText {
    font-family: Poppins !important;
    color: #1F2A5D;
    font-size: 13px;
    margin-bottom: 0px;
    padding-left: 4px;
}

.filterTitle {
    font-family: Poppins !important;
    color: #1F2A5D;
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 500;
}

.filterCollapse {
    border: none !important;
}

.filterButton {
    color: #ffffff;
    padding: 0px 12px;
    border-radius: 4px;
    font-size: 0px;
    height: 40px;
    border: unset;
    background-color: #22046b;
    flex: 0 0 auto;
    color: #ffffff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.75rem;
}
