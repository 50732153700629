.table {
    border-spacing: 0 10px;
    width: 100%; 
    border-bottom: none;
}

.table_th {
  background: #2E1364 !important;
  color: #D7DFE8 !important;
  border-bottom: none;
  text-align: left !important;
  vertical-align: middle; 
  font-weight: 600;

  }
  .no_data_container{
    width: 100%;
     height: 100%;
    text-align-last: center;
  }
  .no_data_text{
    font-size: 16px;
  }
  .table_td_no_data {
    background: #060B28 !important;
    color: #fff !important;
    height: 200px;
    border-bottom: none;
  }
  .table_td {
    background: #060B28 !important;
    color: #fff !important;
    border-bottom: 1px solid #56577A;
    padding-top: 10px; 
    padding-bottom: 10px; 
    text-align: start;
  }

  .table_tr {
   margin-top: 20px;
  }
  
  .sort_icon{
    margin-left: 5px;
  }

  .arrow {
   width: 20px;
   margin-right: -15px;
  }
  /*conflict-resolution-will be removed later*/