.selectWrapper {
    margin-bottom: 8px;
}
.label {
    line-height: 2.25;
    color: white !important;
    font-weight: 700;
}

.errorMessage{
    color:#EF7B10;
    font-size: 10px;
    font-weight: 400;
}

.selectedOptionMainContainer {
    max-width: 380px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
}

.optionSelectedContainer {
    border: 1px solid;
    background: linear-gradient(180deg, rgba(49, 58, 91, 0) -19.57%, #313A5B 98.8%, rgba(49, 58, 91, 0.222222) 130.43%),
    linear-gradient(180deg, rgba(154, 56, 199, 0.4) 12.2%, rgba(0, 0, 0, 0) 95.06%, rgba(0, 0, 0, 0.4) 96.73%);
    top: 204.25px;
    left: 8.5px;
    padding: 5px 10px;
    gap: 10px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectedValue {
    font-size: 12px;
}

.removeIcon {
    cursor: pointer;
    color: white;
}

.dropdownContainer{
    display: flex;
    width: 504px;
    height: 44px;
    padding: 4px 16px;
    align-items:center;
    gap: 4px;
    flex-shrink:0; 
    border-radius:10px;
    border: 1px solid rgba(255, 255, 255, 0.60);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -3.62%, rgba(255, 255, 255, 0.20) 100%);
    justify-content: space-between;
}

.dropdownContainer:hover {
        background: linear-gradient(0deg, rgba(96, 46, 166, 0.2) 0%, rgba(201, 119, 214, 0.2) 88.64%);
        box-shadow: 0px 0px 10px 0px rgba(118, 61, 176, 1);
        border: 1px solid #4b47b8
    }
.dropdownContainer:focus {
        background: transparent !important;
        box-shadow: none !important
}

.selectContainer {
    border-radius: 5px;
    border: 1px solid rgba(51, 250, 255, 0.57);
    background: #242644;
    width: 504px;
    padding: 10px;
    margin-top:10px;
    height: 246px;
    position: absolute
}

.departmentSelectContainer {
    border-radius: 5px;
    border: 1px solid rgba(51, 250, 255, 0.57);
    background: #242644;
    width: 504px;
    padding: 10px;
    margin-top:10px;
    top: 119px;
    bottom: 217px;
    position: absolute
}

.locationSelectContainer {
    border-radius: 5px;
    border: 1px solid rgba(51, 250, 255, 0.57);
    background: #242644;
    width: 504px;
    padding: 10px;
    margin-top:10px;
    top: 202px;
    bottom: 135px;
    position: absolute;
}

.inputWrapper {
    display: flex;
    padding-left: 0px;
    background-color: #1C1F28;
    border-radius: 10px;
    height: 45px;
    width: 100%;
    background: #1C1F28 !important;
    border: 3px solid #5D5F86;
    filter: drop-shadow(0 0 0.65rem #2E1364);
    min-width: 292px;
  }
  
  .searchIcon {
    color: #797694;
    margin-left: 10px;
}

.multiSelectPlaceholder {
    color: rgba(255, 255, 255, 0.30);
}
.selectPlaceholder {
    color: rgba(255, 255, 255, 0.30);
}

.optionSelectAll > div {
    padding: 6px;
    width: 60%;
}

.optionWrapper > div:hover,
.optionSelectAll > div:hover {
    background: #17152B;
    opacity: 0.75;
    color: white !important;
}

.optionMultiSelect label,
.optionSingleSelect label,
.optionSelectAll label {
    cursor: pointer;
}
/*conflict-resolution-will be removed later*/