
.alertRow {
    display: flex;
    align-items: flex-end;
    width: 100%;
    gap: 16px;
    padding-top: 16px;
}

.alertTypeCol {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.alertTypeCol label {
  font-weight: 500;
}

.alertTimeCol {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.alertTimeInput {
    border: none;
    border-bottom: 1px solid #99afe4;
}

.alertUserCol {
    flex: 0 0 auto;
}

.alertDetailBox {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 30%;
  }
  .alertTimeInput {
    border: none;
    border-bottom: 1px solid #99afe4;
  }
  .fenceDetailsLabel {
    color: #1f2a5d !important;
    font-size: 14px !important;
    font-family: Poppins !important;
    font-weight: 500 !important;
  }
  .alertBoxWrapper {
  display: flex;
  gap: 40px;
  align-items: flex-end;
}
.alertDetailContainer {
  display: flex;
  gap: 10px;
  min-height: 330px;
  max-height: 330px;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 10px;
  flex-direction: column;
}

.alertDetailContainer::-webkit-scrollbar {
  width: 6px; 
}

.alertDetailContainer::-webkit-scrollbar-thumb {
  background-color: #22046B; 
  border-radius: 10px; 
  min-height: 100px; 
}
.alertDetailContainer::-webkit-scrollbar-track {
  background-color: #E7E7E7; 
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}
.modalSaveButton {
  border: unset;
  flex: 0 0 auto;
  color: #ffffff;
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #22046b;
}
.alertTypeInput {
  padding: 3px 8px;
  border: 1px solid #e7ecf7;
  border-radius: 5px;
  background: #e7ecf7;
  font-size: 14px;
  font-family: Poppins !important;
  color: #1f2a5d;
  height: 40px;
  font-weight: 500;
}