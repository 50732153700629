.addAlertContainer {
  display: block;
}
.AddAlertButtonBox{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.AddAlertButton {
  background: #22046b;
  height: 36px;
  font-family: Poppins !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  padding: 10px 12px;
}
.AddVehicleButton {
  background: #22046b;
  height: 36px;
  font-family: Poppins !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  padding: 10px 12px;
}

.disabledAddVehicleButton {
  background-color: #9E9E9E;
  cursor: not-allowed;
  opacity: 0.6;
}
.AddAlertIcon {
  padding-right: 5px;
}
.addAlertModel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.addVehicleContent {
  background: #ffffff;
  padding: 20px 50px;
  border-radius: 8px;
  max-width: 70%;
  width: 980px;
  text-align: center;
  height: auto;
}

.addAlertModelContent {
  background: #ffffff;
  padding: 50px 30px;
  border-radius: 8px;
  max-width: 75%;
  width: 100%;
  text-align: center;
  max-height: 530px;
  height: 530px;
  position: relative;
}


.enabledSaveButton {
  background-color: #22046B;
  color: #ffffff;
  border: none;
  padding: 10px 28px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  font-family: Poppins !important;
  font-size: 12px;
}

.disabledSaveButton {
  background-color: #9e9e9e;
  color: #ffffff;
  border: none;
  padding: 10px 28px;
  border-radius: 4px;
  cursor: not-allowed;
  position: absolute;
  bottom: 15px;
  font-family: Poppins !important;
  font-size: 12px;
}

.alertHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alertHeader h4 {
  font-size: 16px;
  font-family: Poppins !important;
  color: #1f2a5d;
  font-weight: 600;
}
.alertModelclose {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 20px;
}
.AddAlertCloseBtn {
  position: absolute;
  top: 15px;
  right: 30px;
  cursor: pointer;
}
.alertDetailContainer {
  display: flex;
  gap: 16px;
  min-height: 330px;
  max-height: 330px;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 20px;
  flex-direction: column;
  padding-bottom: 245px;
}

.alertDetailContainer::-webkit-scrollbar {
  width: 6px; 
}

.alertDetailContainer::-webkit-scrollbar-thumb {
  background-color: #22046B; 
  border-radius: 10px; 
  min-height: 100px; 
}
.alertDetailContainer::-webkit-scrollbar-track {
  background-color: #E7E7E7; 
}

.alertDetailBox {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 30%;
  position: relative;
}
.deleteIconBox {
  width: 10%;
  align-self: center;
}
.deleteIconBox img {
  cursor: pointer;
}
.alertTimeInput {
  border: none;
  border-bottom: 1px solid #99afe4;
}

.hrsLabel {
  border-bottom: 1px solid #99afe4; 
}

.timeInputContainer {
  display: inline-flex; 
  align-items: center; 
}
.fenceDetailsLabel {
  color: #1f2a5d !important;
  font-size: 14px !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
}
.selectUserLabel {
  text-align: left;
  position: relative;
}
.selectUserLabel .fenceDetailsLabel {
  position: absolute;
  top: -20px;
  z-index: 1;
}
.alertDeleteIcon {
  position: relative;
  margin-top: -40px;
  right: -50%;
}
.alertBoxWrapper {
  display: flex;
  gap: 16px;
  align-items: stretch;
}

.alertDetailCard{
  background: #fff;
  padding: 10px;
  width: 100%;
  box-shadow: 0px 0px 10px 5px rgba(217, 224, 255, 0.8);
  border-radius: 8px;
}
.alertDetailsTable{
  border-bottom: 1px solid #22046B;
}
.selectColumn{
  display: flex;
  align-items: center;
  text-align: center;
}
.alertDataList td{
  text-align: center;
}
.selectColumn input{
  accent-color: #22046B;
}
.selectColumn p{
  margin-bottom: 0px;
  padding-left: 3px;
  font-family: Poppins !important;
  font-size: 12px;
  color: #1F2A5D;
}
.alertTableHeader{
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #22046B;
}
.alertEditIcon{
  background: none !important;
  cursor: pointer;
}
.alertCountstatus{
  color: #BC0078 !important;
}

/* vehicleModal css */

.assignVehiclecontainer{
  background: #EDF2FF;
  padding: 20px;
  border-radius: 5px;
}
.assignVehicleList{
  display: flex;
  gap: 10px;
  padding: 0px;
  margin: auto;
  flex-wrap: wrap;
}
.assignVehicleChip{
  display: flex;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #5251C0;
  padding: 8px 15px;
  font-size: 12px;
  color: #1F2A5D;
  font-family: Poppins !important;
  align-items: center;
  gap: 14px;
}
.assignVehicleBox{
  text-align: left;
  margin-top: 10px;
  padding: 20px;
  color: #1F2A5D;
}
.assignVehicleBox p{
  font-size: 16px;
  font-family: Poppins !important;
  font-weight: 600;
}
.assignVehicleSelect{
  display: flex;
  gap: 20px;
  align-items: center;
}
.assignVehicleRadio{
  display: flex;
  gap: 5px;
  align-items: center;
}
.assignVehicleRadio span{
  font-size: 14px;
  font-family: Poppins !important;
  font-weight: 400;
}
.assignVehicleSaveButton{
  width: 79px;
  height: 36px;
  font-family: Poppins !important;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;
  position: relative;
  margin: 30px;
}

.selectGroupRadio {
  width: 16px;
  height: 16px;
  accent-color: #22046B;
}

.selectGroupRadio:checked {
  background-color: #22046B; 
}
.ModelButton{
  position: relative;
  right: -24px;
  cursor: pointer !important;
}

.alertTimeError {
  position: absolute;
  bottom: -18px;
  width: 100%;
  left: 0;
  font-size: 0.75rem;
  text-align: left;
  color: red;
}