.public_body {
    min-height: 100vh;
    min-width: 100%;
    background-color: black;
    background-image: url('../../assets/darkThemeAssets/DarkPublicBackground.svg');
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
}

.public_header {
}

.norminc_logo {
    width: 142.683px;
    height: 104px;
    background-color: black;
    margin-top:50px;
    margin-left:50px
    
}

.public_content {
    flex: 1
}
/*conflict-resolution-will be removed later*/