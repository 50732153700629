.admin_Create_Button {
  margin-top: 10px;
  color: #fff;
  background-blend-mode: overlay, normal; 
  background: linear-gradient(355deg, rgba(7,10,77,1) 0%, rgba(54,91,218,1) 100%, rgba(56,94,221,1) 100%)
  ,radial-gradient(circle, rgba(7,10,77,1) 0%, rgba(54,91,218,1) 100%, rgba(56,94,221,1) 100%);
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.40), 0px 0px 5px 0px #2e6ca6 inset, 0px -1px 8px 0px #2e6ca6 inset;
  border: none;
  padding: 7px 10px 10px 10px;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid #215688; 
}

.admin_create {
  filter: drop-shadow(#133555 -4px -5px 13px);
}

.admin__container {
  width: 100%;
  padding-top: 10px;
  padding:  0 3.5rem;

}

.admin__table__container{
background: #060B28;
margin-top: 10px;
margin-bottom: 45px;
}

.admin__cta__container{
  margin-top: 40px;
  margin-bottom: 45px;
  }




.admin_Create_Button:hover {
  animation: color-slide 0.4s ease forwards;
}

@keyframes color-slide {
  0% {
    background: linear-gradient(180deg, rgba(46 108 166, 0.90) -22.92%, rgba(46 108 166, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #C8D1FE 0%, #2e6ca6 100%);

  }

  100% {
    background: linear-gradient(90deg, rgba(46 108 166, 0.90) -22.92%, rgba(46 108 166, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #C8D1FE 22%, #2e6ca6 69%);

  }
}


.admin_tooltip_inner_container {
  color: white;
  font-weight: 400;
  font-size: 14px;
}

.admin_tooltip_inner_container  span {
  font-weight: 600;
  font-size: 16px;
}



.popupadmin {
  background-color: #242644 !important;
    border: 2px solid #1a6e70;
    position: absolute;
    top: 15px;
    left: 38px;
    z-index: 1000;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 8px;
    color: #fff;
    border-radius: 10px;
}

.popupadmin_ul {
  padding-left: 0;
  text-align: left;
  padding: 0px 0px 0px 0px;
  margin-bottom: unset !important
}

.popupadmin_ul li {
  width: 75px;
  padding-left: 6px;
  padding-top: 10px;
}

.popupadmin_ul li:hover {
  background-image: url("/public/images/adminActive.svg") !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 0px 8px;

}

#administration_table th {
  background: #2E1364 !important;
  color: #fff !important;
}

#administration_table td {
  background: #060B28 !important;
  color: #fff !important;
}


.admin__button {
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

}

.admin__button_yes {
  border-radius: 10px;
  border: 2px solid rgb(189, 64, 234);
  background: linear-gradient(180deg, rgb(203 46 166) -22.92%, rgb(161 0 122 / 0%) 26.73%), radial-gradient(96.63% 140.33% at 76.68% 66.67%, rgb(72 14 149 / 37%) 0%, rgb(208 18 238 / 52%) 100%);
  box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 80%), 0px 0px 0px 0px #fface400 inset, 0px 0px 0px 0px;
  height: 40px;
  width: 90px;
  padding: 6px;
}

.admin__button_no {
  background-color: transparent;
  border-radius: 10px;
  border: 3px solid rgb(189, 64, 234);
  padding: 4px;
  width: 90px;
  height: 40px;
  margin-right: 50px;

}

.dwn_btn_disabled {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  background: linear-gradient(180deg, rgb(0 0 0 / 90%) -22.92%, rgb(47 47 47 / 90%) 26.73%), radial-gradient(96.63% 140.33% at 76.68% 66.67%, rgb(0 0 0) 0%, rgb(211 219 232 / 0%) 100%);
  background-blend-mode: overlay, normal;
  box-shadow: 0px -1px 8px 0px #7587b6 inset, 0px 0px 5px 0px #000000 inset, 0px 10px 10px 0px rgb(0 0 0)

}
.formContainer {
  padding: 0 40px 0 0;

}
/*conflict-resolution-will be removed later*/