.location-dropdown-content {
    position: fixed;
    right: 65px;
    z-index: 1999;
    border-radius: 5px;
    background-color: #fff;
    padding: 12px 12px 12px 12px;
    color: #fff;
    border: none;
    width: 160px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-top: -55%;
    font-size: 14px !important;
    max-height: calc(40vh);
    overflow-y: hidden;
    overflow-x: hidden;
    transform: translate(5%, -5%)
}

.mainLocationContaier::before {
    content: '';
    position: absolute;
    top: 10%;
    left: -25px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
    z-index: 2000;
}

.location-content::-webkit-scrollbar {
    width: 3px !important;
    /* Width of the scrollbar */
}

.location-content::-webkit-scrollbar-thumb {
    background-color: #22046b !important;
    /* Color of the scrollbar thumb */
    border-radius: 3px !important;
    /* Rounded corners */
}

.location-content::-webkit-scrollbar-track {
    background-color: #fff !important;
    /* Color of the scrollbar track */
}

.nd_Dark .form-check-input:checked[type=radio] {
    background-color: transparent !important;
    /* background-color: #ffffff8a !important; */
    border-color: #ffffff8a !important;
    /* border-color: #22046b !important; */
    outline: none !important;
    box-shadow: none !important;
}

.nd_Dark .form-check-input[type=radio] {
    font: normal normal normal 14px/20px Inter;
    background-color: transparent !important;
    border-color: #ffffff8a !important;
    outline: none !important;
    box-shadow: none !important;
}

.nd_Dark .form-check-input[type=radio]~label {
    cursor: pointer !important;
    color: #8D9CC4 !important;
    margin-left: 8px !important;
}

.nd_Dark .form-check {
    cursor: pointer !important;
}

.nd_Dark .form-check-input:checked[type=radio] {
    background-image: url("/public/images/svgicon/trackingScreen/radeo_Dark.svg") !important;
    /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%2322046b%27/%3e%3c/svg%3e") !important */
}

.nd_Dark .form-check-input {
    cursor: pointer !important;
}

.location-button-container {
    display: inline-block;
    position: relative;
}

.location-content {
    max-height: calc(32vh) !important;
    width: 100% !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    width: 95% !important;
}