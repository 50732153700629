.table-container {
  margin: 20px;
}

h1 {
  display: flex;
  align-items: center;
}

.popup-download{
  padding: 8px 16px;
  background-color: #1f2a5d;
  color: #fff;
  border: none;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  /* padding: 8px; */
  text-align: center;
  border-bottom: 1px solid #ddd;
  
}

th {
  background-color: #f5f5f5;
  /* background-color: red !important;
  color: blue !important; */
}
/* 
tbody tr:nth-child(even) {
  background-color: #f9f9f9;
} */
tbody{
  font-size: 12px;
}

.soc-thead {
  left: 274px;
  width: 1372px;
  text-align: center;
  height: 69px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  font-size: 14px;
}

.popup-content.additional-classname {
    top: 20px;
    bottom: 25px;
    width: 80vw !important;
    /* max-height: 75vh !important; */
    /* min-height: 65vh !important; */
    height: fit-content !important;
    overflow: hidden;
    padding: 30px !important;
}

/* .filters-data {
  max-height: 388px;
  overflow-y: auto;
} */

.filters-data table {
  width: 100%;
  border-collapse: collapse;
}

.filters-data th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.vehicles-heading{

    color: #1f2a5d;
    margin-bottom: -2rem;
    opacity: 1;
}

.small-cell {
  font-size: 14px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.soc-dropdown-content {
  position: fixed;
  right: 70px;
  z-index: 1999;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px 10px 0px 10px;
  color: #fff;
  border: none;
  width: 130px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  top: 50%;
}

.soc-dropdown-content::before {
  content: '';
  position: absolute;
  top: 5%;
  right: -20px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  z-index: 2000;
}

.dropdown-content button {
  width: 100%;
  text-align: left;
}

.dropdown-option {
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
  position: relative;
  font-size: 14px;
  width: 100%;
  padding-bottom: 10px;;
}

.dropdown-option:nth-child(5n) {
  border: none;
}

.dropdown-option .icon {
  position: absolute;
  left: 90px;
  transform: translateX(5px);
  margin-right: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.count-label {
  font-size: 12px;
  /* color: #999; */
  margin-top: -19px;
}

.green {
  color: #58b730;
}

.yellow {
  color: #FFBF00;
}

.orange {
  color: #f28920;
}

.blue {
  color: #4a8eda;
}
.blues{
  color:#0047AB;
}
.red{
  color: red;
}
.clickable {
  cursor: pointer;
  color: #6A91FA;
  text-decoration: underline;
}
/* Add appropriate CSS styles for the hover effect of each color option */
.dropdown-option .hovered.blues {
background-color: #0047AB;
color:white;
border-radius: 4px;
}

.dropdown-option .hovered.green {
background-color: #58b730;
color:white;
border-radius: 4px;
padding-left: 5px;
}

.dropdown-option .hovered.yellow {
background-color: #FFBF00;
color:white;
border-radius: 4px;
padding-left: 5px;
}

.dropdown-option .hovered.orange {
background-color: #f28920;
color:white;
border-radius: 4px;
padding-left: 5px;
}

.dropdown-option .hovered.red {
  background-color: red;
  color:white;
  border-radius: 4px;
  padding-left: 5px;
}

.count-label.white {
color: white;
}

.filters-data {
  position: relative;
}
.table-body-container {
  max-height: calc(75vh - 200px); /* Adjust the value as needed */
  overflow-y: auto;
}
.text-container {
  display: inline-block;
}

.underline-text {
  border-bottom: 2px solid #C2C2C2;
  display: inline-block;
  padding-bottom: 5px;
  width: 420px;
  margin-left: 30px;
  margin-right: 30px;
}

.alarmAnalytics-table th {
  background: #22046b 0% 0% no-repeat padding-box !important;
  color: #fff !important;
}

.nd__soc-table td {
  padding: 4px !important;
}

.popup-close {
  cursor:pointer;
  display:flex;
  flex-direction:row-reverse;
  border-radius:50%;
  padding:3px;
  background-color: white;
}

.popup-close:hover {
 background-color: #D5D5D5; 
}