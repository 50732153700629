.groups__edit__text{
    color: #1F2A5D;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}
.groups__edit__count__text{
    color: #1F2A5D;
    font-size: 12px;
   }
   .groups__add__count__text{
    color: #1F2A5D;
    font-size: 12px;
    padding-left: 10px;
   }
.groups__edit__conut__para{
    font-weight: 600;
    color: #22046B;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.groups__edit__conut__span{
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
    color: #22046B;
}
.group__edit__arrow{
    font-weight: 600;
    display: flex;
    align-items: center;
}

#groups__add__table tbody{
    width: 100% !important;
}

#groups__add__table {
    width: 100% !important;
    position: relative !important;
}

#groups__add__table th {
    width: 10% !important;
}

#groups__add__table td {
    width: 10% !important;    
}

#groups__add__table .select-col {
    width: 10% !important;
}

#groups__add__table .select-col, #groups__add__table td, #groups__add__table tr {
    width: 193.46px !important;
    text-align: center;
}

/* #groups__add__table{
    height: 350px !important;
} */

#groups__add__table th{
    background-color: #22046B !important;
}

#groups__add__table th p{
    background-color: #22046B !important;
    color: #FFFFFF !important;
    margin-bottom: 0px !important;
}

#groups__add__table th svg{
    background-color: #E7ECF7 !important;
    color: #1F2A5D !important;
}

/* make th content vertically center */
#groups__add__table th{
    vertical-align: middle !important;
}
.sub__group__table{
    padding: 0 0 2rem 0 !important;
    width: 100% !important;
    margin-left: 0rem;
    min-height: 600px !important;
}

.groups__table--wrapper {
    box-shadow: 0px 3px 6px #00000029;
}

.overflow-group .geofence-dropdown-content {
    margin-left: 0 !important;
    margin-top: 0 !important;
    top: 100%;
    right: 0;
    z-index: 1000;
}

.subgroup-popup {
    right: 0;
    top: 100%;
    width: 260px;
    word-wrap: break-word;
    margin: 0;
    border-radius: 3px;
    padding: 9px 9px;
}

.subgroup-popup .popupSubGroups_ul:last-of-type {
    border-bottom: 0;
}

.subgroup-popup .popupSubGroups_ul {
    margin-bottom: 0;
    border-bottom: 1px solid #e1e1e1;
}

.subgroup-popup .popupSubGroups_ul li {
    padding: 6px 3px;
}

.grp__alert-success {
    --bs-alert-padding-y: 0.5rem !important;
    font-size: 14px !important;
    color: #22046B !important;
}

.grp__alert-success > span > .btn-close {
    font-size: 10px !important;
    color: #22046B !important;
}

.grp__alert-success > span > .btn-close:focus {
    box-shadow: none !important
}