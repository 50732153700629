.UploadFileContainer{
    margin-top: 15%;
    z-index: 9999 !important;
}
.modelHeader{
    padding: 25px 18px !important;
    display: block !important;
}
.modelHeaderClose{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    top: 13px;
}
.modelFooter{
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    margin-bottom: 20px;
    gap: 14px;
}
.modelCancelButton{
    background: #EFF3F8;
    color: #1F2A5D;
    font-family: Poppins !important;
    font-size: 12px;
    width: 83px;
    height: 40px;
    border-radius: 5px;
}
.modelSaveButton{
    background: #22046B;
    color: #FFFFFF;
    font-family: Poppins !important;
    font-size: 12px;
    width: 83px;
    height: 40px;
    border-radius: 5px;
}

.errorModal {
  margin-top: 15%;
  z-index: 9999 !important;
  }
  .closeButtonError{
    display: flex;
    justify-content: flex-end;
    margin: 18px;
  }
  .errorModal .errorModalBody {
    max-height: 440px;
    overflow: auto;
  }
  
  .title {
    font-size: 1.5rem;
    color: #22046b; 
  }
  
  .successMessage {
    display: flex;
    align-items: center;
    border: 1px solid #90E1A0;
    color: #155724; 
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .errorMessage {
    display: flex;
    flex-direction: column;
    background-color: #f8d7da; 
    border: 1px solid #f5c6cb; 
    color: #721c24;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  

  .closeButton {
    cursor: pointer;
  }
  .accordianItem2{
    border: 1px solid #E94240 !important;
  }
  .errorIcon{
    margin-left: -9px;
    margin-right: 10px;
  }
  .successIcon{
    margin-right: 10px;
  }
  .errorIconCollapse{
    margin-right:10px
  }
  

.vehicleItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  font-size: 16x;
}

.copyButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.copyButtonBox {
  border-left: 1px solid #ddd; 
  padding-left: 10px;
}

.vehicleItem span {
  flex-grow: 1; 
}

.accordianContainer{
  display: flex;
  flex-direction: column;
}

.messageBox {
  border: 2px solid #90E1A0;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 12px;
}
.messageErrorBox {
  border-color: rgb(233, 66, 64, 0.4);
  cursor: pointer;
}
.messageHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px 0;
}
.messageHeader img {
  flex: 0 0 20px;
  width: 20px;
  height: auto;
}
.messageHeader span {
  flex: 1 0 auto;
  font-weight: 500;
  font-size: 0.875rem;
}
.messageHeader .copyButton {
  transform: rotate(180deg);
  transition: all 0.2s linear;
}
.messageBody {
  flex-direction: column;
  padding: 12px 8px;
  max-height: 100px;
  overflow: auto;
  display: none;
}
.messageBody span {
  color: #1F2A5D;
  font-size: 0.875rem;
}
.messageFooter {
  display: none;
  justify-content: flex-end;
  border-top: 1px solid #22046B;
  padding-top: 12px;
}
  
.messageBoxExpanded .messageBody,
.messageBoxExpanded .messageFooter {
  display: flex; 
}

.messageBoxExpanded .messageHeader .copyButton {
  transform: rotate(0deg);
}
