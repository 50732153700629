.trip-analytics-filter-button {
    background: #EEEEEE 0% 0% no-repeat padding-box !important;
    width: fit-content !important;
    padding: 5px 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75em !important;
    color: #1F2A5D !important;
    cursor: pointer;
    border-radius: 2px;
}

.trip-analytics-filter-button.selected {
    font: normal normal medium 14px/21px Inter;
    color: #FFFFFF !important;
    background-color: #22046B !important;
}