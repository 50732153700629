.root{
    width: 107%;
    border-radius: 10px;
    border-bottom: 1px solid #6d7bb2 ;
    border-right: 1px solid #6d7bb2 ;
    border-left: 1px solid #6d7bb2 ;
    border-top: 1px solid #263571  ;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -3.62%, rgba(255, 255, 255, 0.20) 100%);
    color: #fff ;
    font-weight: 400 ;
    font-size: 14px;
    height: 40px ;
    border-radius: 8px ;
    opacity: 1 ;
    padding-top: 15px ;
    padding-right: 25px ;
    margin-bottom: 10px;
    padding-left: 10px;
    margin-top: 5px;
    height: 80px;
}



.root::-webkit-scrollbar {
    width: 8px !important;
}

.root::-webkit-scrollbar-track {
    background: #393939 !important;
}

.root::-webkit-scrollbar-thumb {
    background: #0B0C12 !important;
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    background-clip: content-box !important;
}

.root:focus {
    background: transparent !important;
    border:1px solid #6B1DA5 !important;
    box-shadow: none!important ;
}

.root:hover {
    background: linear-gradient(0deg, rgba(96, 46, 166, 0.2) 0%, rgba(201, 119, 214, 0.2) 88.64%);
    box-shadow: 0px 0px 10px 0px rgba(118, 61, 176, 1);
    border: 1px solid #4b47b8 ;
}

.errorMessage{
    color:#EF7B10;
    font-size: 10px;
    font-weight: 400;

}


.iconSuccess{
    position: absolute;
    margin-top: 14px;
    margin-left:5px;
}

.label {
    font-weight: 700;
    color: white !important;
}
.lableRequired{
    color:#C417E0;
}


 input:-webkit-autofill,
 input:-webkit-autofill:hover, 
 input:-webkit-autofill:focus, 
 input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}
.root::placeholder{
    color: rgba(255, 255, 255, 0.30);
}
/*conflict-resolution-will be removed later*/