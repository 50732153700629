/* TermsandConditions.css */

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
  
  .heading {
    color: #bc0078;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .content {
    max-width: 1100px;
    text-align: justify;
    margin-bottom: 20px;
    height: 600px; 
    overflow-y: scroll; 
    position: relative; 
    padding-left: 20px; 
    font-size: 12px;
    width: 80%; 
    padding-right: 20px;
  }
  
  /* Add scrollbar styles */
  .content::-webkit-scrollbar {
    width: 10px; 
  }
  
  .content::-webkit-scrollbar-thumb {
    background-color:#1f2a5d; 
    border-radius: 5px; 
  }
  
  .content::-webkit-scrollbar-thumb:hover {
    background-color: #8b0039; 
  }
  
  .accept-button {
    padding: 6px 100px;
    background-color: #1f2a5d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px; 
    margin-left: 19rem;
  }
  
  /* styles.css */
  .table-container {
    border: 1px solid black;
    border-collapse: collapse;
    width: 80%; 
  }
  
  .table-container th,
  .table-container td {
    border: 1px solid black;
    padding: 4px; 
    font-size: 10px;
    font-weight: 500;
  }
  
  .head {
    color: #1f2a5d;
    font-weight: 600;
  }
  
  .content ul,
  .content ol {
    padding-left: 20px;
  }
  
  .content li {
    position: relative;
    margin-bottom: 10px; 
    font-size: 12px;
  }
  
  .content li::before {
    content: "•"; 
    position: absolute;
    left: -20px; 
  }
  
  .company-heading {
    font-weight: 500;
    color: #1f2a5d;
    font-size: 12px;
  }
  