/* Add spacing between each table row */
.vehicles-onboarding-tc .table-wrapper .table {
    border-collapse: separate;
    border-spacing: 0 5px; /* Adjust the second value to your desired spacing */
}

.mw-120 {
    min-width: 120px;
}

.vehicles-onboarding-tc .table-wrapper .table td {
    width: auto;
    vertical-align: middle;
}

.vehicles-onboarding-tc .table-wrapper tbody tr {
    margin-bottom: 10px;
    /* Adjust the value as per your desired spacing */
}


#vehicle-onboarded-model th p {
    background: inherit;
    display: flex;
    justify-content: center;
    margin-bottom: 3px;
}

.vehicles-onboarding-tc .table-wrapper tbody tr td .text {
    /* padding: 4px; */
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background: #E7ECF7 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    font-size: 0.875rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.vehicles-onboarding-tc .table-wrapper tbody tr td .inputfields {
    height: 32px !important;
    text-align: center;
    color: #1F2a5d !important;
    font-size: 12px !important;
}

.vehicles-onboarding-tc .table .active {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000045;
    outline: 2px;
}
.table-header {
    background: #E7ECF7 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
  }
  .vehicle-onboard-modal-body .table-header > th {
    background: unset !important;
    border: none !important;
  }
  
  .vehicle-onboard-modal-body .header-container .hr-line-blue-hr-width {
    width: 200px;
    max-width: 200px;
    vertical-align: middle;
  }
  
  .hr-line-blue-vobEdit-width {
    width: 215px;
    max-width: 215px;
  }
  .vehicle-onboarded-th{
    color: #fff !important;
  }
  .vehicle__onboard__select{
    color: #1F2a5d !important;
    font-size: 12px !important;
  }
  .location_data_row{
    padding: 0.5rem 0rem !important;
  }