.file-upload-container-geofence {
    border: 1.4px solid #d3d3d3;
    border-radius: 8px;
    margin-top: 35px;
    width: 440px;
    margin-left: 13px;
    font-size: 12px;
    font-weight: 600;
    color: #1f2a5d;
    padding: 10px 0px;
  }
  .progress-bar-geofence {
    height: 4px !important;
    background-color: #67a3ff !important;
    border-radius: 2px !important;
  }
  .progress-geofence {
    height: 4px !important;
    background-color: #cecece !important;
  }
  .upload-spinner-geofence {
    padding: 6px !important;
    color: #67a3ff !important;
  }
  .close-icon-progressbar-geofence {
    color: #acacac;
    height: 16px;
    width: 16px;
    margin-right: 7px;
  }
  .cls-btn-geofence {
    background-color: unset;
    border: unset;
  }
  .margin-left-geofence {
    margin-left: -20px;
  }
  .upload-size-geofence {
    color: #b3b3b3;
    font-size: 12px;
  }
  