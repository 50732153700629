.nd__dark-vehicle-info-can-status-card {
    width: 98% !important;
    height: 100% !important;
    border-radius: 0px 20px 20px 0px !important;
    background: linear-gradient(90deg, rgb(17 16 24 / 0%) 0.28%, rgb(0 0 0) 129.42%) !important;
    box-shadow: none !important;
    background-blend-mode: overlay !important;
    padding: 10px 30px;
    font-size: 0.75em;
    color: #F7F7F7;
    margin-left: 4px;
    margin-top: 10px;
}



.new-dark-dashboard .nd__dark-info-can-status-card-half-border {
  position: relative;
  overflow: hidden;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent; /* Make sure this is configured to show when needed */
  border-left: 1px solid transparent;
 
}

/* This is for the top half-border */
.new-dark-dashboard .nd__dark-info-can-status-card-half-border::before {
  content: '';
  position: absolute;
  top: 0; /* Aligns to the top */
  left: 10%;
  width: 80%;
  height: 1px;
  background: linear-gradient(90deg, transparent,  rgba(196, 65, 244, 1), transparent);
  z-index: 10;
}

/* This is for the bottom half-border, ensuring visibility transition */
.new-dark-dashboard .nd__dark-info-can-status-card-half-border::after {
  content: '';
  position: absolute;
  bottom: 0; /* Aligns to the bottom */
  left: 10%;
  width: 80%;
  height: 1px;
  background: linear-gradient(90deg, transparent,  rgba(196, 65, 244, 1), transparent); /* Adjust the background to match the top border */
  z-index: 10;
 
}


.nd__dark-vi-line {
    height: 0px;
    width: 100%;
    border: 1px solid #3a3b3c;
    margin-top: 8px;
}

.nd__dark-vehicle-info-can-status-content>.row {
    /* padding: 0px 26px; */
    display: flex;
    align-items: center;
}

.nd__vehicle-info-can-status-text-sideby-icon {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.nd__green {
    color: #38E053 !important;
}

.nd__yellow {
    color: #FFC000 !important;
}

.nd__red {
    color: #E40B0B !important;
    margin-top: 3px;
}

.nd__blue {
    color: #22046B !important;
}

.nd__vehicle-info-can-status-text-row-small {
    font-size: 0.80em;
    text-align: center;
}

.nd__vehicle-info-status-img {
    height: 2em;
}

.ndvi__dark-can-box {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
}

.nd__dark-vi__can-box-header {
    display: inline-flex;
    align-items: center;
}

.nd__dark-vi__can-box-header img {
    max-width: 100%;
    width: 28px;
    height: auto;
    margin-right: 16px;
    margin-top: -10px;
}

.nd__dark-vehicle-info-can-status-card .nd__dark-vi__can-box-header span {
    font-size: 12px;
}

.ndvi__can-box-body {
    text-align: center;
    margin-top: -2px;
}

.nd__dark-vehicle-info-can-status-card .ndvi__can-box-body span {
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
    max-width: 70px;
}

.nd__vehicle-info-can-status-header>.row .col-6:last-of-type {
    text-align: right;
}
.nd-vc-info-status-perc-in{
    border: 1em solid #C977D6;
    height: 15.2em;
    width: 15.2em !important;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    left: 0.39em;
    top: 0.35em;
    font-size: 0.3em;
    background: #2E3034;
    text-align: center;
}
.nd-vc-info-status-perc-in-container{
    /* margin-left: 18px; */
    margin-left: 41px;
}
.ndvi__can__title{
    text-align: center;
    margin-top: 5px; 
}
.ndvi__can__title_span {
    font-size: 10px;
    font-weight: 600;
}
.nd-vc-info-status-image{
    margin-top: 5px;
    width: 26px;
}