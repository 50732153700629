.fleet-download-button {
  background-color: #22046B;
  color: #FFFFFF;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: -4rem;
  margin-top: 0px;
  margin-right:47px;
}

.fleet-searchBarDiv {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 15px; 
  background-color: #e7ecf7;
  border-radius: 4px;
  height: 45px;
  min-width: 400px;
  width: 100%;
  margin-left: -43rem;
  margin-top: 2rem;
}

.fleet-search-icon {
  margin-left: 10px; 
  color: #797694;
}

.fleet-search-input {
  background-color: #e7ecf7;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  height: 45px;
  padding: 0px 10px;
  margin-top:19px;
}

.fleetSearchInput {
  width: 479px !important
}

.ctaButtonsDiv {
  width: 220px !important
}

.search_filters {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}


th {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border-bottom: 1px solid #71797e;
}

td{
  text-align: center;
}


#myTable td {
  padding: 6.5px !important;
}

.fleetheading{
  text-align: left;
  font-size:18px;
  font-weight: 600;
}
.action_span{
  margin-left: -4px;
}

.fleet-search-bar-div {
  min-width: unset !important;
}

.fleet-multi-select-width {
  width: unset !important ;
}
.nd_table-head{
  vertical-align: baseline !important;
}