.checkboxButton {
    background-color: transparent;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
}
.checkboxButtonDisabled {
    opacity: 0.65;
}
.checkboxButton > span {
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 400;
    display: inline-block;
}
/*conflict-resolution-will be removed later*/