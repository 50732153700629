.immobilizeHistorytable th {
  color: #10204b !important;
  font-size: 16px !important;
  vertical-align: middle;
}

.immobilizeHistorytable td {
  color: #10204b !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.immobilizeHistorytable {
  border-collapse: separate;
  border-spacing: 1.2px 0px;
}

.mobilize-history-status {
  padding: 8px 35px 8px 35px;
  font-weight: 400;
  color: #06a561;
  background-color: #c4f8e2;
  border-radius: 4px;
  text-align: center;
}

.immobilize-history-status {
  padding: 8px 25px 8px 25px;
  font-weight: 400;
  color: #1e5eff;
  background-color: #c8d6fc;
  border-radius: 4px;
}
.immobilize-na-status {
  padding: 8px 25px 8px 25px;
  font-weight: 400;
  color: #000;
  background-color: #eff3f8;
  border-radius: 4px;
  width: 128px;
}

.reasonImmobilizeHistoryBtn {
  width: 178px;
  height: auto;
  padding: 8px;
  border-radius: 6px;
  background-color: #eff3f8;
  color: #10204b;
}

.dwnRefresh {
  width: 125px;
  height: 46px;
  top: 332px;
  left: 1572px;
  padding: 12px 8px 12px 8px;
  gap: 16px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  background-color: #ffffff;
  border: 1px solid #ffe9f6;
  font-weight: 500;
  color: #10204b;
  margin-right: 10px;
  text-align: center;
}