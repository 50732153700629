#Rc__Records__table__data {
    border-collapse: separate;
    border-spacing: 0 15px;
}

#Rc__Records__table__data th {
    width: 150px;
    text-align: center;
    padding: 5px;
}

#Rc__Records__table__data td {
    width: 212px;
    text-align: center;
    padding: 5px;
}

.Rc__records__row {
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 20px;
    border: 5px solid transparent;
    vertical-align: middle;
}

.Rc__records__row__td {
    margin-bottom: 0 !important;
}

.Rc__records__view__table td {
    background: #e7ecf7 !important;
    opacity: 1;
}

.Rc__records__view__table {
    vertical-align: middle;
    margin-bottom: 20px;
}


.Rc__records__view__table td:first-child {
    /* background: #fff !important;
    width: 20px !important */
}


.Rc__records__view__table td:first-child::before {
    display: none;
 }

/* .Rc__records__row_last td:first-child::before {
    height: calc(100% + 15px);
    width: 1px;
    left: 50%;
    top:-83px;
} */

.Rc__records__row td:first-child {
    background: #fff !important;
    width: 20px !important
}

.Rc__records-line-col {
    position: relative;
}

.Rc__records-line-col::before,
.Rc__records-line-col::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 50%;
    left: 50%;
    height: 1px;
    border: 1px dashed #BA6FA5;
}

.Rc__records-line-col::before {
    height: calc(100% + 15px);
    width: 1px;
    left: 50%;
    top: -60px;
}

.Rc__records-line-col-0::before {
	height: calc(100% - 6px);
	width: 1px;
	left: 50%;
	top: -15px;
}

.Rc__records-line-col--main::before {
    display: none;
}

.Rc__records__row_last .Rc__records-line-col::before {
    height: calc(100% - 30px);
}


.rc__borderGreen {
    border: 3px solid #009B88;
    width: 30px;
    opacity: 1;

}

.rc__borderOrange {
    border: 3px solid #E06000;
    width: 30px;
    opacity: 1;
}

.rc__borderBrown {
    border: 3px solid #632008;
    width: 30px;
    opacity: 1;
}

.rc__borderPurple {
    border: 3px solid #22046B;
    width: 30px;

    opacity: 1;
}
.rc__borderPink {
    border: 3px solid #B31F85;
    width: 30px;
    margin-left: 19rem;
    opacity: 1;
}

.rc__border_noncommunication{
    border: 3px solid #B31F85;
    width: 30px;
    margin-left: 91px;
    opacity: 1;
}

.rc__status_block_nocom_container{
    width: 230px;
     margin-left: auto ;
}

.rc__vehicle_status__heading{
    position: absolute;
    left: 55px;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 500;
}

.rc__status_block_container {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: center;
}

.status-block {
    display: flex;
    position: relative;
}

.time-block {
    display: flex;
}

.time-block p {
    margin-right: 10px;
}

.time-block span {
    margin-left: 10px;
    margin-top: 1px;
}

.end-time {
    color: #6480FF;
    position: absolute;
    top: 23px;
    left: 103px;
}

.dot-img {
    margin-top: 2px;
}

.Vs__records__details__container {
    display: flex;
    position: relative;
}

.Vs__records__details__time {
    margin-left: 12px;
    margin-right: 5px;
}

.Vs__records__details__start__time {
    display: flex;
    margin-top: 3px;
}

.Vs__records__details__start__time__text {
    margin-right: 10px;
}

.Vs__records__details__start__time__value {
    margin-left: 10px;
    margin-top: 1px;
}

.Vs__records__details__end__time {
    margin-top: 14px;
}

.Vs__records__details__end__time__contain {
    display: flex;
}

.Vs__records__details__end__time__text {
    margin-right: 10px;
}

.Vs__records__details__end__time__value {
    margin-left: 10px;
    margin-top: 1px;
}

.Vs__records__details__endT__duration {
    color: #6480FF;
    position: absolute;
    top: 31px;
}

.Vs__records__details__dote {
    margin-top: 10px;
}

.Rc__records__row__vs__details {
    display: flex;
    justify-content: space-around;
}
.vehicle-status-radio-input:checked[type=radio] {
	accent-color: #22046B;
    margin-left: 20px !important;
}
.vehicle-status-radio-input{
    margin-left: 20px !important;
}
.Vs__records__details__start__time__blank{
    margin-top: 1px;
}
.Vs__records__details__end__time__blank{
    position: absolute;
    bottom: 17px;
    margin-left: 10px;
}
.dvs__vehicleLine-img{
    margin-top: 5px;
}

.Rc_record_border_right{
    margin-right: 15px;
	padding-right:15px;
	border-right: 2px solid #707070;
}

/* .Rc_record_status_block{
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.selectLayout-historical-data {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 20px;
  }
  .data-points-multi-select-width {
    width: 200px;
    max-width: 240px;
    font-size: 14px;
    height: 45px !important;
  }