.nd__get-alert-report{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
}
#vd__vh__table th{
    background-color: #22046b !important;
    color: #ffff !important;
}
.vd-alert-tooltip {
    z-index: 9999 !important;
}