.nd__dark-tracking-web-cam-card {
    background: #111;
  background-image: url("/public/images/svgicon/webCamFrame.svg") !important ;
  background-repeat:no-repeat !important;
    box-shadow: 0px 3px 6px #00000029;
    background-size: 322px 188px;
    padding: 14px 32px;
    overflow: hidden;
    margin-left: 57px;
    margin-top: 50px;
}

.nd__dark-tracking-web-cam-card-iframe{
margin-left : -5px !important ;
}

.nd__tracking-web-cam-card > p {
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin-bottom: 8px;
}

.nd__tracking-web-cam-card iframe body {
    overflow: hidden;
}