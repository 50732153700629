.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  background-color: #1C1F28;
  border-radius: 10px;
  height: 45px;
  width: 100%;
  background: #1C1F28 !important;
  border: 3px solid #5D5F86;
  filter: drop-shadow(0 0 0.65rem #2E1364);
  min-width: 292px;
}

.searchIcon {
  margin-left: 10px;
  color: #797694;
}

.root {
  background-color: #e7ecf7;
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  height: 45px;
  padding: 0px 10px;
  color: #fff;
  background: #1C1F28;
  border-top: 3px solid #5D5F86;
  border-bottom: 3px solid #5D5F86;
  border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
/*conflict-resolution-will be removed later*/