.reporting__tab__container {
    background: #F6F6F6;
    display: flex;
    flex-direction: row;
}


.reporting__container{

    width:100%;
}

.reporting__body_wrapper {
    margin : 20px ;
}




.reporting-title {
    color: #10204B;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

.reporting__tabs {
    position: relative;
    width: 100%;
    padding: 8px 15px;
    text-align: center;
    cursor: pointer;
}

.reporting-title:hover {
    color: #10204B !important;
}

.reporting-title:focus {
    color: #10204B !important;
}


.active__tab {
    background-color: #ffffff;
    border-left: 0px !important;
    margin-bottom: 0px !important; 
    margin-top: 15px !important;
    padding-top: 15px  !important;
}



.reporting__vertical__line {
    border-left: 2px solid #707070;
    line-height: 9px;
    margin: 20px;
}


.active__tab + .reporting__vertical__line {
    border-left: none;
}

.reporting__container .reporting__vertical__line:first-child {
    border-left: none;
}

.charging-tab-container > .nav-tabs .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    top: 27px;
    background-color: #10214B;
  }
#uncontrolled-tab-example-tab-charge_report  {
    margin-left: 40px;
}