.new-dark-dashboard .custom-marker-popup,
.group-listing-dark .custom-marker-popup {
    background-color: #fff;
    border-radius: 5px;
}

.new-dark-dashboard .custom-marker-popup .leaflet-popup-content-wrapper,
.group-listing-dark .custom-marker-popup .leaflet-popup-content-wrapper {
    border-radius: 5px;
    text-align: center;
}

.new-dark-dashboard .custom-marker-popup .leaflet-popup-content,
.group-listing-dark .custom-marker-popup .leaflet-popup-content {
    margin: 8px 8px 8px 8px;
}

.new-dark-dashboard .nd__map-wrapper,
.group-listing-dark .nd__map-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #111;
}

.nd_group-sub-list {
  flex-direction: column;
}

.nd_subgroup-range{
  width: 213px!important;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px;
  border-radius: 7px;
  height: 40px;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.nd_subgroup-range {
  position: relative;
}

.nd_subgroup-range:hover {
  background: linear-gradient(45deg, rgb(17, 16, 24), rgba(0, 0, 0, 0.47), rgb(17, 16, 24));
}

.nd_subgroup-range:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(57, 255, 255, 0.8) 35%, #39FFFF 55%, rgba(57, 255, 255, 0.6) 60%, rgba(0, 0, 0, 0));
  /* transform: translateY(-1px); */
}

.nd_subgroup-range.selected {
  background: linear-gradient(45deg, rgb(17, 16, 24), rgb(0 0 0 / 47%), rgb(17, 16, 24));
}

.nd_subgroup-range.selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(52, 175, 213, 0.8) 30%, #34afd5 50%, rgba(52, 175, 213, 0.8) 70%, rgba(0, 0, 0, 0));
  /* transform: translateY(-1px); */
}

.nd_subgroup-border-style{
  padding-left: 10px;
  border-left: 1px solid #fff;
}

.nd__location-range {
  margin-bottom: 4px;
}

.nd_subgroup-range:first-child {
  margin-top: 6px; 
  margin-bottom: 6px;
}

.nd__group-dropdown-content{
  top:200px !important;
  overflow: hidden;
  width: 258px !important;
  height: 352px !important;
}

.new-dark-dashboard .nd__soc-sidebar-toggle-button,
.group-listing-dark .nd__soc-sidebar-toggle-button {
    position: absolute;
    right: 0;
    bottom: 30px;
    background-color: #22046b;
    color: white;
    border-radius: 5px 0px 0px 5px;
    padding: 0px 6px;
    height: 65px;
    z-index: 9999;
    transition: all 300ms linear;
}

.new-soc-sidebar-dark{
  height: calc(100vh - 11vh) !important;
}

.new-dark-dashboard .nd-soc-sidebar-dark,
.group-listing-dark .nd-soc-sidebar-dark {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 80px;
    z-index: 990;
    transition: all 300ms linear;
}

.new-dark-dashboard .nd__map_sidebar_controls,
.group-listing-dark .nd__map_sidebar_controls {
    position: absolute;
    z-index: 999;
    top: 14.5px;
    right: 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    transition: all 300ms linear;
}

.new-dark-dashboard .nd__map_sidebar_controls.map_sidebar_controls-open,
.group-listing-dark .nd__map_sidebar_controls.map_sidebar_controls-open {
    right: 38px;
    z-index: 999;
}

.new-dark-dashboard .nd__map_sidebar_controls .nd__msc__zoom-controls,
.group-listing-dark .nd__map_sidebar_controls .nd__msc__zoom-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(39, 41, 60, 1);
    padding: 8px;
    border: 1px solid rgba(73, 76, 100, 1);
    border-radius: 5px;
    position: fixed;
    bottom: 70px;
    right: 38px;
}

.new-dark-dashboard .nd__vehicle_info_map .nd__map_sidebar_controls.map_sidebar_controls-open,
.group-listing-dark .nd__vehicle_info_map .nd__map_sidebar_controls.map_sidebar_controls-open {
    right: 10px;
    top: 10px;
}

.new-dark-dashboard .nd__vehicle_info_map .nd__map_sidebar_controls,
.group-listing-dark .nd__vehicle_info_map .nd__map_sidebar_controls {
    height: calc(100% - 20px);
    width: 100%;
}
.new-dark-dashboard .nd__vehicle_info_map .nd__map_sidebar_controls .nd__msc__zoom-controls,
.group-listing-dark .nd__vehicle_info_map .nd__map_sidebar_controls .nd__msc__zoom-controls {
    right: 0;
    bottom: 0;
    position: absolute;
}

.new-dark-dashboard .nd__msc__reset,
.new-dark-dashboard .nd__msc__download,
.new-dark-dashboard .nd__msc__zoom-in,
.new-dark-dashboard .nd__msc__zoom-out,
.new-dark-dashboard .nd__msc__fullscreen,
.group-listing-dark .nd__msc__reset,
.group-listing-dark .nd__msc__download,
.group-listing-dark .nd__msc__zoom-in,
.group-listing-dark .nd__msc__zoom-out,
.group-listing-dark .nd__msc__fullscreen {
    background: #ffffff;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.new-dark-dashboard .nd__msc__zoom-in,
.group-listing-dark .nd__msc__zoom-in {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 34px;
    height: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.new-dark-dashboard .nd__msc__zoom-in img,
.new-dark-dashboard .nd__msc__zoom-out img,
.group-listing-dark .nd__msc__zoom-in img,
.group-listing-dark .nd__msc__zoom-out img {
    width: 26px;
    height: auto;
}

.new-dark-dashboard .nd__msc__zoom-out,
.group-listing-dark .nd__msc__zoom-out {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 34px;
    height: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.new-dark-dashboard .nd__soc-sidebar-open .nd__soc-sidebar-toggle-button,
.group-listing-dark .nd__soc-sidebar-open .nd__soc-sidebar-toggle-button {
    right: 80px;
}

.new-dark-dashboard .nd__soc-sidebar-open .nd-soc-sidebar-dark,
.group-listing-dark .nd__soc-sidebar-open .nd-soc-sidebar-dark {
    right: 0px;
    z-index: 997;
}

.new-dark-dashboard .nd__soc-sidebar-container,
.group-listing-dark .nd__soc-sidebar-container {
    height: 100%;
    padding-top: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.rightSideBgImg{
    position: absolute;
    height: 100%;
    width: 80px;
    top: 0px;
    z-index: -1;
    right: 0px;
}

.new-dark-dashboard .nd__soc-sidebar-open .nd__soc-container,
.new-dark-dashboard .nd__soc-sidebar-open .nd__location-container,
.group-listing-dark .nd__soc-sidebar-open .nd__soc-container,
.group-listing-dark .nd__soc-sidebar-open .nd__location-container {
    padding: 0px 10px;
    margin: 0;
    border: 0;
    width: 100%;
    height: 78px;
    background-color: transparent;
    background-position: center;  
}

.new-dark-dashboard .nd__soc-sidebar-open .nd__soc-container p,
.new-dark-dashboard .nd__soc-sidebar-open .nd__location-container p,
.group-listing-dark .nd__soc-sidebar-open .nd__soc-container p,
.group-listing-dark .nd__soc-sidebar-open .nd__location-container p {
    margin: 0;
    font-size: 10px;
    color: #ffffff;
    font-weight: 500;
}

.new-dark-dashboard .nd__map-soc-dropdown-content,
.group-listing-dark .nd__map-soc-dropdown-content {
    position: fixed;
    right: 90px;
    z-index: 999;
    border-radius: 12px;
    padding: 10px 10px 0px 10px;
    color: rgba(166, 249, 255, 1); 
    border: none;
    width: 210px;
    box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    top: 180px !important;
    background: linear-gradient(340deg, rgb(35 84 127 / 79%) 19.63%, rgb(82 14 88 / 94%) 83.01%);
   
}
.new-dark-dashboard .nd__map-soc-dropdown-content .form-check,
.group-listing-dark .nd__map-soc-dropdown-content .form-check {
    display: inline-flex !important; 
    align-items: flex-start !important;
}

.nd__dark-map-wrapper .nd__map-soc-dropdown-content::before,
.group-listing-dark .nd__map-soc-dropdown-content::before {
    display: none;
  
}

.new-dark-dashboard .nd__map-location-dropdown-content,
.group-listing-dark .nd__map-location-dropdown-content {
    position: absolute;
    right: 90px;
    z-index: 999;
    border-radius: 12px;
    padding: 10px 10px 0px 10px;
    color: rgba(166, 249, 255, 1); 
    border: none;
    width: 225px;
    box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    top: 120px;
    background: linear-gradient(340deg, rgb(35 84 127 / 79%) 19.63%, rgb(82 14 88 / 94%) 83.01%);
    height: 401px;
    
}
.nd__dataSource-dropdown-content{
  height: 140px !important;
  top: 263px !important;
}

.nd__vechile-category-dropdown-content{
  height: 190px !important;
  top: 320px !important;
}
.nd__group-category-dropdown-content{
  height: 319px !important;
  top: 310px !important;
}

.new-dark-dashboard .nd__map_location,
.group-listing-dark .nd__map_location {
    overflow-y: auto;
    height: 482px;
    border-radius: 5px;
}


.nd__dark-map-wrapper .nd__map-location-dropdown-content::before,
.group-listing-dark .nd__map-location-dropdown-content::before {
    display: none;

}

.new-dark-dashboard .nd__render-soc-list,
.group-listing-dark .nd__render-soc-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0px;
    border-bottom:none;
 
}
.new-dark-dashboard .nd__render-soc-list:last-child,
.group-listing-dark .nd__render-soc-list:last-child {
   
    border-bottom: none;

}


.new-dark-dashboard .nd__render-soc-list .nd__soc-range,
.group-listing-dark .nd__render-soc-list .nd__soc-range {

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    border-radius: 7px;
    height: 40px;
    cursor: pointer;
    width: 100%;
    align-items: center;
}

/* animation top border */

.new-dark-dashboard .nd__render-soc-list .nd__soc-range,
.group-listing-dark .nd__render-soc-list .nd__soc-range {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid transparent;
    transition: border 0.5s ease;
  }
  
  
  .new-dark-dashboard .nd__render-soc-list .nd__soc-range::before,
  .group-listing-dark .nd__render-soc-list .nd__soc-range::before {
    position: absolute;
    left: 10%;
    width: 80%;
    height: 1px;
    /* background: linear-gradient(90deg, transparent, #A6F9FF, transparent); */
    z-index: 10;
  }
  
  /* .new-dark-dashboard .nd__render-soc-list .nd__soc-range::after,
  .group-listing-dark .nd__render-soc-list .nd__soc-range::after {
    content: '';
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 0;
    width: 80%;
    height: 1px;
    background: linear-gradient(90deg, transparent, transparent);
    z-index: 10;
    transition: background 2s linear; 
  } */
  
  
  .new-dark-dashboard .nd__render-soc-list .nd__soc-range::before,
  .group-listing-dark .nd__render-soc-list .nd__soc-range::before {
    top: 0;
  }
  
  .new-dark-dashboard .nd__render-soc-list .nd__soc-range::after,
  .group-listing-dark .nd__render-soc-list .nd__soc-range::after {
    bottom: 0;
  }
  .new-dark-dashboard .nd__render-soc-list .nd__soc-range:hover,
  .group-listing-dark .nd__render-soc-list .nd__soc-range:hover {
    background: linear-gradient( 45deg,
      rgb(17, 16, 24),rgb(0 0 0 / 47%), rgb(17, 16, 24)
    );
   position: relative;
  }

  .new-dark-dashboard .nd__render-soc-list .nd__soc-range:hover::before,
  .group-listing-dark .nd__render-soc-list .nd__soc-range:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(52, 175, 213, 0.8) 25%, #34afd5 80%, rgba(52, 175, 213, 0.8) 93%, rgba(0, 0, 0, 0));
    /* transform: translateY(-1px); */
  }

  .nd__soc-range {
    position: relative;
    transition: background 0.3s ease, border-top 0.3s ease;
  }
  
  .nd__soc-range.selected {
    background: linear-gradient(45deg, rgb(17, 16, 24), rgb(0 0 0 / 47%), rgb(17, 16, 24));
  }
  
  .nd__soc-range.selected::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(52, 175, 213, 0.8) 30%, #34afd5 50%, rgba(52, 175, 213, 0.8) 70%, rgba(0, 0, 0, 0));
    /* transform: translateY(-1px); */
  }
  


  .new-dark-dashboard .nd__render-location-list,
  .group-listing-dark .nd__render-location-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0px;
    border-bottom:none;
    width: 250px;
}
  .nd__group-sub-list {
      display: block !important;
  }
  .nd__group-range{
    background: none !important;
  }


.new-dark-dashboard .nd__render-location-list:last-child,
.group-listing-dark .nd__render-location-list:last-child {
   
    border-bottom: none;

}


.new-dark-dashboard .nd__render-location-list .nd__location-range,
.group-listing-dark .nd__render-location-list .nd__location-range {

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 6px;
    border-radius: 7px;
    height: 40px;
    cursor: pointer;
    width: 100%;
}

/* animation top border */

.new-dark-dashboard .nd__render-location-list .nd__location-range,
.group-listing-dark .nd__render-location-list .nd__location-range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid transparent;
    transition: border 0.5s ease;
  }
  
  
  /* .new-dark-dashboard .nd__render-location-list .nd__location-range::before,
  .group-listing-dark .nd__render-location-list .nd__location-range::before {
    content: '';
    position: absolute;
    left: 2%;
    height: auto;
    z-index: 10;
  } */
  
  .new-dark-dashboard .nd__render-location-list .nd__location-range::after,
  .group-listing-dark .nd__render-location-list .nd__location-range::after {
    content: '';
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 0;
    width: 80%; /* Adjust width for 80% visibility */
    height: 1px;
    background: linear-gradient(90deg, transparent, transparent);
    z-index: 10;
    transition: background 2s linear; /* Transition for animation */
  }
  
  
  .new-dark-dashboard .nd__render-location-list .nd__location-range::before,
  .group-listing-dark .nd__render-location-list .nd__location-range::before {
    top: 0;
  }
  
  .new-dark-dashboard .nd__render-location-list .nd__location-range::after,
  .group-listing-dark .nd__render-location-list .nd__location-range::after {
    bottom: 0;
  }
  .new-dark-dashboard .nd__render-location-list .nd__location-range:hover,
  .group-listing-dark .nd__render-location-list .nd__location-range:hover {
    position: relative;
    background: linear-gradient( 45deg, rgb(17, 16, 24), rgb(0 0 0 / 47%), rgb(17, 16, 24));
  }

  .groupArrowIcon{
    width: 22px;
    margin-right: 3px;
  }


  .new-dark-dashboard .nd__render-location-list .nd__location-range:hover::before,
  .group-listing-dark .nd__render-location-list .nd__location-range:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(52, 175, 213, 0.8) 30%, #34afd5 50%, rgba(52, 175, 213, 0.8) 70%, rgba(0, 0, 0, 0));
    /* transform: translateY(-1px); */
  }

  .nd__location-range {
    position: relative;
    transition: background 0.3s ease, border-top 0.3s ease;
  }
  
  .nd__location-range.selected {
    background: linear-gradient(45deg, rgb(17, 16, 24), rgb(0 0 0 / 47%), rgb(17, 16, 24));
  }
  
  .nd__location-range.selected::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(52, 175, 213, 0.8) 30%, #34afd5 50%, rgba(52, 175, 213, 0.8) 70%, rgba(0, 0, 0, 0));
    /* transform: translateY(-1px); */
  }
  
  .new-dark-dashboard .location-count,
  .group-listing-dark .location-count {
    color: #39FFFF !important;
    font-weight: 700  !important;
    margin-bottom: 0px !important;
  }
  .new-dark-dashboard .sub-group-count{
    margin-top: 0px !important;
    padding-right: 25px !important;
  }
 

  
  /* Adjusted keyframes for segmented clockwise border animation */
  @keyframes segmentedClockwiseBorder {
    0%, 12.5% {
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
    }
    25% {
      border-top: 1px solid #A6F9FF;
      border-right: 1px solid #A6F9FF;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
    }
    37.5%, 50% {
      border-top: 1px solid transparent;
      border-right: 1px solid #A6F9FF;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
    }
    62.5%, 75% {
      border-top: 1px solid transparent;
      border-right: 1px solid #A6F9FF;
      border-bottom: 1px solid #A6F9FF;
      border-left: 1px solid transparent;
    }
    87.5%, 100% {
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid #A6F9FF;
      border-left: 1px solid transparent;
    }
  }
  
  @keyframes reverseSegmentedClockwiseBorder {
    0%, 12.5% {
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid #A6F9FF;
      border-left: 1px solid transparent;
    }
    25% {
      border-top: 1px solid transparent;
      border-right: 1px solid #A6F9FF;
      border-bottom: 1px solid #A6F9FF;
      border-left: 1px solid transparent;
    }
    37.5%, 50% {
      border-top: 1px solid transparent;
      border-right: 1px solid #A6F9FF;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
    }
    62.5%, 75% {
      border-top: 1px solid #A6F9FF;
      border-right: 1px solid #A6F9FF;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
    }
    87.5%, 100% {
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
    }
  }


.new-dark-dashboard .nd__render-soc-list .nd__soc-range p,
.group-listing-dark .nd__render-soc-list .nd__soc-range p {
    margin: 0;
    font-size: 0.75rem;
    color: #111;
    font-weight: 500;
}
.new-dark-dashboard .nd__render-location-list .nd__location-range p,
.group-listing-dark .nd__render-location-list .nd__location-range p {
    margin: 0;
    font-size: 0.75rem;
    color: #111;
    font-weight: 500;
}

.new-dark-dashboard .nd__render-soc-list .nd__soc-info svg,
.group-listing-dark .nd__render-soc-list .nd__soc-info svg {
    width: 100% !important;
    cursor: pointer;
}

.new-dark-dashboard .nd__map-marker,
.group-listing-dark .nd__map-marker {
    display: inline-flex;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.new-dark-dashboard .nd__map-marker__tip,
.group-listing-dark .nd__map-marker__tip {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    top: -6px;
    border-bottom-width: 6px;
    border-bottom-style: solid;
}

.new-dark-dashboard .nd__map-marker__live,
.group-listing-dark .nd__map-marker__live {
    background-color: #009B88;
}
.new-dark-dashboard .nd__map-marker__live .nd__map-marker__tip,
.group-listing-dark .nd__map-marker__live .nd__map-marker__tip {
    border-bottom-color: #009B88;
}

.new-dark-dashboard .nd__map-marker__idle,
.group-listing-dark .nd__map-marker__idle {
    background-color: #E06000;
}

.new-dark-dashboard .nd__map-marker__idle .nd__map-marker__tip,
.group-listing-dark .nd__map-marker__idle .nd__map-marker__tip {
    border-bottom-color: #E06000;
}

.new-dark-dashboard .nd__map-marker__immobile,
.group-listing-dark .nd__map-marker__immobile {
    background-color: #E06000;
}

.new-dark-dashboard .nd__map-marker__immobile .nd__map-marker__tip,
.group-listing-dark .nd__map-marker__immobile .nd__map-marker__tip {
    border-bottom-color: #E06000;
}

.new-dark-dashboard .nd__map-marker__charging,
.group-listing-dark .nd__map-marker__charging {
    background-color: #5491F5;
}

.new-dark-dashboard .nd__map-marker__charging .nd__map-marker__tip,
.group-listing-dark .nd__map-marker__charging .nd__map-marker__tip {
    border-bottom-color: #5491F5;
}

.new-dark-dashboard .nd__map-marker__non-communication,
.group-listing-dark .nd__map-marker__non-communication {
    background-color: #DE54E2;
}

.new-dark-dashboard .nd__map-marker__non-communication .nd__map-marker__tip,
.group-listing-dark .nd__map-marker__non-communication .nd__map-marker__tip {
    border-bottom-color: #DE54E2;
}

.new-dark-dashboard .nd__map-wrapper .leaflet-div-icon,
.group-listing-dark .nd__map-wrapper .leaflet-div-icon {
    background: none;
    border: none;
}

.new-dark-dashboard .nd__map-wrapper > .leaflet-container > .leaflet-control-container > .leaflet-top,
.group-listing-dark .nd__map-wrapper > .leaflet-container > .leaflet-control-container > .leaflet-top {
    z-index: 999 !important;
}

.new-dark-dashboard .nd__map-wrapper > .leaflet-container,
.group-listing-dark .nd__map-wrapper > .leaflet-container  {
    background-color: black;
}



.new-dark-dashboard .nd__map-wrapper .nd__map-location-dropdown-content .form-check-label,
.group-listing-dark .nd__map-wrapper .nd__map-location-dropdown-content .form-check-label {
    color: rgba(166, 249, 255, 1) !important;
    margin-left: 18px !important;
}

.new-dark-dashboard .nd__map-wrapper .leaflet-layer,
.new-dark-dashboard .nd__map-wrapper .leaflet-control-zoom-in,
.new-dark-dashboard .nd__map-wrapper .leaflet-control-zoom-out,
.new-dark-dashboard .nd__map-wrapper .leaflet-control-attribution,
.group-listing-dark .nd__map-wrapper .leaflet-layer,
.group-listing-dark .nd__map-wrapper .leaflet-control-zoom-in,
.group-listing-dark .nd__map-wrapper .leaflet-control-zoom-out,
.group-listing-dark .nd__map-wrapper .leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}   


#uniqueFilterButton {
    position: relative;
    top: 13px;
    background-color: #4C50EA;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    z-index: 888;
    float: right;
    font-size: 14px;
    font-size: 14px;
    align-items: center;
}

.unique-sidebar {
  height: 90%;
  width: 0;
  position: fixed;
  top: 90px;
  right: 0;
  background-color: #35417f;
  color: #b3b3c6;
  overflow-x: visible;
  transition: 0.5s;
  padding-top: 0px;
  z-index: 1000;
  border-radius: 8px;
}

.unique-sidebar.open {
  width: 540px; 
}

.unique-sidebar-header {
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 8px;
  top: 5px;
  height: 30px;
}

.unique-closebtn {
  font-size: 36px;
  cursor: pointer;
  color: white;
}

.unique-filter-options {
  width: 295px;
  padding: 0px 15px;
  color: #b3b3c6;
  background: linear-gradient(to right, #354180, #1C223F);
}

.unique-filter-category {
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.unique-filter-category h3 {
  margin-bottom: 10px;
  color: #ffffff; 
}

.unique-filter-category ul {
  list-style: none;
  padding: 0;
}

.unique-filter-category li {
  margin-bottom: 10px;
  color: #b3b3c6;
}

.unique-filter-actions {
  display: flex;
  justify-content: center;
  gap: 25px;
  position: absolute;
  bottom: 60px;
  width: 540px;
  left: 0px;
  padding: 27px;
  bottom: 16px;
}

.unique-clear-btn, .unique-apply-btn {
  width: 150px;
  height: 36px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  color: white;
}

.unique-clear-btn {
  border: 1.5px solid transparent;
  border: 1px solid #BC2EF0;
  background: transparent;
  border-radius: 10px;
}

.unique-apply-btn {
  background-color: #BC2EF0; 
}

.unique-clear-btn:hover, .unique-apply-btn:hover {
  opacity: 0.9;
}

.active-group-style{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
  padding: 20px 0px 25px 10px;
  margin-right: 17px;
  border-bottom: 1px solid  #39FFFF;
}

.selectedOptionMainContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    margin-top: 8px;
    z-index: 888;
    position: relative;
    float: right;
    top: 5px;
}
.optionSelectedContainer {
  border: 1px solid;
  background: #354180;
  padding: 5px 10px;
  gap: 10px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
}
.nd__select-all{
  padding-bottom: 10px !important;
}

.nd__select-all-group {
  display: flex;
  align-items: center;
}

.custom-checkbox {
  position: relative;
  display: inline-block;
}

.custom-checkbox.checked::after {
  content: ""; 
  position: absolute;
  top: 0px;
  left: 3px;
  width: 4px;
  height: 7px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); 
}

