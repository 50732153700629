.panelContainer {
    width: 100%;
    max-width: 340px;
    background: #ffffff;
    height: auto;
    max-height: calc(100% - 220px);
    overflow: auto;
    border-radius: 12px;
    position: absolute;
    left: calc(340px + 20px);
    top: calc(85px + 45px);
    z-index: 402;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
    color: #1f2a5d;
    padding: 0 15px;
}

.panelContainer button {
    background: transparent;
}

.panelContainer .panelButton {
    border: unset;
    flex: 0 0;
    color: #ffffff;
    padding: 7px 16px;
    border-radius: 4px;
    font-size: 0.75rem;
    background-color: #22046b;
}
.panelContainer .panelButton:disabled {
    opacity: 0.75;
}

.panelSection {
    padding: 10px 5px;
    border-bottom: 2px solid #e7ecf7;
}
.panelSectionHeader {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
}
.panelSection:last-of-type {
    border-bottom: 0;
}

.panelSectionRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.panelDetailsRow {
    display: flex;
    align-items: flex-start;
}
.panelDetailsBox {
    display: flex;
    align-items: center;
}

.panelDetailsRow span {
    font-size: 0.75rem;
}

.amenitiesPanelSection > span {
    font-size: 0.875rem;
    font-weight: 600;
    display: inline-block;
}

.amenitiesWrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 0.875rem;
}
.amenitiesWrapper > p {
    font-size: 0.875rem;
    margin: 0;
}

.amenityBox {
    display: inline-flex;
    align-items: center;
    gap: 5px;
}
.amenityBox span{
   font-size: 0.75rem;
}
.fenceNameTitle {
    font-size: 14px;
    margin: 0;
    color: #1f2a5d;
    font-weight: 500;
}
.fenceCreatedBy {
    font-size: 12px;
    margin: 0;
    color: #1f2a5d;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
}
.identifiersTitle {
    font-size: 12px;
    margin: 0;
    color: #1f2a5d;
    font-weight: 500;
}

.fenceTypeWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-left: -5px;
}
.fenceTypeIcon {
    flex: 0 0 22px;
    width: 22px;
    height: auto;
}
.fenceTypeLabel {
    flex: 1 1 auto;
    margin: 0;
    color: #1f2a5d;
    font-size: 0.875rem;
    font-weight: 500;
}

.fenceInfoTable {
    width: 100%;
    font-size: 0.875rem !important;
}
.fenceInfoTable tr td {
    text-align: left;
    font-family: Poppins !important;
    padding: 2px 0;
    font-size: 0.75rem !important;
    vertical-align: unset;
}
.fenceInfoTable tr td:nth-child(1) {
    width: 130px !important;
    font-weight: 500 !important;
}
.fenceInfoTable tr td:nth-child(2) {
    font-size: 0.875rem !important;
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 500 !important;
}

.infoPanelSection .fenceInfoTable tr td:nth-child(1) {
    width: 90px !important;
}
.infoPanelSection .fenceInfoTable tr td:nth-child(2) {
    padding-left: 4px;
    padding-right: 4px;
}
