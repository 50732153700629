.new-dark-dashboard .nd__dark-tracking-alert-card {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    align-items: center;
    background: #111;
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px;
    border-radius: 4px;
}

.new-dark-dashboard .nd___dark-tracking-alert-card-container{
    border-radius: 0px 20px 20px 0px !important;
    background: linear-gradient(90deg, rgb(17 16 24 / 0%) 0.28%, rgb(0 0 0) 129.42%) !important;
    box-shadow: none !important;
    background-blend-mode: overlay !important;
    padding: 15px 0px 0px;
}

.new-dark-dashboard .nd_Dark .nd__tac-container {
    padding: 5px;
    border-radius: 8px;
    width: 50%;
    /* width: 33%; */
}
.new-dark-dashboard .nd___dark-tracking-alert-card{
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-left: 30px;
}
.new-dark-dashboard .nd__tac-container--disabled .tac__outer-circle {
    border-color: #808080 !important;
}
/* .nd__tac-container--disabled .tac__inner-circle {
    background-color: #808080 !important;
} */

/* .nd__tac-container--disabled .tac__inner-circle_label {
    color: #808080 !important;
} */
.new-dark-dashboard .tac__inner-circle_label{
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
}



.new-dark-dashboard .nd_Dark .nd__tac-button {
    min-width: 140px;
    background-color: transparent;
    margin: 0;
    padding: 0;
    text-align: left;

}

.new-dark-dashboard .nd_Dark .nd__tac-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: #FFFFFF;
}

.new-dark-dashboard .nd__tracking-alert-card .tac__outer-circle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    border: 1px solid #E92022;
    flex: 0 0 auto;
}
.new-dark-dashboard .nd__tac-container--disabled .tac__outer-circle{ 
    background: #8f8888 !important; 
    box-shadow: 0px 0px 4px 5px #665f5f !important;
    /* box-shadow: 1px 1px 10px 2px rgb(139, 133, 133)  !important; */
}

.new-dark-dashboard .nd_Dark .tac__outer-circle{
    height: 40px;
    min-width: 40px;
    /* width: 40px; */
    background: #FF0000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 4px; */
    border: 1px solid #190b0b8f;
    box-shadow: 0px 0px 4px 5px #a43c3c;
}

.new-dark-dashboard .nd__tracking-alert-card .tac__inner-circle {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #E92022;
    color: #FFFFFF;
    font-size: 0.75rem;
}

.new-dark-dashboard .nd__tracking-alert-card p {
    margin: 0;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
}