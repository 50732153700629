.container {
    flex: 1 0 auto;
    overflow-y: auto;
}

.sectionHeading {
    font-size: 1rem;
    color: white;
    font-weight: 500;
}

.cardsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    row-gap: 30px;
    column-gap: 15px;
}

.cardsWrapper::-webkit-scrollbar {
    width: 10px !important;
}

.cardsWrapper::-webkit-scrollbar-track {
    background: #393939 !important;
}

.cardsWrapper::-webkit-scrollbar-thumb {
    background: #0B0C12 !important;
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    background-clip: content-box !important;
}