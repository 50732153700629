.file-upload-container {
  border: 1.4px solid #d3d3d3;
  border-radius: 8px;
  margin-top: 35px;
  width: 440px;
  margin-left: 13px;
  font-size: 12px;
  font-weight: 600;
  color: #1f2a5d;
  padding: 10px 0px;
}

.progress-bar {
  height: 4px !important;
  background-color: #67a3ff !important;
  border-radius: 2px !important;
}

.progress {
  height: 4px !important;
  background-color: #cecece !important;
}

.upload-spinner {
  padding: 6px !important;
  color: #67a3ff !important;
}

.close-icon {
  color: #acacac;
  height: 16px;
  width: 16px;
}

.cls-btn {
  background-color: unset;
  border: unset;
}

.margin-left {
  margin-left: -20px;
}

.upload-size {
  color: #b3b3b3;
  font-size: 12px;
}
