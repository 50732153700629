:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
  --primary-color: #10214b;
  --secondary-color: #dddddd;
  --text-white-color: #ffffff;
}

.main-container {
  background-color: #fff;
  padding-left: -2.5rem !important;
  height: 87vh;
  max-height: 100vh;
}

.image-container {
  background-color: #f5f7f9;
  height: 99vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-container {
  background-color: #fff;
  height: 75vh;
  max-height: 100vh;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}


.login-btn{
  background-color: yellow;
  font-weight: 500 !important;
}


.inputfields {
  background: #E7ECF7 !important;
  color: #797694 !important;
  font-weight: 400 !important;
  border: none !important;
  height: 48px !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}

.no-value-selected-input .inputfields{
  background: #F5F7F9 !important;
}



.inputfield:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.error-message {
  color: #ff0000;
  white-space: nowrap;
  font-size: 13px;
  text-align: left;
}

.formLabel {
  color: #1f2a5d !important;
  font-size:14px !important;
  text-align: start;
}

.form-label-group.required .formLabel {
  display: flex;
  align-items: center;
}

.form-label-group.required .formLabel::after {
  content: "";
  margin-left: 5px;
}

.name-inputs {
  display: flex;
  gap: 10px;
}

.name-inputs input {
  flex: 1;
  width: 100%;
}

.row .form-label-group.required {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.row .form-label-group.required .formLabel,
.row .form-label-group.required select {
  width: 100%;
}

.LoginImage {
  height: 500px !important;
  width: 500px;
}

.LogoImage {
  height: 40px !important;
  width: 250px;
}


.RememberCheckbox {
  color: var(--primary-color);
  background-color: var(--primary-color);
}

.RememberMeLabel {
  color: var(--primary-color) !important;
  font-weight: 600;
}

.forgotPassLabel {
  color: var(--primary-color) !important;
  font-weight: 600;
}

.CreateAccountLabel {
  color: var(--primary-color);
  font-weight: 600;
}

.CreateAccountLink {
  color: var(--primary-color);
  font-weight: 600;
}

.ContactUsLabel {
  color: #d1108c;
}

.ContactUsNum {
  color: var(--primary-color);
  font-weight: 600;
}

.EmailUsLabel {
  color: #d1108c;
}

.EmailUsEmail {
  color: var(--primary-color);
  font-weight: 600;
}

.name-inputs {
  display: flex;
  gap: 10px;
}

.name-inputs input {
  flex: 1;
}

.send-otp {
  background-color: transparent;
  border: none;
  color: #d1108c;
  cursor: pointer;
  font-size: 14px;
  text-align: left !important;
}

/* .form-label {
  margin-right: 185px;
} */

.checkbox-container {
  margin-top: 30px;
}

/* .checkbox-container .form-check-input {
  margin-right: -14px;
} */

.checkbox-container .form-check-label {
  font-size: 13px;
  /* font-weight: bold; */
}


.otp-success-icon-signup {
  width: 30px;
  right: 87px;
  position: absolute;
  margin-top: 41px;
  color: #18cb06 !important;
}


.otp-timer{
  margin-top: 10px;
  font-size: 13px;
  color: #a2a2a2;
  font-weight: 500;
}


#otp{

padding-left: 25px;
padding-right: 20px;
}
.password-exclamation-icon {
  width: 30px;
  right: 6px;
  position: absolute;
  margin-top: -32px;
  color: #e21212 !important;
}

.password-success-icon {
  width: 30px;
  right: 104px;
  position: absolute;
  margin-top: 5px;
  color: #18cb06 !important;
}
.login-account-link {
  color:#d1108c;
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
  margin-left: -4px;
}


.login-account-label {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 14px;
}

.Login-Heading {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 28px;
 
}
.creater-Heading {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 28px !important;
}
.password-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  display: flex;
    justify-content: center;
}

.password-visibility-icon {
  position: absolute;
  top: 16px;
  right: 40px;
  transform: translateY(-50%);
  cursor: pointer;
 
}


#confirmPassword{
  padding-right: 25px !important;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.dropdown-arrow svg {
  font-size: 20px;
  color: #1f2a5d;
  font-weight: bold;
}


.login-message {
  margin-top: 1rem;
}

.asterisk-red {
  color: red;
  padding-left: 1px;
}

#terms-checkbox{
  background-color: #dadada !important;
}
#terms-checkbox:checked{
  background-color: #1f2a5d !important;
}

.custom-gap{
  gap: 10rem; 
}

.otp-timer-div{
  margin-top:8px;
  font-size: 13px;
}

/* Styles for error  & success popover */
#error-popover {
  background-color: white;
  color: #F20000;
  max-width: 300px; 
  border-radius: 10px;
}

#error-popover .popover-body {
  color: #F20000;
  font-size: 12px; 
}

.signupLabel{
  font-weight: 500;
}

#success-popover {
  background-color: white;
  color: green;
  max-width: 300px; 
  border-radius: 10px ;
}

#success-popover .popover-body {
  color: green;
  font-size: 12px; 
}

.has-success {
  color: #28a745;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.chagepassword-success {
  width: 30px;
  right: 6px;
  position: absolute;
  margin-top: -32px;
  color: #18cb06 !important;
}
.chagepassword-error {
width: 30px;
right: 6px;
position: absolute;
margin-top: -32px;
color: #e21212 !important;
}
/* CSS file or style block */
.text-green {
color: #18cb06 !important; 
}

.text-red {
color: #e21212 !important;
}
.cp_form_group{
  width: 251px;
}

.popover-arrow{
  transform: rotate(90deg);
  position: absolute;
  top: -12px;
  left: 106px;
}

.error-popover{
  position: absolute;
  top: 35px;
  right: -90px;

}
.create-signup-container{
  margin-top: 2rem !important;
}



  .signupLabel{
    width: 250px !important;
  }

  .register-form .inputfields{
    width: 250px !important;
  } 

  .register-form .row{
    margin:15px  25px !important;
  } 

  .location-multi-select-width{
    width: 250px !important;
  }
  .location-multi-select-width{
    width: 250px !important;
  }
  .signup-form{
    padding: 0 14rem !important;
  }

  .otp__senderror{
    margin-left: 20px !important;
  }

  
  .contact-us-text {
    color: var(--primary-color);
    /* font-weight: 600; */
    font-size: 16px;
  }
  
  .email-link {
    text-decoration: none;
    color: #1f2a5d;
    /* font-weight: 600; */
    font-size: 16px;
  }
  .no-value-selected .css-13cymwt-control,
  .no-value-selected .css-egi0ci-ValueContainer {
     background-color: #F5F7F9 !important;
     color: #797694 !important;
 }



  @media (min-width: 1500px) {

    .register-form .inputfields {
      width: 300px !important;
      height: 48px !important;
  }
  
  .location-multi-select-width {
    width: 300px !important;
    height: 48px !important;
    background: #E7ECF7 !important;
}

.location-multi-select-width-before {
  width: 300px !important;
  height: 48px !important;
  background: #F5F7F9!important;
}

.login-account-label {
  font-weight: 500;
  font-size: 14px;
  margin-left: 4px;
}

.forgot-account-link{
  color: #d1108c;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}
.login-account-link {
  color: #d1108c;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}
.forgot-account-link{
  color: #d1108c;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}
.contact-us-form {

  padding: 0 65px;

}
.contact-us-text {
  color: var(--primary-color);
  font-size: 14px;
}

.email-link {
  text-decoration: none;
  color: #1f2a5d;
  font-size: 14px;
}

.row .form-label-group.required {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 2px;
}


.row .form-label-group-signup{
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 13px !important;
}
.Login-Heading {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 26px;
}
.creater-Heading {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 26px !important;
}


#password-visibility-icon__signup_first{
  color: #22046B !important;
  margin-top: 8px !important;
  margin-left:10px !important;
  right: 50px  ! important;
}

#password-visibility-icon__signup_second{
  color: #22046B !important;
  margin-top: 8px !important;
  right: 50px  ! important;
 
}
.create-password-tooltip-signup{
  margin-top: -36px !important;
    right: 0px ! important;
}

#password-tick-icon__signup_second{
  width: 30px;
  right: 10px !important;
  position: absolute;
  margin-top: 12px !important;
  color: #18cb06 !important ;

}


#password-tick-icon__signup_first{
  width: 30px;
  right: 10px !important;
  position: absolute;
  margin-top: 12px !important;
  color: #e21212 !important;

}

.lastname_hf{
  margin-left: 12px !important;
}


.inputfields input{
  height: 48px !important;
}
  
.signup-btn-container{
  margin-top: 73px !important;
  margin-left: -42px !important;

}
.form-last-lable{
  
  margin-left: 20px !important;

}
.form-last-input{
    
  margin-left: 18px !important;
}
.inputfields-create{
  height: 48px !important;
}
.checkbox-container {
  margin-top: 50px;

}
.create-password-fields{
  width: 363px;
  margin-left: -16px;
  margin-top: 13px;
}

.create-signup-btn{
  margin-left: 0;
}

.checkbox-container .form-check-label {
  font-size: 14px;
}
.form-by-check-label{
 
  margin-right: 41px;

}
.create-password-input-container{
  margin-left: 14px !important;
}
.create-new-password-label{
  margin-left: 15px !important;
  margin-top: 4px !important;
}
.otp-success-icon-signup {
  width: 30px;
  right: 87px;
  position: absolute;
  margin-top: 41px;
  color: #18cb06 !important;
}



  }

/*conflict-resolution-will be removed later*/