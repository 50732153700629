.modalContiner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(10px) !important;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}
.modalWrapper {
    background: linear-gradient(328deg, rgba(117, 9, 255, 0.24) 6.73%, rgba(255, 255, 255, 0.24) 100.28%);
    stroke-width: 1px;
    stroke: #FFF;
    filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.16));
    backdrop-filter: blur(26px);
    flex-direction: column;
    height: auto;
    width: 35vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding-bottom: 20px;
    border: 1px solid rgba(0, 180, 255, 0.5); /* Thinner border with reduced opacity */
    position: relative; /* Ensure the ::after pseudo-element is correctly positioned */
}

.modalWrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    pointer-events: none;
    box-shadow: 0 0 5px rgba(0, 180, 255, 0.3); /* Thinner glow effect */
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    height: 40px;
}

.modalTitle {
    flex: 1;
    color:  #FFF;
    font-family: Inter;
    font-size: 1.55rem;
    font-style: normal;
    line-height: normal;
    margin-top: 20px;
    margin-left: 55px;
}

.modalCrossIcon {
    width: 35px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}   

.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
}

.defaultModalContiner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(214.25deg, rgba(18, 113, 255, 0.035) 6.73%, rgba(255, 26, 218, 0.015) 100.28%);
    backdrop-filter: blur(10px) !important;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}

.defaultModalWrapper {
    background: rgba(31, 44, 100, 0.7);
    border: 1px solid;
    border-image-source: conic-gradient(from 180deg at 51.95% 49.81%, rgba(0, 0, 0, 0.105455) -2.11deg, rgba(51, 66, 255, 0) 131.45deg, #37F4F4 175.58deg, rgba(51, 66, 255, 0) 252.32deg, rgba(0, 0, 0, 0.0885149) 310.85deg, rgba(0, 0, 0, 0.105455) 357.89deg, rgba(51, 66, 255, 0) 491.45deg),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    backdrop-filter: blur(26px);
    /* max-width: 35vw; */
    /* width: 100%; */
    border-radius: 10px;
    padding: 40px 60px;
    /* padding-bottom: 40px; */
    box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 100px 100px 0px rgba(0, 0, 0, 0.25);
}

.defaultModalWrapper::before{
    content: '';
    position: absolute;
    top: 0; 
    left: 10%;
    width: 80%;
    height: 1px;
    background: linear-gradient(90deg, transparent, #A6F9FF, transparent);
    z-index: 10;
}

.defaultModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.defaultModalTitle {
    flex: 1;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 1.55rem;
    font-style: normal;
    line-height: normal;
    text-align: center;
    font-weight: 600;
}

.defaultModalCrossIcon {
    width: 35px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}   

.defaultModalContent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
}   
/*conflict-resolution-will be removed later*/





/* immbilize Modal  csss */




.immobilizeModalContiner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(214.25deg, rgb(0 0 0 / 54%) 106.73%, rgb(0 0 0 / 16%) 100.28%);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}

.immobilizeModalWrapper {

    background:  #FFFFFF;
    backdrop-filter: blur(26px);
    border-radius: 10px;
    height: 428px;
    padding: 51px 70px 27px 103px;
    box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 100px 100px 0px rgba(0, 0, 0, 0.25);
}

.immobilizeModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.immobilizeModalTitle {
    flex: 1;
    color:  #000000;;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
   line-height: normal;
   margin-left: -34px;
   /* text-align: center; */
}

.immobilizeModalCrossIcon {
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 22px;
}

  

.immobilizeModalContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    margin-left: -35px;
}   

