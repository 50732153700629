.vt__vehicle-tracking-info-container {
    height: 100%;
    width: 100%;
    font-size: 12px !important;
}

.vt__vehicle-tracking-info-header {
    width: 100%;
    background-color: #E4E4E4 !important;
    padding: 5px;
}

.vt__vehicle-tracking-info-content {
    width: 100%;
    overflow-y: auto;
    max-height: 85% !important;
    padding: 5px;
}

.vt__container-fluid {
    margin-top: 0 !important;
}

.vt__content-row {
    margin-top: 5px;
}

.vt__tracking-info-header {
    color: #1F2A5D !important;
    font-weight: 700;
}

.vt__vehicle-tracking-info-content .d-flex {
    margin-top: 10px;
}

.vt__tracking-header-left {
    display: flex;
    align-items: center;
    justify-content: left;
}

.vt__tracking-header-right {
    display: flex;
    align-items: center;
    justify-content: right;
}

.vt__view-chart-button {
    min-width: 0px !important;
    text-align: center !important;
    color: #fff !important;
    background-color: #22046B !important;
    border: 1px solid #22046B !important;
    font-weight: normal !important;
    font-size: 10px !important;
    height: 30px !important;
}

.vt__tracking-info-data {
    color: #1F2A5D !important;
}