.main-text-edit {
    font: normal normal 500 16px/29px Inter !important;
    color: #1F2a5d ! important;
    margin-left: 9px !important;
}
.ra-edit-label {
    font: normal normal 500 14px/29px Inter !important;
    color: #1F2a5d ! important;
}
.edit-ra-input{
    font: normal normal 400 12px/29px Inter !important;
    color: #1F2a5d ! important;
}
.inputfields::placeholder {
    font: normal normal 400 12px/29px Inter !important;
    color: #1F2a5d ! important;
  }
  .ra__user__edit__save__btn{
    font-weight: 500 !important;
  }