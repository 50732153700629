.group-details {
    height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
}

.gd__sidebar {
    flex: 0 0 40%;
    height: 100%;
    border-radius: 20px 20px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: linear-gradient(142deg, rgba(27, 51, 81, 0.30) 19.63%, rgba(155, 97, 149, 0.30) 83.01%);
    box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    border-top-right-radius: 10px;
    overflow: hidden;
}

.gd__sidebar-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gd__sidebar-body-list {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    margin-top: 8px;
}

.gd__sidebar-body-list::-webkit-scrollbar {
    width: 8px;
}

.gd__sidebar-body-list::-webkit-scrollbar-track {
    background: #FFFFFF !important;
}

.gd__sidebar-body-list::-webkit-scrollbar-thumb {
    background: #C2C2C2 !important;
    width: 6px;
}

.gd__map-container {
    height: 100%;
    flex: 1 0 55%;
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    border-top-left-radius: 10px;
    position: relative;
    overflow: hidden;
}

.new-dashboard-map {
    height: calc(100vh - 70px);
    width: 100%;
}

.gd__sidebar-body-details {
    height: 100%;
    width: 100%;
    margin-top: 8px;
}