
.vt__map .vtm__vehicle-marker img,
.vt__map .vtm__direction-marker img,
.vt__map .vtm__destination-marker img {
    width: 100%;
    height: auto;
}

.vt__map .vtm__direction-marker img {
    transform-origin: 6px 3px;
}

.vt__map .leaflet-container {
    z-index: 0;
}
.vt__map path.leaflet-interactive {
    stroke-width: 4px;
}

.vt__map
.leaflet-control-container
.leaflet-bottom,
.leaflet-control-container
.leaflet-right {
    bottom: 0%;
}

.vt__map .vtm__vehicle-marker {
    z-index: 700 !important;
}

.vt__map-marker {
    display: inline-flex;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: #0660C6;
}

.vt__map-marker__tip {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    top: -6px;
    border-bottom-width: 6px;
    border-bottom-style: solid;
    border-bottom-color: #0660C6;
}