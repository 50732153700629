.imageHolder {
	border-width: 8px;
	border-style: solid;
	border-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
	width: 200px;
	border-radius: 50%;
	position: absolute;
	top: 25%;
	left: 4%;
	background-color: #22046B;
}

.cameraIcon {
	position: absolute;
	top: 75%;
	left: 90%;
	cursor: pointer;
	width: 30px;
}


.logout-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.6);
	height: 89vh;
	width: 94vw;
	z-index: 1000;
	position: relative;
}

.logout-modal-content {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	flex-direction: column;
	height: 40vh;
	width: 40vw;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	border-radius: 7px;
}

.logout-modal-content-text {
	font-size: 18px;
	font-weight: 600;
	color: #22046B;
}

.logout-modal-content-buttons {
	margin-top: 30px;
	width: 18vw;
	display: flex;
	justify-content: space-around;
}

.logout-modal-content-buttons-true {
	height: 40px;
	background-color: #22046B;
	width: 65px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	border-radius: 7px;
	cursor: pointer;
}

.logout-modal-content-buttons-false {
	height: 40px;
	background-color: #808080;
	width: 65px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	border-radius: 7px;
	cursor: pointer;
}

.profile-banner {
	height: 30%;
	width: 94vw;
}

.profile-banner-image-container {
	border-width: 8px;
	border-style: solid;
	border-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
	width: 200px;
	border-radius: 50%;
	position: absolute;
	top: 25%;
	left: 4%;
	background-color: #22046b;
}

.profile-banner-image-text {
	font-size: 65px;
	color: #fff;
	font-weight: 580;
}

.profile-banner-image-text-container {
	position: absolute;
	top: 63%;
	left: 7%;
}

.profile-banner-image-text-container-email {
	font-size: 17px;
	font-weight: 500;
	color: #22046B;
}

.profile-banner-image-text-container-location {
	border-left-width: 2px;
	border-left-style: solid;
	border-left-color: #000;
	padding-left: 50px;
	margin-left: 20px;
}

.profile-banner-image {
	height: 185px;
	width: 185px;
	border-radius: 50%;
}

.profile__text__color {
	color: #1f2a5d;
}

.profile-banner-image-camera-icon {
	width: 35px;
	/* position: absolute;
     top: 75%;
     left: 90%;
     cursor:pointer; */
}

.user-name {
	font-size: 16px;
	font-weight: 600;
}

/* .profile__page_text {
	font-size: 12px;
	font-weight: 500;
	color: #1f2a5d;
} */
.profile-heading{
	color: #fff !important;;
}
body {
	overflow: hidden; /* Hide body scrollbar for user profile page */
    background-color: black !important;
	  
}
/* userProfile.css */

.user-profile-container {
	padding: 20px;
	background-color:#010208;
	height: 40rem;
  }
  
  .profile-card {
	display: flex;
	align-items: flex-start;
	
	min-height: 300px; 
	margin-top: 70px;
	border-radius: 4px;
	background: rgba(56, 58, 87, 0.50);
	box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.20);
	backdrop-filter: blur(15px);
  }
  
  .card-content {
	display: flex;
	padding: 20px;
	width: 100%;
	color: #fff;
  }
  
  .profile-image-section {
	flex: 0 0 auto;
  }
  
  .profile-image-container {
	position: relative;
	width: 150px; /* Adjust image container width */
	height: 150px; /* Adjust image container height */
	border: 5px solid #868686; /* Added a white border */
	border-radius: 50%;
	background-color: #3E4351;
	top: -50px;
  }
  
  .profile-image-container .profile-image {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
  }
  
  .camera-icon {
	position: absolute;
	bottom: 0;
	right: 0;
	cursor: pointer;
	width: 30px; /* Reduced the size to 15px */
     height: 30px; /* Reduced the size to 15px */
  


  }
  
  .profile-details-section {
	margin-left: 20px; /* Add margin to separate image and details */
  }
  
  .user-name {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 10px; /* Add margin below user name */
  }
  
  .profile-email,
  .profile-role,
  .profile-location {
	font-size: 16px;
	margin-bottom: 5px; /* Add margin below each detail */
	color: #fff;
  }
  .update-password-button{
	margin-top: 15px;
	white-space: nowrap; 
	margin-right: 15px;
  }
  
  .profile-image-avatar {
	position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3.5rem;
    transform: translate(-50%, -50%);
  }