.vt-chart-title {
    font: normal normal 500 12px Inter;
    padding: 12px 4px 12px 20px;
}


.vt-chart-title-disable {
    font: normal normal 500 12px Inter;
    color: #5B5959;
    padding: 12px 4px 12px 25px;
}


.vt__chart-download-main {
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding: 12px 4px 12px 20px;
}

.vt__chart-download {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: linear-gradient(180deg, #3F3F3F, #3F3F3F, #3F3F3F) 0% 0% no-repeat padding-box;
    color: #fff !important;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer !important;
    z-index: 1500;
}

.vt__chart-export-dropdown {
    position: absolute;
    border-radius: 3px;
    padding: 10px 5px 10px 5px;
    border: none;
    width: 113px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-top: 200%;
    margin-right: 200%;
    z-index: 2000;
    background-color: #3F3F3F !important;
}

.vt__chart-export-dropdown-menu {
    width: 100%;
    font-size: 12px;
    border-radius: 3px;
    padding: 5px;
}

.vt__chart-export-dropdown-menu:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background-color: #5B5959 !important;
}

.vt__bold-chevron {
    stroke-width: 1 !important;
}

.vt_chart_download_row{
    display: flex;
    align-items: center;
}

.vt__bold-chevron-disabled {
    stroke-width: 1 !important;
    opacity: 0.5;
}