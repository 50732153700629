.modalWrapper {
    width: 100%;
    max-width: 940px;
}
.submoduleWrapper > span,
.actionTypesWrapper > span {
    color: white;
}

.editButton {
    background: transparent;
    position: absolute;
    top: -45px;
    right: -45px;
}

.submoduleRowWrapper {
    padding: 4px 8px;
    margin-bottom: 8px;
}