.otpInput {
    border: 1px solid transparent;
    border-radius: 8px;
    width: 40px !important;
    height: 40px;
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    caret-color: blue;
    margin-top: 10px;
    margin-left: 10px;
    position: relative;
    right: 9px;
    background: linear-gradient(328deg, rgb(117 9 255 / 24%) 6.73%, rgb(255 255 255 / 24%) 100.28%);
    cursor: pointer;
  }


.otpInput:hover{
    background: linear-gradient(328deg, rgb(117 9 255 / 0%) 6.73%, rgb(182 61 198 / 18%) 100.28%);
  }

.previousPage {
    color: rgba(255, 255, 255, 0.70);
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}

.previousPageLink {
  color: #00CFFD;
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
}

.previousPageLink:hover {
  text-decoration: underline;
}
/*conflict-resolution-will be removed later*/