.custom-div {
  position: absolute;
  width: 140px;
  background-color:#37368D;
  font-size: 13px;
  color: white;
  padding: 15px;
  z-index: 2000;
  margin-left: 10px;
  border-radius: 15px;
  margin-left: 43px;

}

.active-submenu {

  color: #F0F0F0 !important; 
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  margin-top:15%;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #37368D;
  transform: translateY(-50%);
  z-index: 2000;
  margin-left: 35px;
}

.popover-container{
  position: absolute;
  margin-left: 68px;
  height: 150px;
  width: 150px;
  z-index: 1500;
}

.link{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0px
}

.sidebar.close{
  width: 100px;
  margin-top: -10px;
}

.submenu-label {
  color: #8D9CC4;
  padding: 2px;
  font-size: 11px;
}

.submenu-label:hover {
  color: #FFFFFF
}

.sub-link{
  border: none;
  text-decoration: none;
}

.sub-link:hover{
  border: none;
  text-decoration: none;
}

.location-checkbox {
  background: #37368D;
}

.profile-separator{
  display: flex;
  border-top-width: 1px;
  border-top-color: #fff;
  border-top-style: solid;
  width: 80%;
  margin-left: 10%;
  position: absolute;
  bottom: 123px; 
}

.profile-avatar {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #fff;
  color: #D17FAE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-navlink {
  position: absolute;
	bottom: 60px;
	width: 100%;
	background: rgba(255, 255, 255, 0.1);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(0px);
}

.navlink-text{
  font-size: 9px;
  color: #fff;
}

.sidebar a:last-child {
  display: flex;
  border-top: 1px solid rgb(255, 255, 255);
}

@media (min-width: 1700px) and (max-width: 1900px) {
 
  .profile-navlink {
    position: absolute;
    bottom: 100px;
    width: 100%; 
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(0px);
  }
}