.title {
    color: white;
    font-weight: 400;
    font-size: 14px;
}
.count {
    color: white;
    font-weight: 300;
    font-size: 14px;
}
.unit {
    font-size: 13px;
    display: inline-block;
    margin-left: 3px;    
}
/*conflict-resolution-will be removed later*/