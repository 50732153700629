.error-message {
  color: #ff0000;
  white-space: nowrap;
  font-size: 13px;
  text-align: left;
  margin-top: 5px;
}

.heading {
  margin-top: 45px;
  height: 56px;
}

.heading h4 {
  color: #10214B;
  letter-spacing: 0px;
}

.form-container-wrapper {
  padding-left: 100px;
}

.form-container {
  padding-top: 40px;
}

.form-field {
  margin-bottom: 20px;
}

.form-label{
  color: #1f2a5d;
  height: 24px;
  white-space: nowrap;
}

.form-value {
  color: #10214B;
  height: 28px;
}

.edit {
  cursor: pointer;
  color: #1492E6;
  gap: 10px;
}

.action-button-container{
  margin-top: 30px;
  gap: 30px;
}

.approve-btn, .decline-btn{
  width: 210px;
  height: 50px;
  background-color: #1f2a5d;
  color: #FFFFFF;
  border-radius: 4px;
}

.decline-btn {
  background-color: #EFF3F8;
  color: #1f2a5d;
}
.decline-ra-modal .modal-dialog,
.edit-ra-modal .modal-dialog {
  width: 400px !important;
}
.decline-ra-modal .modal-header,
.edit-ra-modal .modal-header {
  margin-top: 20px;
}
.decline-ra-modal .modal-body,
.edit-ra-modal .modal-body {
  padding-left: 70px;
  padding-right: 70px;
}
.decline-ra-modal .modal-footer,
.edit-ra-modal .modal-footer {
  margin-bottom: 20px;
  margin-top: 30px;
}

.dropdown-content.dropdown-custom-style {
  position: absolute;
  background-color: #fff;
  min-width: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  z-index: 2;
  margin-left: -175px;
  margin-top: 13px;
}

.dropdown-content.dropdown-custom-style li {
  list-style-type: none;
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-content.dropdown-custom-style li:hover {
  background-color: #eff3f8;
}

.dropdown-content li {
  color: #10214b;
}

.form-label-group {
  display: flex;
  flex-direction: column;
}

.formLabel{
  margin-bottom: 5px;
}

.textarea-height {
  font-size: 14px !important;
  height: 60px !important;
  min-height: 60px !important;
  background: #f5f7f9 !important;
  color: var(--primary-color) !important;
  border: none !important;
  border-radius: 5px !important;
  opacity: 1 !important;
}

.expired-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.expired-container .expired-icon {
  width: 160px;
  height: auto;
}
.expired-container .expired-message {
  font-size: 24px;
  color: #10214B;
}

#decline-requisition-form select.form-control {
  height: auto !important;
  white-space: break-spaces !important;
}

#edit-location #react-mselect > div > div:first-child,
#edit-location #react-mselect > div {
  background-color: #F5F7F9 !important;
}