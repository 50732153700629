.nd__dark-vehicle-info-header-card {
    width: 100% !important;
    height: 100% !important;
    background: #111;
    font-size: 0.75em;
    color: #22046B;
}

.nd__vehicle-info-header-flex {
    display: flex;
    align-items: center;
}

.nd__dark-vehicle-info-header-registration-no {
    font-size: 16px ;
    color: #C977D6;
    font-weight: 600 ;
}

.nd__dark-vehicle-info-header-oem-model {
    font-size: 12px !important;
    color: rgba(255, 255, 255, 0.60) !important;
    background-color: transparent !important;
    padding: 2px 6px;
    border-radius: 3px;
    font-weight: 500;
    margin-left: 8px;
}

.nd__vehicle-info-header-address {
    font-size: 11px !important;
    color: #1f2a5d !important;
}

.nd__vehicle-info-header-chevron-left {
    fill: #22046B !important;
    stroke-width: 1 !important;
}

.nd_dark-vehicle-info-header-share-popover {
    width: 400px;
    background-color: #111;
    border-bottom: 1px solid #0660C642;
    box-shadow: 0px 3px 6px #0660C642;
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    margin-left: 35px;
    margin-top: -10px;
    display: none; /* Initially hidden */
    z-index: 20000 !important;
}

.nd_dark-vehicle-info-header-share-popover:before {
    content: "";
    position: absolute;
    top: 10px;
    left: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #111 transparent transparent;
    z-index: 20000 !important;
}

.nd__dark-vehicle-info-popover-header {
    font-size: 14px;
    text-align: center;
    color: #fff;
}

.nd__vehicle-info-popover-conetent > .container-fuild-hr {
    padding: 5px 12px;
}

.nd__vehicle-info-popover-conetent > .container-fuild-hr > .row-hr {
    border-bottom: 2px solid var(--bs-border-color) !important
}

.na__vehicle-info-copy {
    height: 16px;
    width: 16px;
}

.nd__dark-vehicle-info-headers-icons {
    height: 20px !important;
}



.nd__vehicle-info-headers-icons-address{
    width: 18px;
    height: auto;
}

.nd__dark-vh__share-icon {
    background-image: radial-gradient(circle,rgba(96, 46, 166, 0.97), transparent);
    padding: 8px  !important;
    border:1px solid rgba(201, 119, 214, 1) ;
    border-radius: 22px !important;
}

.ndvh__vehicle-status {
    flex: 0 0 auto;
    padding: 0 14px;
    border-radius: 1px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.ndvh__vehicle-status-text{
    font-size: 10px;
}

.na__vehicle-info-copied {
    opacity: 0.6;
}

.nd__dark-vehicle-info-header-chassis-number,
.nd__dark-vehicle-info-header-motor-number {
    font-size: 10px;
    color: #fff !important;
}

.nd__dark-vehicle-info-header-chassis-number {
    border-right: 1px solid #9E9E9E;
    margin-right: 8px;
    padding-right: 8px;
}

.nd-v-info-custom-tooltip {
    color: #FFFFFF;
    opacity: 1 !important;
    background-color: #22046b;
    font-size: 25px;
    z-index: 10;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    border-radius: 4px;
    height: 19px;
  }
  .nd__vehicle-info-arrow_back{
    margin-top: 10px;
    cursor: 'pointer'
  }
  .nd__dark-vehicle-info-line {
    height: 0px;
    border: 2px solid #3a3b3c !important;
    margin-top: 10px !important;
    border-radius: 50px;
  }
  .nd__dark-vehicle-info-icon{
    width: 30px;
    margin-left: 5px;
  }

  .nd__dark-vehicle-info-header-address{
    color:#FFFFFF ;
    font-size: 10px;
  }