
.forgot-form-container {
  
  padding-left: 38px;
  padding-right: 47px;
  margin-top: -7px;



}

.send-otp {
    font-size: 13px;
    font-weight: 550;
}

.user-icon-container {
    width: 30px;
    margin-top: -38px;
    color: var(--primary-color) !important;
  }
  .align-label {
    display: inline-block;
    width: 150px;
  }

  .confirmPassword{
    right : 35px !important;
  }
  
  .forgot-password-note {
    color: #C7C7C7;
    font-size: 18x;
    margin-top: 5px;
    margin-left: -10px;

  }
  .forgot-password-visibility-icon {
    position: absolute;
    top: 43%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }


  .password-visibility-icon{
    right: 35px !important;
  }

  .has-success {
    color: #28a745;
  }
  
  .disabledbutton {
    pointer-events: none;
    opacity: 0.4;
  }
  .chagepassword-success {
    width: 30px;
    right: 5px ;
    position: absolute;
    margin-top: -32px ;
    color: #18cb06 !important;
  }
  .password-visibility-icon-forgotpassword{
    position: absolute;
    top: 25px;
    right: 40px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .fa-eye-slash_forgetpass_eye_icon{
    color: #22046B !important;

  }

  .password-exclamation-icon-forgotpassword{
    margin-top: 12px !important;
  }

  .otp-success-icon {
    width: 30px;
    right: 0px;
    position: absolute;
    margin-top: 10px;
    color: #18cb06 !important;
  }

  .password-success-icon {
    width: 30px;
    right: 5px;
    position: absolute;
    margin-top: -33px;
    color: #18cb06 !important;
}

#password-success-icon {
  width: 30px;
  top: 1px;
  right: 5px;
  position: absolute;
  margin-top: 3px;
  color: #18cb06 !important;

}
  .chagepassword-error {
  width: 30px;
  right: 5px ;
  position: absolute;
  margin-top: -34px ;
  color: #e21212 !important;
  }

  .password-exclamation-icon {
    width: 30px;
    right: 0px !important;
    position: absolute;
    margin-top: -33px !important;
    color: #e21212 !important;
}

#password-exclamation-icon{

  width: 30px;
  right: 0px !important;
  position: absolute;
  margin-top: 4px !important;
  color: #e21212 !important;
}
  /* CSS file or style block */
  .text-green {
  color: #18cb06 !important; 
  }
  
  .text-red {
  color: #e21212 !important;
  }
  

  .forgot-submit-container{
    margin-top: 33px !important;
    margin-left: -13px !important;
  }