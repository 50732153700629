.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;

   
}

.profile-page-otp-success-icon{
  position: absolute;
  left: 400px;
}

.profile-card-body{
  box-shadow: none !important;
}

.card {
    border: none !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 9px #0000002B;
    border-radius: 4px;
    opacity: 1;
    width: 500px;
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.send-otp {
    background-color: transparent;
    border: none;
    color: #d1108c;
    cursor: pointer;
    font-size: 14px;
  }

.profile-valid-icon {
    margin-top: 7px;
}

.profile-error-msg{
  margin-left:145px;
}
  
  .close-button {
    border: none;
    background: none;
    color: #1f2a5d;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto;
  }

  .profile-otp-timer-div{
    margin-top:32px;
    font-size: 13px;
  }
  .ra-bulk-upload-btn{
    font: normal normal 400 14px/27px Inter !important;
    padding: 0px 4px !important;
    height: 45px !important;
    width: 202px !important;
    border-radius: 4px !important;
    background-color:#22046B !important;
  }
  .ra-add-btn{
    font: normal normal 400 14px/27px Inter !important;
    padding: 0px 4px !important;
    height: 45px !important;
    border-radius: 4px !important;
    background-color:#22046B !important;
  }
  /* .ra-dwn-btn{
    color: #ffffff;
    padding: 0px 13px;
    border-radius: 4px;
    font: normal normal 400 14px/27px Inter !important;
    height: 45px;
    border: unset;
    background-color: #22046B !important;
  } */
 