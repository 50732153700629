.dark-dashboard-main-container .custom-div-dark {
    position: absolute;
    width: 175px;
    border-radius: 14px;
    /* background: linear-gradient(76deg, rgb(30 105 119) 12.63%, rgb(169 92 204) 103.01%); */
    /* background: linear-gradient(76deg, rgb(46 152 227 / 41%) 26.63%, rgb(173 87 213 / 68%) 60.01%, #314aa55c); */
    background: linear-gradient(65deg, rgb(16 85 171 / 50%) 19.63%, rgb(226 69 210 / 34%) 83.01%);
    box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    font-size: 13px;
    color: rgba(166, 249, 255, 1);
    padding: 15px;
    z-index: 2000;
    margin-left: 10px;
    border-radius: 15px;
    margin-left: 18px;
    /* border: 2px solid transparent; */
    margin-top: -15px;
}

.dark-dashboard-main-container .active-submenu {

    color: #F0F0F0 !important;
}

.dark-dashboard-main-container .arrow-dark {
    position: absolute;
    width: 0;
    height: 0;
    margin-top: 15%;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid rgb(48 83 191 / 48%);
    transform: translateY(-50%);
    z-index: 2000;
    margin-left: 9px;
}

.dark-dashboard-main-container .popover-container {
    position: absolute;
    margin-left: 68px;
    height: 150px;
    width: 150px;
    z-index: 1500;
}

.dark-dashboard-main-container .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0px
}

.dark-dashboard-main-container .sidebar.close {
    width: 100px;
    margin-top: -10px;
}

.dark-dashboard-main-container .submenu-dark-label {
    color: #FFFFFF;
    padding: 15px !important;
    font-size: 11px;
    width: 140px;
    background-image: url("/public/images/subMenuPopup/List.png") !important;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;


}

.dark-dashboard-main-container .submenu-dark-label:hover {
    color: #39FFFF;
    background-image: url("/public/images/subMenuPopup/ListActive.png") !important;
}

.dark-dashboard-main-container .sub-link {
    border: none;
    text-decoration: none;
}

.dark-dashboard-main-container .sub-link:hover {
    border: none;
    text-decoration: none;
}

.dark-dashboard-main-container .location-checkbox {
    background: #37368D;
}

.dark-dashboard-main-container .profile-separator {
    display: flex;
    border-top-width: 1px;
    border-top-color: #fff;
    border-top-style: solid;
    width: 80%;
    margin-left: 10%;
    position: absolute;
    bottom: 123px;
}

.dark-dashboard-main-container .profile-avatar {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #fff;
    color: #D17FAE;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark-dashboard-main-container .profile-navlink {
    position: absolute;
    bottom: 60px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(0px);
}

.dark-dashboard-main-container .navlink-text {
    font-size: 9px;
    color: #fff;
}

.dark-dashboard-main-container .sidebar a:last-child {
    display: flex;
    border-top: 1px solid rgb(255, 255, 255);
}



@media (min-width: 1700px) and (max-width: 1900px) {

    .dark-dashboard-main-container .profile-navlink {
        position: absolute;
        bottom: 100px;
        width: 100%;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(0px);
    }
}