.searchWrapper {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 8px 4px;
    border-radius: 5px;
    flex-grow: 1;
    margin-left: 0px;
    border: 2px solid #92a1e5;
    flex-grow: 0;
    border-radius: 8px;
    max-width: 100%;
    position: relative;
}
.searchIcon {
    margin-right: 5px;
    color: #555;
    margin: 0px 10px;
}
.searchInput {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    font-size: 0.875rem;
}

.searchResultWrapper {
    position: absolute;
    z-index: 0;
    width: calc(100% - 8px);
    background-color: white;
    left: 6px;
    top: calc(100% + 0px);
    box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.5);
    border-radius: 0 0 6px 6px;
    border: 1px solid #cccccc;
}
.searchResultWrapper.noAddress {
    border: unset;
    box-shadow: none;
}
.searchResult {
    font-size: 0.75rem;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    transition: all 0.3s linear;
    cursor: pointer;
}
.searchResult:last-of-type {
    border-bottom: 0;
}
.searchResult:hover {
    background-color: #f1f1f1;
}
.searchResult.active {
    background-color: #e1e1e1;
}