.container {
    padding: 7px 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.cardCreateTemplate {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    border: 2px dashed #3E3F65;
    background: linear-gradient(127deg, rgba(53, 65, 128, 0.74) 28.26%, rgba(28, 34, 63, 0.50) 91.2%);
    backdrop-filter: blur(60px);
    padding-top: 50px;
    padding-bottom: 50px;

    max-width: 720px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.cardCreateTemplate::before {
    display: none;
}
.cardCreateTemplate img {
    max-width: 120px;
    height: auto;
}
.cardCreateTemplate p {
    color: #E3E8F3;
    font-size: 1rem;
    font-weight: 700;
}