#uncontrolled-tab-example-tab-pending_sign_up {
    margin-left: 40px;
}
.mian-container-user-management{
    padding: 10px;
}
#uncontrolled-tab-example-tab-pending_sign_up , #uncontrolled-tab-example-tab-ra_user{
    top: -1.2px;
  }
  .ra-user .nav-link {
	position: relative !important;
	color: #9CAACF ! important;
    font: normal normal 500 16px/29px Inter !important;
    padding-right: 0px !important;

}

.ra-user .nav-link.active {
	color: #1F2a5d ! important;
    font: normal normal 500 16px/29px Inter !important;
    padding-right: 0px !important;
}