/* Initial state with only the top border */


.new-dark-dashboard .nd__dark-vehicle-detail-card,
.group-listing-dark .nd__dark-vehicle-detail-card {
  background: linear-gradient(90deg, rgba(17, 16, 24, 0.00) 0.28%, rgba(17, 16, 24, 0.51) 129.42%);
  background-blend-mode: overlay;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 20px;
  width: 98%;
  height: max-content;
  margin: 0 0 15px;
  padding: 20px 15px;
  cursor: pointer;
}

.new-dark-dashboard .nd__dark-vehicle-detail-card-half-border,
.group-listing-dark .nd__dark-vehicle-detail-card-half-border {
  position: relative;
  overflow: hidden;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent; /* Make sure this is configured to show when needed */
  border-left: 1px solid transparent;
  transition: border 0.5s ease;
}

/* This is for the top half-border */
.new-dark-dashboard .nd__dark-vehicle-detail-card-half-border::before,
.group-listing-dark .nd__dark-vehicle-detail-card-half-border::before {
  content: '';
  position: absolute;
  top: 0; /* Aligns to the top */
  left: 10%;
  width: 80%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #A6F9FF, transparent);
  z-index: 10;
}

/* This is for the bottom half-border, ensuring visibility transition */
.new-dark-dashboard .nd__dark-vehicle-detail-card-half-border::after,
.group-listing-dark .nd__dark-vehicle-detail-card-half-border::after {
  content: '';
  position: absolute;
  bottom: 0; /* Aligns to the bottom */
  left: 10%;
  width: 80%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #A6F9FF, transparent); /* Adjust the background to match the top border */
  z-index: 10;
  transition: background 0.5s ease; /* Adjust the transition to match your needs */
}





/* Adjusted keyframes for segmented clockwise border animation */
@keyframes segmentedClockwiseBorder {
  0%, 12.5% {
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
  }
  25% {
    border-top: 1px solid #A6F9FF;
    border-right: 1px solid #A6F9FF;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
  }
  37.5%, 50% {
    border-top: 1px solid transparent;
    border-right: 1px solid #A6F9FF;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
  }
  62.5%, 75% {
    border-top: 1px solid transparent;
    border-right: 1px solid #A6F9FF;
    border-bottom: 1px solid #A6F9FF;
    border-left: 1px solid transparent;
  }
  87.5%, 100% {
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #A6F9FF;
    border-left: 1px solid transparent;
  }
}

@keyframes reverseSegmentedClockwiseBorder {
  0%, 12.5% {
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #A6F9FF;
    border-left: 1px solid transparent;
  }
  25% {
    border-top: 1px solid transparent;
    border-right: 1px solid #A6F9FF;
    border-bottom: 1px solid #A6F9FF;
    border-left: 1px solid transparent;
  }
  37.5%, 50% {
    border-top: 1px solid transparent;
    border-right: 1px solid #A6F9FF;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
  }
  62.5%, 75% {
    border-top: 1px solid #A6F9FF;
    border-right: 1px solid #A6F9FF;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
  }
  87.5%, 100% {
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
  }
}





/* .hover-animation {
  animation: segmentedClockwiseBorder 2s linear forwards;
}

.reverse-animation {
  animation: reverseSegmentedClockwiseBorder 2s linear forwards;
} */

.new-dark-dashboard .nd-vc-row-1,
.group-listing-dark .nd-vc-row-1 {
  display: flex;
  align-items: center;
}

.new-dark-dashboard .nd-vc-row-2,
.group-listing-dark .nd-vc-row-2 {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.new-dark-dashboard .nd-vc-title,
.group-listing-dark .nd-vc-title {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  min-width: 140px;
}

.new-dark-dashboard .nd-static-vehicle-icon,
.group-listing-dark .nd-static-vehicle-icon {
  height: auto;
  width: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.new-dark-dashboard .nd-vc-icon-col,
.group-listing-dark .nd-vc-icon-col {
  flex: 0 0 auto;
  display: flex;
  position: relative;
}

.new-dark-dashboard .nd-vc-oem-div,
.group-listing-dark .nd-vc-oem-div {
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  margin-right: 8px;
}

.new-dark-dashboard .nd__dark-vc-regi-no,
.group-listing-dark .nd__dark-vc-regi-no {
  text-align: left;
  font: normal normal 600 18px Inter;
  color: #8FA0FB;
  margin-bottom: 8px;
  line-height: 1.1;
}

.new-dark-dashboard .nd__dark-vc-oem,
.group-listing-dark .nd__dark-vc-oem {
  text-align: left;
  font: normal normal 500 12px Inter;
  color: rgba(255, 255, 255, 0.60);
  margin-bottom: 0;
  margin-top: 0;
}

.new-dark-dashboard .nd__dark-vc-line,
.group-listing-dark .nd__dark-vc-line {
  height: 0px;
  width: 100%;
  border: 1px solid #878a8d !important;
  margin-top: 8px;
  margin-bottom: 16px;
  border-radius: 10px;
}

.new-dark-dashboard .nd__dark-vc-gmarker,
.group-listing-dark .nd__dark-vc-gmarker {
  height: auto;
  width: 17px;
}

.new-dark-dashboard .nd__dark-vc-address,
.group-listing-dark .nd__dark-vc-address {
  text-align: left;
  font: normal normal 500 12px Inter;
  color: #ffff;
  margin-left: 8px;
  margin-right: 8px;
  flex: 1 1 auto;
  margin-bottom: 0;
}

.new-dark-dashboard .nd__dark-vc-soc,
.group-listing-dark .nd__dark-vc-soc {
  position: relative;
  text-align: left;
  font: normal normal 500 12px Inter;
  color: #8FA0FB ;
  width: max-content;
}

.new-dark-dashboard .nd-vc-status,
.group-listing-dark .nd-vc-status {
  height: auto;
  flex: 0 0 auto;
  color: #FFFFFF;
  font: normal normal 500 12px Inter;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  min-width: 95px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 2px;
}
.new-dark-dashboard .nd-vc-status p,
.group-listing-dark .nd-vc-status p {
  margin-bottom: 0;
}

/* .new-dark-dashboard .nd-vc-battery-perc-in,
  group-listing-darkard .nd-vc-battery-perc-in {
  border: 0.2em dotted transparent !important;
  height: 2.2em;
  width: 2.2em !important;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 0.39em;
  top: 0.35em;
  font-size: 0.3em;
} */

.new-dark-dashboard .nd-vc-battery,
.group-listing-dark .nd-vc-battery {
  flex: 0 0 auto;
}

.new-dark-dashboard .nd-fav-flag,
.group-listing-dark .nd-fav-flag {
  padding: 2% 5% 0% 5%;
}

/* battery related */

.new-dark-dashboard .pie,
.new-dark-dashboard .c100 .bar,
.new-dark-dashboard .c100.p51 .fill,
.new-dark-dashboard .c100.p52 .fill,
.new-dark-dashboard .c100.p53 .fill,
.new-dark-dashboard .c100.p54 .fill,
.new-dark-dashboard .c100.p55 .fill,
.new-dark-dashboard .c100.p56 .fill,
.new-dark-dashboard .c100.p57 .fill,
.new-dark-dashboard .c100.p58 .fill,
.new-dark-dashboard .c100.p59 .fill,
.new-dark-dashboard .c100.p60 .fill,
.new-dark-dashboard .c100.p61 .fill,
.new-dark-dashboard .c100.p62 .fill,
.new-dark-dashboard .c100.p63 .fill,
.new-dark-dashboard .c100.p64 .fill,
.new-dark-dashboard .c100.p65 .fill,
.new-dark-dashboard .c100.p66 .fill,
.new-dark-dashboard .c100.p67 .fill,
.new-dark-dashboard .c100.p68 .fill,
.new-dark-dashboard .c100.p69 .fill,
.new-dark-dashboard .c100.p70 .fill,
.new-dark-dashboard .c100.p71 .fill,
.new-dark-dashboard .c100.p72 .fill,
.new-dark-dashboard .c100.p73 .fill,
.new-dark-dashboard .c100.p74 .fill,
.new-dark-dashboard .c100.p75 .fill,
.new-dark-dashboard .c100.p76 .fill,
.new-dark-dashboard .c100.p77 .fill,
.new-dark-dashboard .c100.p78 .fill,
.new-dark-dashboard .c100.p79 .fill,
.new-dark-dashboard .c100.p80 .fill,
.new-dark-dashboard .c100.p81 .fill,
.new-dark-dashboard .c100.p82 .fill,
.new-dark-dashboard .c100.p83 .fill,
.new-dark-dashboard .c100.p84 .fill,
.new-dark-dashboard .c100.p85 .fill,
.new-dark-dashboard .c100.p86 .fill,
.new-dark-dashboard .c100.p87 .fill,
.new-dark-dashboard .c100.p88 .fill,
.new-dark-dashboard .c100.p89 .fill,
.new-dark-dashboard .c100.p90 .fill,
.new-dark-dashboard .c100.p91 .fill,
.new-dark-dashboard .c100.p92 .fill,
.new-dark-dashboard .c100.p93 .fill,
.new-dark-dashboard .c100.p94 .fill,
.new-dark-dashboard .c100.p95 .fill,
.new-dark-dashboard .c100.p96 .fill,
.new-dark-dashboard .c100.p97 .fill,
.new-dark-dashboard .c100.p98 .fill,
.new-dark-dashboard .c100.p99 .fill,
.new-dark-dashboard .c100.p100 .fill,
.group-listing-dark .pie,
.group-listing-dark .c100 .bar,
.group-listing-dark .c100.p51 .fill,
.group-listing-dark .c100.p52 .fill,
.group-listing-dark .c100.p53 .fill,
.group-listing-dark .c100.p54 .fill,
.group-listing-dark .c100.p55 .fill,
.group-listing-dark .c100.p56 .fill,
.group-listing-dark .c100.p57 .fill,
.group-listing-dark .c100.p58 .fill,
.group-listing-dark .c100.p59 .fill,
.group-listing-dark .c100.p60 .fill,
.group-listing-dark .c100.p61 .fill,
.group-listing-dark .c100.p62 .fill,
.group-listing-dark .c100.p63 .fill,
.group-listing-dark .c100.p64 .fill,
.group-listing-dark .c100.p65 .fill,
.group-listing-dark .c100.p66 .fill,
.group-listing-dark .c100.p67 .fill,
.group-listing-dark .c100.p68 .fill,
.group-listing-dark .c100.p69 .fill,
.group-listing-dark .c100.p70 .fill,
.group-listing-dark .c100.p71 .fill,
.group-listing-dark .c100.p72 .fill,
.group-listing-dark .c100.p73 .fill,
.group-listing-dark .c100.p74 .fill,
.group-listing-dark .c100.p75 .fill,
.group-listing-dark .c100.p76 .fill,
.group-listing-dark .c100.p77 .fill,
.group-listing-dark .c100.p78 .fill,
.group-listing-dark .c100.p79 .fill,
.group-listing-dark .c100.p80 .fill,
.group-listing-dark .c100.p81 .fill,
.group-listing-dark .c100.p82 .fill,
.group-listing-dark .c100.p83 .fill,
.group-listing-dark .c100.p84 .fill,
.group-listing-dark .c100.p85 .fill,
.group-listing-dark .c100.p86 .fill,
.group-listing-dark .c100.p87 .fill,
.group-listing-dark .c100.p88 .fill,
.group-listing-dark .c100.p89 .fill,
.group-listing-dark .c100.p90 .fill,
.group-listing-dark .c100.p91 .fill,
.group-listing-dark .c100.p92 .fill,
.group-listing-dark .c100.p93 .fill,
.group-listing-dark .c100.p94 .fill,
.group-listing-dark .c100.p95 .fill,
.group-listing-dark .c100.p96 .fill,
.group-listing-dark .c100.p97 .fill,
.group-listing-dark .c100.p98 .fill,
.group-listing-dark .c100.p99 .fill,
.group-listing-dark .c100.p100 .fill {
  position: absolute;
  border: 0.09em solid #000000;
  width: 0.82em;
  height: 0.82em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.new-dark-dashboard .pie-fill,
.new-dark-dashboard .c100.p51 .bar:after,
.new-dark-dashboard .c100.p51 .fill,
.new-dark-dashboard .c100.p52 .bar:after,
.new-dark-dashboard .c100.p52 .fill,
.new-dark-dashboard .c100.p53 .bar:after,
.new-dark-dashboard .c100.p53 .fill,
.new-dark-dashboard .c100.p54 .bar:after,
.new-dark-dashboard .c100.p54 .fill,
.new-dark-dashboard .c100.p55 .bar:after,
.new-dark-dashboard .c100.p55 .fill,
.new-dark-dashboard .c100.p56 .bar:after,
.new-dark-dashboard .c100.p56 .fill,
.new-dark-dashboard .c100.p57 .bar:after,
.new-dark-dashboard .c100.p57 .fill,
.new-dark-dashboard .c100.p58 .bar:after,
.new-dark-dashboard .c100.p58 .fill,
.new-dark-dashboard .c100.p59 .bar:after,
.new-dark-dashboard .c100.p59 .fill,
.new-dark-dashboard .c100.p60 .bar:after,
.new-dark-dashboard .c100.p60 .fill,
.new-dark-dashboard .c100.p61 .bar:after,
.new-dark-dashboard .c100.p61 .fill,
.new-dark-dashboard .c100.p62 .bar:after,
.new-dark-dashboard .c100.p62 .fill,
.new-dark-dashboard .c100.p63 .bar:after,
.new-dark-dashboard .c100.p63 .fill,
.new-dark-dashboard .c100.p64 .bar:after,
.new-dark-dashboard .c100.p64 .fill,
.new-dark-dashboard .c100.p65 .bar:after,
.new-dark-dashboard .c100.p65 .fill,
.new-dark-dashboard .c100.p66 .bar:after,
.new-dark-dashboard .c100.p66 .fill,
.new-dark-dashboard .c100.p67 .bar:after,
.new-dark-dashboard .c100.p67 .fill,
.new-dark-dashboard .c100.p68 .bar:after,
.new-dark-dashboard .c100.p68 .fill,
.new-dark-dashboard .c100.p69 .bar:after,
.new-dark-dashboard .c100.p69 .fill,
.new-dark-dashboard .c100.p70 .bar:after,
.new-dark-dashboard .c100.p70 .fill,
.new-dark-dashboard .c100.p71 .bar:after,
.new-dark-dashboard .c100.p71 .fill,
.new-dark-dashboard .c100.p72 .bar:after,
.new-dark-dashboard .c100.p72 .fill,
.new-dark-dashboard .c100.p73 .bar:after,
.new-dark-dashboard .c100.p73 .fill,
.new-dark-dashboard .c100.p74 .bar:after,
.new-dark-dashboard .c100.p74 .fill,
.new-dark-dashboard .c100.p75 .bar:after,
.new-dark-dashboard .c100.p75 .fill,
.new-dark-dashboard .c100.p76 .bar:after,
.new-dark-dashboard .c100.p76 .fill,
.new-dark-dashboard .c100.p77 .bar:after,
.new-dark-dashboard .c100.p77 .fill,
.new-dark-dashboard .c100.p78 .bar:after,
.new-dark-dashboard .c100.p78 .fill,
.new-dark-dashboard .c100.p79 .bar:after,
.new-dark-dashboard .c100.p79 .fill,
.new-dark-dashboard .c100.p80 .bar:after,
.new-dark-dashboard .c100.p80 .fill,
.new-dark-dashboard .c100.p81 .bar:after,
.new-dark-dashboard .c100.p81 .fill,
.new-dark-dashboard .c100.p82 .bar:after,
.new-dark-dashboard .c100.p82 .fill,
.new-dark-dashboard .c100.p83 .bar:after,
.new-dark-dashboard .c100.p83 .fill,
.new-dark-dashboard .c100.p84 .bar:after,
.new-dark-dashboard .c100.p84 .fill,
.new-dark-dashboard .c100.p85 .bar:after,
.new-dark-dashboard .c100.p85 .fill,
.new-dark-dashboard .c100.p86 .bar:after,
.new-dark-dashboard .c100.p86 .fill,
.new-dark-dashboard .c100.p87 .bar:after,
.new-dark-dashboard .c100.p87 .fill,
.new-dark-dashboard .c100.p88 .bar:after,
.new-dark-dashboard .c100.p88 .fill,
.new-dark-dashboard .c100.p89 .bar:after,
.new-dark-dashboard .c100.p89 .fill,
.new-dark-dashboard .c100.p90 .bar:after,
.new-dark-dashboard .c100.p90 .fill,
.new-dark-dashboard .c100.p91 .bar:after,
.new-dark-dashboard .c100.p91 .fill,
.new-dark-dashboard .c100.p92 .bar:after,
.new-dark-dashboard .c100.p92 .fill,
.new-dark-dashboard .c100.p93 .bar:after,
.new-dark-dashboard .c100.p93 .fill,
.new-dark-dashboard .c100.p94 .bar:after,
.new-dark-dashboard .c100.p94 .fill,
.new-dark-dashboard .c100.p95 .bar:after,
.new-dark-dashboard .c100.p95 .fill,
.new-dark-dashboard .c100.p96 .bar:after,
.new-dark-dashboard .c100.p96 .fill,
.new-dark-dashboard .c100.p97 .bar:after,
.new-dark-dashboard .c100.p97 .fill,
.new-dark-dashboard .c100.p98 .bar:after,
.new-dark-dashboard .c100.p98 .fill,
.new-dark-dashboard .c100.p99 .bar:after,
.new-dark-dashboard .c100.p99 .fill,
.new-dark-dashboard .c100.p100 .bar:after,
.new-dark-dashboard .c100.p100 .fill,
.group-listing-dark .pie-fill,
.group-listing-dark .c100.p51 .bar:after,
.group-listing-dark .c100.p51 .fill,
.group-listing-dark .c100.p52 .bar:after,
.group-listing-dark .c100.p52 .fill,
.group-listing-dark .c100.p53 .bar:after,
.group-listing-dark .c100.p53 .fill,
.group-listing-dark .c100.p54 .bar:after,
.group-listing-dark .c100.p54 .fill,
.group-listing-dark .c100.p55 .bar:after,
.group-listing-dark .c100.p55 .fill,
.group-listing-dark .c100.p56 .bar:after,
.group-listing-dark .c100.p56 .fill,
.group-listing-dark .c100.p57 .bar:after,
.group-listing-dark .c100.p57 .fill,
.group-listing-dark .c100.p58 .bar:after,
.group-listing-dark .c100.p58 .fill,
.group-listing-dark .c100.p59 .bar:after,
.group-listing-dark .c100.p59 .fill,
.group-listing-dark .c100.p60 .bar:after,
.group-listing-dark .c100.p60 .fill,
.group-listing-dark .c100.p61 .bar:after,
.group-listing-dark .c100.p61 .fill,
.group-listing-dark .c100.p62 .bar:after,
.group-listing-dark .c100.p62 .fill,
.group-listing-dark .c100.p63 .bar:after,
.group-listing-dark .c100.p63 .fill,
.group-listing-dark .c100.p64 .bar:after,
.group-listing-dark .c100.p64 .fill,
.group-listing-dark .c100.p65 .bar:after,
.group-listing-dark .c100.p65 .fill,
.group-listing-dark .c100.p66 .bar:after,
.group-listing-dark .c100.p66 .fill,
.group-listing-dark .c100.p67 .bar:after,
.group-listing-dark .c100.p67 .fill,
.group-listing-dark .c100.p68 .bar:after,
.group-listing-dark .c100.p68 .fill,
.group-listing-dark .c100.p69 .bar:after,
.group-listing-dark .c100.p69 .fill,
.group-listing-dark .c100.p70 .bar:after,
.group-listing-dark .c100.p70 .fill,
.group-listing-dark .c100.p71 .bar:after,
.group-listing-dark .c100.p71 .fill,
.group-listing-dark .c100.p72 .bar:after,
.group-listing-dark .c100.p72 .fill,
.group-listing-dark .c100.p73 .bar:after,
.group-listing-dark .c100.p73 .fill,
.group-listing-dark .c100.p74 .bar:after,
.group-listing-dark .c100.p74 .fill,
.group-listing-dark .c100.p75 .bar:after,
.group-listing-dark .c100.p75 .fill,
.group-listing-dark .c100.p76 .bar:after,
.group-listing-dark .c100.p76 .fill,
.group-listing-dark .c100.p77 .bar:after,
.group-listing-dark .c100.p77 .fill,
.group-listing-dark .c100.p78 .bar:after,
.group-listing-dark .c100.p78 .fill,
.group-listing-dark .c100.p79 .bar:after,
.group-listing-dark .c100.p79 .fill,
.group-listing-dark .c100.p80 .bar:after,
.group-listing-dark .c100.p80 .fill,
.group-listing-dark .c100.p81 .bar:after,
.group-listing-dark .c100.p81 .fill,
.group-listing-dark .c100.p82 .bar:after,
.group-listing-dark .c100.p82 .fill,
.group-listing-dark .c100.p83 .bar:after,
.group-listing-dark .c100.p83 .fill,
.group-listing-dark .c100.p84 .bar:after,
.group-listing-dark .c100.p84 .fill,
.group-listing-dark .c100.p85 .bar:after,
.group-listing-dark .c100.p85 .fill,
.group-listing-dark .c100.p86 .bar:after,
.group-listing-dark .c100.p86 .fill,
.group-listing-dark .c100.p87 .bar:after,
.group-listing-dark .c100.p87 .fill,
.group-listing-dark .c100.p88 .bar:after,
.group-listing-dark .c100.p88 .fill,
.group-listing-dark .c100.p89 .bar:after,
.group-listing-dark .c100.p89 .fill,
.group-listing-dark .c100.p90 .bar:after,
.group-listing-dark .c100.p90 .fill,
.group-listing-dark .c100.p91 .bar:after,
.group-listing-dark .c100.p91 .fill,
.group-listing-dark .c100.p92 .bar:after,
.group-listing-dark .c100.p92 .fill,
.group-listing-dark .c100.p93 .bar:after,
.group-listing-dark .c100.p93 .fill,
.group-listing-dark .c100.p94 .bar:after,
.group-listing-dark .c100.p94 .fill,
.group-listing-dark .c100.p95 .bar:after,
.group-listing-dark .c100.p95 .fill,
.group-listing-dark .c100.p96 .bar:after,
.group-listing-dark .c100.p96 .fill,
.group-listing-dark .c100.p97 .bar:after,
.group-listing-dark .c100.p97 .fill,
.group-listing-dark .c100.p98 .bar:after,
.group-listing-dark .c100.p98 .fill,
.group-listing-dark .c100.p99 .bar:after,
.group-listing-dark .c100.p99 .fill,
.group-listing-dark .c100.p100 .bar:after,
.group-listing-dark .c100.p100 .fill  {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.new-dark-dashboard .c100,
.group-listing-dark .c100 {
  position: relative;
  font-size: 56px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  /* background: linear-gradient(90deg, rgba(27, 51, 81, 100) 40.63%, rgba(155, 97, 149, 100) 100%, rgba(155, 97, 149, 100) 90.01%); */
  background: linear-gradient(90deg, rgba(17, 16, 24, 0.00) 0.28%, rgba(17, 16, 24, 0.51) 129.42%);
  margin: 10% 0% 0% 25%;
  margin-bottom: 10px;
}


.new-dark-dashboard .c100 *,
.new-dark-dashboard .c100 *:before,
.new-dark-dashboard .c100 *:after,
.group-listing-dark .c100 *,
.group-listing-dark .c100 *:before,
.group-listing-dark .c100 *:after  {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.new-dark-dashboard .c100>span,
.group-listing-dark .c100>span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 12px;
  color: #3c4761;
  display: block;
  text-align: center;
  white-space: nowrap;
}

.new-dark-dashboard .c100:after,
.group-listing-dark .c100:after {
  position: absolute;
  top: 0.09em;
  left: 0.09em;
  display: block;
  content: " ";
  border-radius: 50%;
  /* background: linear-gradient(140deg, rgba(27, 51, 81, 100) 40.63%, rgba(155, 97, 149, 100) 100%, rgba(155, 97, 149, 100) 90.01%); */
  background: linear-gradient(90deg, rgba(17, 16, 24, 0.00) 0.28%, rgba(17, 16, 24, 0.51) 129.42%);
  width: 0.82em;
  height: 0.82em;
}

.new-dark-dashboard .c100:hover,
.group-listing-dark .c100:hover {
  cursor: default;
}

/* GREEN 50-100*/
.new-dark-dashboard .c100.green .bar,
.new-dark-dashboard .c100.green .fill,
.group-listing-dark .c100.green .bar,
.group-listing-dark .c100.green .fill {
  border-color: #15c7a8 !important;
}


/* ORANGE 20-49*/
.new-dark-dashboard .c100.orange .bar,
.new-dark-dashboard .c100.orange .fill,
.group-listing-dark .c100.orange .bar,
.group-listing-dark .c100.orange .fill {
  border-color: #eb7d4b !important;
}


/* YELLOW 5-19*/
.c100.yellow .bar,
.c100.yellow .fill {
  border-color: #ffca28 !important;
}


/* RED 0-4*/
.new-dark-dashboard .c100.red .bar,
.new-dark-dashboard .c100.red .fill,
.group-listing-dark .c100.red .bar,
.group-listing-dark .c100.red .fill {
  border-color: #DE54E2 !important;
}

.new-dark-dashboard .c100.soc-live .bar,
.new-dark-dashboard .c100.soc-live .fill,
.new-dark-dashboard .c100.soc-live .nd-vc-battery-perc-in,
.group-listing-dark .c100.soc-live .bar,
.group-listing-dark .c100.soc-live .fill,
.group-listing-dark .c100.soc-live .nd-vc-battery-perc-in {
  border-color: #15c7a8 !important;
}

.new-dark-dashboard .c100.soc-idle .bar,
.new-dark-dashboard .c100.soc-idle .fill,
.new-dark-dashboard .c100.soc-idle .nd-vc-battery-perc-in,
.group-listing-dark .c100.soc-idle .bar,
.group-listing-dark .c100.soc-idle .fill,
.group-listing-dark .c100.soc-idle .nd-vc-battery-perc-in {
  border-color: #F29900 !important;
}

.new-dark-dashboard .c100.soc-parked .bar,
.new-dark-dashboard .c100.soc-parked .fill,
.new-dark-dashboard .c100.soc-parked .nd-vc-battery-perc-in,
.group-listing-dark .c100.soc-parked .bar,
.group-listing-dark .c100.soc-parked .fill,
.group-listing-dark .c100.soc-parked .nd-vc-battery-perc-in {
  border-color: #E06000 !important;
}

.new-dark-dashboard .c100.soc-immobile .bar,
.new-dark-dashboard .c100.soc-immobile .fill,
.new-dark-dashboard .c100.soc-immobile .nd-vc-battery-perc-in,
.group-listing-dark .c100.soc-immobile .bar,
.group-listing-dark .c100.soc-immobile .fill,
.group-listing-dark .c100.soc-immobile .nd-vc-battery-perc-in {
  border-color: #E06000 !important;
}

.new-dark-dashboard .c100.soc-charging .bar,
.new-dark-dashboard .c100.soc-charging .fill,
.new-dark-dashboard .c100.soc-charging .nd-vc-battery-perc-in,
.group-listing-dark .c100.soc-charging .bar,
.group-listing-dark .c100.soc-charging .fill,
.group-listing-dark .c100.soc-charging .nd-vc-battery-perc-in  {
  border-color: #5491F5 !important;
}

.new-dark-dashboard .c100.soc-non-communication .bar,
.new-dark-dashboard .c100.soc-non-communication .fill,
.new-dark-dashboard .c100.soc-non-communication .nd-vc-battery-perc-in,
.group-listing-dark .c100.soc-non-communication .bar,
.group-listing-dark .c100.soc-non-communication .fill,
.group-listing-dark .c100.soc-non-communication .nd-vc-battery-perc-in  {
  border-color: #7D7D7D !important;
}

/* .new-dark-dashboard .nd-custom-tooltip {
  color: #FFFFFF;
  opacity: 1 !important;
  background-color: #505288 !important;
  font-size: 25px;
  z-index: 10;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  border-radius: 4px;
  height: 19px;
} */
.new-dark-dashboard .nd-vc-click,
.group-listing-dark .nd-vc-click{
  color: #00CEE9;
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
margin-left: 10px;
}