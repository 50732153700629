.donut-chart-box-charging-report{
    display: flex;
    justify-content: space-around;
    text-align: center !important;
}
.charging-analytics-box-shadow-donut{
    box-shadow: 0px 3px 6px #00000029 !important;
    padding: 10px;
}
.chart-item-charging-report{
font: normal normal 500 17px/14px Inter !important;
color: #1F2A5D !important;
}
.charging-chart-report-main-row{
    margin: 7px;
    display: flex;
    justify-content: center;
}
.titele-charging-report-dount{
font: normal normal 500 14px/11px Inter !important;
color: #22046B !important;
}