.gf__map {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.markerClusterGroup {
    background-image: url("/public/images/svgicon/GeofenceTabicon/fenceClusterBackground.svg") !important ;
    background-color: transparent;
    background-size: cover;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.markerClusterGroup span {
    font-size: 0.75rem;
    font-weight: 800;
    color: white;
}

.mapControls {
    position: absolute;
    right: 16px;
    z-index: 400;
    top: 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.mapControls .controlsGroup {
    display: flex;
    flex-direction: column;
}

.mapControls .controlsGroup button:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.mapControls .controlsGroup button:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.mapControls button {
    background-color: white;
    padding: 6px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}

.mapControls button img {
    width: 20px;
    height: auto;
}
