.hr-line-blue-cr-width {
    width: 175px;
    max-width: 175px;
  }


  .selectLayout{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 20px;
  }

 .no-value-selected .css-13cymwt-control,
 .no-value-selected .css-t3ipsp-control,
 .no-value-selected .css-1gr2brf,
 .no-value-selected .css-msi959-ValueContainer {
    background-color: #F5F7F9 !important;
    color: #1F2a5d !important;
}

.charging__log__date-picker .no-date-selected,
.charging__log__date-picker .no-date-selected .rs-btn-default {
 background-color: #F5F7F9 !important;
}

.chargeselectLayout{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 20px;
}

.container-fluid .chargeLogsFilter {
  margin-top: 10px;
}

.export-btn-box{
  margin-left: 60px;
}