.selectAllLabel {
  font-size: 13px;
  color: #fff;
  margin-bottom: 0px;
}
.filterActiveBg {
  position: relative;
  left: -62px;
  top: -108px;
}
.filterSelectedCircle {
  position: relative;
  left: -147.2px;
  top: -107px;
  height: 53px;
}

.activeBarSelected {
  position: relative;
  left: 35.5px;
  top: -108px;
}

.filterTabCount {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 22px;
  width: 22px;
  padding: 4px 0px;
  border-radius: 50%;
  background-color: #242644 !important;
  color: #242644 !important;
}

.filterTabCountSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 22px;
  width: 22px;
  padding: 4px 0px;
  border-radius: 50%;
  background-color: rgb(23, 21, 43) !important;
  color: rgb(23, 21, 43) !important;
}


.filterTabCountActive {
  background-color: #c977d6 !important;
  color: #fff !important;
}

.filterTabTitle {
  font-size: 14px !important;
  margin-left: 8px !important;
}
.activeGroupSection {
  display: flex;
  align-items: center;
}
.ndLocationContainer {
  position: relative;
  z-index: 1;
  height: 100%;
}

.filterTitle {
  background: #242644;
  width: 254px;
  border: 1px solid #2e6989;
  border-radius: 7px;
  height: 73vh;
}
.removeIcon {
  cursor: pointer;
  color: white;
  height: 18px;
  width: 18px;
}
.filterCountStyle {
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  background-color: #f00;
}

.countSelectBox{
  display: flex;
  align-items: center;
}
.countDashed{
  color: #fff;
  line-height: 1;
  margin-right: 5px;
}

@media screen and (max-height: 800px) {
  .filterTitle {
    height: 73vh;
  }
}
