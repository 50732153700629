.dashboard-vehicle-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dvs__vehicle-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0;
}

.dashboard-vehicle-status .all-vehicle {
    border-right: 1px solid #BFD0FA;
}

.dashboard-vehicle-status .ignitionOff-vehicle {
    border-right: 1px solid #BFD0FA;
}

.dashboard-vehicle-status .nonCommunication-vehicle {
    border-right: 1px solid #BFD0FA;
    border-left: 1px solid #BFD0FA;
}

.dashboard-vehicle-status .favourite-vehicle {
    border-right: 1px solid #BFD0FA;
}

.dashboard-vehicle-status .download-report {
    border-right: 1px solid #BFD0FA;
    height: 90px;
}

.dvs__vehicle-container p {
    margin: 0;
    padding-top: 2px;
    font-size: 0.75rem;
    color: #10214B;
}

.dvs__vehicle-container .dvs__vehicle-line-ignitionOn {
    height: 3px;
    width: 20px;
    border-radius: 5px;
    background-color: #1B8F45;
}

.dvs__vehicle-container .dvs__vehicle-line-ignitionOff {
    height: 3px;
    width: 20px;
    border-radius: 5px;
    background-color: #0660C6;
}

.dvs__vehicle-container .dvs__vehicle-line-live {
    height: 3px;
    width: 20px;
    border-radius: 5px;
    background-color: #009B88;
}

.dvs__vehicle-container .dvs__vehicle-line-idle {
    height: 3px;
    width: 20px;
    border-radius: 5px;
    background-color: #E06000;
}

.dvs__vehicle-container .dvs__vehicle-line-parking {
    height: 3px;
    width: 20px;
    border-radius: 5px;
    background-color: #632008;
}

.dvs__vehicle-container .dvs__vehicle-line-charging {
    height: 3px;
    width: 20px;
    border-radius: 5px;
    background-color: #22046B;
}

.dvs__vehicle-container .dvs__vehicle-line-nonCommunication {
    height: 3px;
    width: 20px;
    border-radius: 5px;
    background-color: #B31F85;
}