.grp__checkbox-accent-color {
    accent-color: #22046B !important;
    cursor: pointer;
    margin-right: 5px;
}


.groupsAccordion .accordion-button:focus{
    border-color: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    background-color: white;
}
.groupsAccordion .accordion-button:not(.collapsed) {
    border-color: transparent;
    box-shadow: none;
    background-color: white;
}
.oemAndModelDiv {
    display:flex;
    width:150px;
    align-items:center;
    height:30px
}

.create-btn.disabledbutton {
    background-color: #808080 !important;
    pointer-events: none; 
    opacity: 1; 
}

.cityDiv {
    display:flex;
    align-items:center;
    height:30px
}

.groupsOemDiv {
    margin-right: 5px;
}
.groupsCityDiv {
    margin-right: 5px;
}