.nd__dark-dashboard-vehicle-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1111;
    margin-left: 185px;

}

.nd__dark-dashboard-vehicle-status > button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    margin: 0;
}

.nd__dark-dashboard-vehicle-status .all-vehicle {
    border-right: 1px dashed #9747FF;
    line-height: 10px;
    padding-right: 25px;
}

.nd__dark-dashboard-vehicle-status .live-vehicle {
    padding-left: 20px;
    padding-right: 20px;
}

.nd__dark-dashboard-vehicle-status .parking-vehicle {
    padding-left: 5px;
    padding-right: 10px;
}
.nd__dark-dashboard-vehicle-status .poorNetwork-vehicle {
    padding-left: 10px;
    padding-right: 10px;
}

.nd__dark-dashboard-vehicle-status .pluggedOut-vehicle {
    padding-left: 10px;
    padding-right: 10px;
}
.nd__dark-dashboard-vehicle-status .deviceFault-vehicle {
    padding-left: 10px;
    padding-right: 10px;
}
.nd__dark-dashboard-vehicle-status .zeroData-vehicle {
    padding-left: 10px;
    padding-right: 10px;
}

.nd__dark-dashboard-vehicle-status .charging-vehicle {
    border-right: 1px dashed #9747FF;
    padding-left: 10px;
    padding-right: 20px;
}


.nd__dark-dashboard-vehicle-status .nonCommunication-vehicle {
    border-right: 1px dashed #9747FF;
    border-left: 0;
    padding-right: 25px;
    padding-left: 25px;
}

.nd__dark-dashboard-vehicle-status .inactiveGps-vehicle {
    border-right: 1px dashed #9747FF;
    border-left: 0;
    padding-right: 10px;
    padding-left: 10px;
}

.nd__dark-dashboard-vehicle-status .favourite-vehicle {
    border-right: 1px dashed #9747FF;
    padding-right: 15px;
    padding-left: 15px;
}

.nd__dark-dashboard-vehicle-status .notification {
    padding-left: 15px;
    padding-right: 10px;
}

.nd__dark-dashboard-vehicle-status .topbar-download {
    border-right: 1px dashed #9747FF;
    padding-right: 15px;
    padding-left: 10px;
}

.dark-notification-count{
  color:#FFFFFF;
  font-size: 0.75rem;
}

.nd__dark-dashboard-vehicle-status .notification,
.nd__dark-dashboard-vehicle-status .topbar-download {
    background: transparent;
    align-self: stretch;
}

.nd__dark-dashboard-vehicle-status .profile {
    align-self: stretch;
    width: 40px;
    height: 40px;
    background: linear-gradient(137.22deg, #70B4E5 10.24%, #C417E0 90.99%);
    color: #FFFFFF;
    border-radius: 50%;
    border: none;
    text-transform: uppercase;
    font-size: 0.875rem;
    margin-left: 25px;
    margin-right: 29px;
}

.nd__dark-dashboard-vehicle-status .dvs__vehicle-container p {
    margin: 0;
    /* padding-top: 2px; */
    font-size: 0.75rem;
    color: #FFFFFF;
}

.dark-dropdown-content.dropdown-custom-style {
    position: absolute;
    background: linear-gradient(142deg, rgb(27 51 81) 19.63%, rgb(155 97 149) 83.01%);
    min-width: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 8px;
    z-index: 2;
    margin-left: -165px;
    margin-top: 19px;
    width: 200px;
    height: 180px;
  }

  .dark-dropdown-content.dropdown-custom-style.non-comm-style {
    margin-left: -420px;
    margin-top: 42px;
  }
  
  .dark-dropdown-content.dropdown-custom-style li {
    list-style-type: none;
    padding: 12px 0px;
    margin-bottom: 14px;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
  }
  
  .dark-dropdown-content.dropdown-custom-style li {
    background-image: url("/public/images/subMenuPopup/List.png") !important;
    background-repeat: no-repeat;
  }
  .dark-dropdown-content.dropdown-custom-style li:hover {
   color: #A6F9FF;
   background-image: url("/public/images/subMenuPopup/ListActive.png") !important;

  }
  
  .dark-dropdown-content li {
    color: #fff;
  }

  .nd__dark-custom-tooltip-arrow-dark-profile {
    position: absolute;
    width: 0;
    height: 0;
    top: -10px;
    right: 39px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #505288;
    border-top: 10px solid transparent;
    transform: translateY(-50%);
    z-index: 2000;
}

  .nd__dark-custom-tooltip-arrow-dark-non-comm {
    position: absolute;
    width: 0;
    height: 0;
    top: -10px;
    right: 89px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #505288;
    border-top: 10px solid transparent;
    transform: translateY(-50%);
    z-index: 2000;
}
.nd_dark-non-com-value{
    color:#fff;
    margin-left:20px;
    font-weight:600;
}

.listItemStyle {
    display:flex;
    width:155px;
    justify-content:space-between;
    align-items:center;
    margin-left: 10px;
}