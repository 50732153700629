.hr-line-blue-tr-width {
    width: 110px;
    max-width: 110px;
  }
  
  .selectLayout{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  #trip-table .download-btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    }
    #trip-table th {
    vertical-align: top;
    padding-bottom: 0 !important;
    padding-top: 19px !important;
    }

.analytics-container .trip-analytics-main-container{
  min-height: 500px !important;
}
.trip__log__date-picker .no-date-selected,
.trip__log__date-picker .no-date-selected .rs-btn-default {
 background-color: #F5F7F9 !important;
}
.trip__log__table{
  box-shadow: 0px 3px 6px #00000029 !important;
}
.records__trip{
  margin-bottom: 10px;
}

.records__trip .tripLogsFilter {
  margin-top: 10px;
}