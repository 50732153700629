.container {
    width: 100%;
    height: 100px;
    padding: 5px 20px;
    border-radius: 20px;
    background: linear-gradient(127deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%);
    backdrop-filter: blur(60px);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.title {
    font-size: 14px;
    color: white;
    font-weight: 700;
}
.count {
    font-size: 18px;
    color: white;
    font-weight: 700;
}

.units {
    font-size: 14px;
    color: white;
    font-weight: 500;
}
/*conflict-resolution-will be removed later*/