.trip-analytics-box-shadow {
    /* box-shadow: 0px 3px 6px #00000029 !important; */
    padding: 0px 17px 0px 17px;
}

.trip-analytics-main-container-main{
    box-shadow: 0px 3px 6px #00000029;
    margin-left: 0px;
    overflow: auto;
    max-height: 63vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px 0px 20px 0px;
  }
  @media (min-width: 1300px) and (max-width: 1542px) {
    .trip-analytics-main-container-main {
      max-height: Calc(70vh - 0px);
      overflow: auto;
      overflow-x: hidden;
    }
  }
  @media (min-width: 1700px) and (max-width: 3000px) {
    .trip-analytics-main-container-main {
      max-height: Calc(76vh - 0px);
      overflow: auto;
      overflow-x: hidden;
    }
  }

  .trip-analytics-main-container-main::-webkit-scrollbar {
    width: 11px !important;
}

.trip-analytics-main-container-main::-webkit-scrollbar-track {
    background: #FFFFFF !important;
}

.trip-analytics-main-container-main::-webkit-scrollbar-thumb {
    background: #C2C2C2 !important;
    border: 3px solid transparent !important;
    border-radius: 6px !important;
    background-clip: content-box !important;
}

.alert-nav-link .nav-link#uncontrolled-tab-example-tab-add_alert {
  padding-right: 0px !important;
  margin-left: 50px !important;
}
