.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
    cursor: pointer;

    flex: 1 0 20%;
    max-width: calc(20% - 12px);
}

.containerActive::before {
    background: #181931;
}

.heading {
    text-align: center;
    color: #39FFFF;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0;
}

.moduleList {
    display: flex;
    flex-wrap: wrap;
}

.moduleListItem {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}

.moduleListItem > span {
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 400;
}

.popoverIcon {
    background-color: transparent;
    height: 20px;
    width: 20px;
    display: inline-block;
}
.popoverIcon img {
    object-fit: cover;
    width: 100%;
}

.popoverModules {
    display: flex;
    flex-wrap: wrap;
}
.popoverModules > p {
    color: #E3E8F3;
    font-size: 0.785rem;
    font-weight: 400;
    flex: 0 0 100%;
    margin: 0;
}