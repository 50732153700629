.searchContainerGeofence {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    flex-grow: 1;
    margin-left: 0px;
    border: 2px solid #92a1e5;
    flex-grow: 0;
    height: 40px;
    border-radius: 11px;
    max-width: 210px;
}

.vobSearchIcon {
    margin-right: 5px;
    color: #555;
    margin: 0px 12px;
}

.vobSearchInput {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
}

.groupDataTable th {
    background: #22046B !important;
    color: #fff !important;
    padding: 10px !important;
}

.AddVehicleButton {
    background: #22046b;
    height: 36px;
    font-family: Poppins !important;
    color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    padding: 10px 12px;
}


/* manually vechile assign  */
.filterHeaderBox {
    display: flex;
    gap: 30px;
    margin: 30px 0px;
}

.manualVehicleTable {
    border: 1px solid #ebebeb;
}

.AssignVehicltableList td {
    padding: 10px;

}

.checkedBox {
    accent-color: #22046B;
}

.checkedBoxColor {
    accent-color: white;
}

.checkedBoxColor:checked {
    background-color: #22046B;
    border-color: #22046B;
}

.actionButtonRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding: 16px 0;
}
.editButtonRow {
    display: flex;
    align-items: center;
    justify-content: center !important;
    gap: 16px;
    padding: 16px 0;
    margin-top: 60px;
}

.actionButton {
    border: unset;
    flex: 0 0 auto;
    color: #ffffff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.875rem;
    background-color: #22046b;
    display: inline-flex;
    gap: 6px;
    align-items: center;
}

.actionIconButton {
    border: unset;
    color: #ffffff;
    padding: 4px;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #22046b;
}

/* groups styles */
.editGroupRoot {
    padding: 16px 0;
}

.editGroupList {
    background-color: #EDF2FF;
    padding: 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
}

.editGroupChip {
    flex: 0 0 auto;
    display: inline-flex;
    align-items: baseline;
    gap: 8px;
    background-color: white;
    padding: 3px 9px 4px;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.35s linear;
}

.editGroupChip:hover {
    background-color: #f9f9f9;
}

.editGroupChip span {
    color: #1F2A5D;
    font-weight: bold;
    font-size: 1rem;
}

/* default view styles */
.assignVehicleBox{
    text-align: left;
    color: #1F2A5D;
    padding: 16px 0;
  }
  .assignVehicleBox p{
    font-size: 16px;
    font-family: Poppins !important;
    font-weight: 600;
  }
  .assignVehicleSelect{
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .assignVehicleRadio{
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .assignVehicleRadio span{
    font-size: 14px;
    font-family: Poppins !important;
    font-weight: 400;
  }
  .assignVehicleSaveButton{
    width: 79px;
    height: 36px;
    font-family: Poppins !important;
    font-size: 12px;
    color: #ffffff;
    border-radius: 5px;
    position: relative;
    margin: 30px;
  }

  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
  }
  .modalSaveButton {
    border: unset;
    flex: 0 0 auto;
    color: #ffffff;
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #22046b;
  }

  .selectGroupRadio {
    width: 16px;
    height: 16px;
    accent-color: #22046B;
  }
  
  .selectGroupRadio:checked {
    background-color: #22046B; 
  }