@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import 'rsuite/dist/rsuite.css';

html {
  height: 100%;
}

* {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
}

td {
  font-size: 12px !important;
  color: #1f2a5d !important;
}

th {
  font-size: 14px !important;
  text-align: center !important;
  color: #1f2a5d !important;
}

thead {
  height: 45px;
}

body {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #1e295d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff !important;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

/* header css implementation start fron here */

header {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  /* border-radius: 0px 0px 0px 31px; */
  opacity: 1;
  height: 70px;
  width: 100vw;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header-left-container {
  display: flex;
  align-items: center;
  justify-content: left;
}

.header-right-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-avtar {
  border: 2px solid #1f2a5d;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-icon {
  color: #1f2a5d;
}

.ham-menu {
  color: #1f2a5d;
}

.avatar-text {
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  color: #1f2a5d;
}

/* Sidebar Css Implementation starts from here */
/* Below style is added for the toaster component placement */
.dashboard-main-container + div {
  inset: 0px !important;
}

.dashboard-main-container {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.sidebar {
  top: 70px;
  left: 0;
  padding-top: 30px;
  position: fixed !important;
  background: linear-gradient(180deg, #22046b, #37368D, #D17FAE) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  /* border-radius: 0px 30px 0px 0px; */
  opacity: 1;
  color: #fff;
  height: 95vh;
  width: 240px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sidebar.close {
  width: 62px;
}

.container-fluid {
  margin-top: 10px
}

.signup-container,
.login-container,
.forgot-password-container {
  margin-top: 0px !important
}

.main-section {
  left: 250px;
  position: relative;
  background: transparent;
  height: 85vh;
  width: calc(100% - 250px);
  transition: all 0.5s ease;
  padding-left: 0px;
  border-radius: 18px;
}

.sidebar.close~.main-section {
  left: 100px;
  width: calc(100% - 100px);
}

.main-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  font-size: 30px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 20px !important;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  text-decoration: none !important;
  gap: 15px;
  transition: all 0.5s;
}

.link:hover {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #ffffff;
  transition: all 0.5s;
}

.link:active {
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #ffffff;
}

.link_text {
  text-decoration: none;
}

.icon,
.link_text {
  font-size: 15px;
  text-decoration: none;
}

/* unauthorise input fields css */

.inputfield {
  background: #E7ECF7 !important;
  color: #797694 !important;
  font-weight: 400 !important;
  font-size: 14px;
  border: none !important;
  height: 48px !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  padding-right: 25px !important;
}

.no-value-selected-input .search-input{
  background: #F5F7F9 !important;
}

.search-input{
  background: #E7ECF7 !important;
}


.no-value-selected-input .inputfield{
  background: #F5F7F9 !important;
}

.inputfield:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

/* label css  */
.form-label {
  color: #1f2a5d !important;
  font-size: 14px !important;
  width: 50% !important;
}

label {
  display: inline !important;
  color: #1f2a5d !important;
  font-size: 14px !important;
}

.form-control {
  font-size: 14px !important;
}

/* dropdown content css for profile */

.dropdown-content.dropdown-custom-style {
  position: absolute;
  background-color: #fff;
  min-width: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  z-index: 2;
  margin-left: -175px;
  margin-top: 13px;
}

.dropdown-content.dropdown-custom-style li {
  list-style-type: none;
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-content.dropdown-custom-style li:hover {
  background-color: #eff3f8;
}

.dropdown-content li {
  color: #10214b;
}

/* Button css */

.btn {
  height: 35px !important;
  width: auto !important;
  min-width: 150px;
  text-align: center !important;
  color: #10214b !important;
  background-color: #ffffff !important;
  border: 1px solid #10214b !important;
  font-weight: 600 !important;
}

.btn-forgot-submit {
  height: 35px !important;
  width: auto !important;
  min-width: 150px;
  text-align: center !important;
  color: #10214b !important;
  background-color: #ffffff !important;
  border: 1px solid #10214b !important;
}

.btn-primary {
  color: #ffffff !important;
  background-color: #22046B !important;
  border: none !important;
}

.signup-btn {
  height: 48px !important;
  width: 48% !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.login-btn {
  height: 48px !important;
  width: 52% !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-left: 10px;
}

.create-signup-btn {
  height: 48px !important;
  width: 110% !important;
  font-size: 14px !important;
  font-weight: 400 !important;

}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background-color: #000000d4 !important;
  color: var(--text-white-color) !important;
  font-weight: 200 !important;
  font-size: 16px !important;
}


.forgot-pass-btn {
  height: 48px !important;
  width: 50% !important;
  
}

.login-heading {
  color: #10214b !important;
  font-size: 32px !important;
  margin-left: 21px !important;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.popup.open {
  opacity: 1;
  visibility: visible;
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup-content {
  position: relative;
  background-color: #fff;
  padding: 50px;
  border-radius: 4px;
  width: 500px;
  height: 60vh;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.submenu {
  position: absolute;
  z-index: 9999;
  top: 100%;
  left: 0;
  background-color: #1f1258;
  padding: 10px;
  width: 10rem;
  list-style: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submenu-open {
  background-color: #fff;
  left: 67px;
}

.submenu-open li a {
  color: #1f1258;
}

/* Date picker css starts here */
.rs-picker-toggle .rs-btn .rs-btn-default {
  background-color: #e7ecf7 !important;
}

.rs-picker-toggle-textbox {
  background-color: #e7ecf7 !important;
  color: #1f2a5d !important;
}

.rs-picker-toggle .rs-picker-toggle-active .rs-btn .rs-btn-default {
  background-color: #e7ecf7 !important;
}

.rs-picker-input {
  background-color: #e7ecf7 !important;
}

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background-color: #e7ecf7 !important;
}

.rs-picker-toggle-value {
  color: #1F2a5d !important;
  font-weight: 500 !important;
}

.rs-picker-default .rs-picker-toggle {
  border: unset !important;
}

.rs-picker-toggle:hover {
  border-color: #e7ecf7 !important;
}

.rs-picker-toggle:active {
  border-color: #e7ecf7 !important;
  box-shadow: none !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  border-color: #e7ecf7 !important;
  box-shadow: none !important;
}

/* css for single calendar */
/* .rs-picker-toolbar {
  max-width: 330px !important;
}

.rs-picker-daterange-content {
  width: 330px;
} */

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #22046B !important;
}

.rs-btn-link {
  color: #000000 !important;
}

.rs-btn-primary {
  background-color: #22046B !important;
}

.rs-calendar-table-header-cell-content {
  color: #000000 !important;
  font-weight: 500 !important;
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: #1f2a5d !important;
}

.rs-picker-toolbar-ranges {
  flex-wrap: nowrap !important;
  gap: 0px !important;
}

.date-picker-rs {
  border-color: #e7ecf7 !important;
  background-color: #e7ecf7 !important;
  height: 45px;
  padding: 6px 0px;
  border-radius: 4px;
}

.rs-calendar-header-time-toolbar {
  float: left;
}

.rs-btn-xs {
  font-size: 14px;
}

.rs-btn-subtle {
  color: #1f2a5d;
}

/* Date picker css ends here */

.hr-line {
  border: 1.5px solid #e7ecf7;
  opacity: 1;
  margin: 0;
  width: 90%;
  max-width: 90%;
}

.hr-line-blue {
  border: 1.5px solid #1f2a5d;
  opacity: 1;
  margin: 0;
  width: 165px;
  max-width: 165px;
}

h5 {
  color: #1f2a5d !important;
}

.dwn-btn {
  color: #ffffff;
  padding: 0px 12px;
  border-radius: 4px;
  font-size: 0px;
  height: 44px;
  border: unset;
  background-color: #22046b;
}

.dwn-btn-disabled {
  background-color: #808080;
  height: 44px;
}

.bttn {
  background-color: #1f2a5d;
  color: #ffffff;
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 0px 4px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
}

.bttn,
.hw {
  height: 45px !important;
  width: 150px !important;
}

/* Multi-select css starts here*/
.css-t3ipsp-control {
  border-color: #e7ecf7 !important;
  background-color: #e7ecf7 !important;
  box-shadow: unset !important;
  height: 45px;
}

.css-13cymwt-control {
  border-color: #e7ecf7 !important;
  background-color: #e7ecf7 !important;
  height: 45px;
}

.css-qbdosj-Input {
  position: absolute !important;
  z-index: 10 !important;
  caret-color: transparent !important;
}

.css-19bb58m {
  position: absolute !important;
  caret-color: transparent !important;
}

.multi-select-width {
  width: 350px;
  font-size: 14px;
  height: 45px !important;
}

.multi-select-menu-buttons {
  height: 30px;
  font-size: 14px !important;
  font-weight: normal;
  background-color: #1f2a5d;
  color: #ffffff;
  border: unset;
  padding: 0px 6px;
  border-radius: 4px;
}

.menu-ok-btn {
  height: 30px;
  width: 50px;
}

.select-option-div:hover .css-4l539y-option {
  background-color: #e7ecf7;
}

/* Multi-select css ends here*/

.row-btn {
  background-color: unset;
  border: unset;
}

.row-i-icon {
  color: #1f2a5d;
  height: 11px;
  width: 12px;
}

/* Tooltip css starts here*/
.nd-dark-tooltip-inner .tooltip-inner {
  color: #fff !important;
  width: fit-content !important;
  background-color: #505288 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.tooltip-inner {
    background-color: #22046B !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    max-width: 214px !important;
    width: 214px;
}

.user_activity_table td {
  padding: 10px;
}

.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #22046B !important;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #22046B !important;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #22046B !important;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #22046B !important;
}

.nd-dark-overlay-placement-bottom-start .tooltip-arrow::before {
  border-bottom-color: #505288 !important;
}

.nd-dark-overlay-placement-top-start .tooltip-arrow::before {
  border-top-color: #505288 !important;
}

/* Tooltip css ends here*/

.box-shadow-danger {
  box-shadow: 0 0 0 0.25rem rgb(252, 130, 130);
  border-radius: 4px;
}

/* Tabs css starts here */

.nav {
  margin-top: -3px !important;
}

.nav-tabs {
  border: unset !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: unset !important;
}

.nav-link {
  border: unset !important;
  padding-left: 0px !important;
  padding-right: 60px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #1f2a5d !important;
  padding-top: 0px !important;
  padding-bottom: 6px !important
}

/* Tabs css ends here */

.search-bar-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  background-color: #e7ecf7;
  border-radius: 4px;
  height: 45px;
  min-width: 320px;
  width: 100%;
}

.fw500 {
  font-weight: 500 !important;

}

.auth-page-top-heading {
  margin-top: 68px !important;
  margin-left: 20px !important;
}

.navlink__active{
  background: rgba(255, 255, 255, 0.20);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    border-radius: 0px;
    /* border: 1px solid rgba(255, 255, 255, 0.18); */
    color: #ffffff;
}

/* 
changing a hover color to magenta  */

a:hover, a:focus {
  color: #d1108c !important;
  color: var(--rs-text-link-hover);
  text-decoration: none;
}


.login_eye_icon {
  color: #22046B ;
}



.date__picker svg {
  fill: #22046B;
}




.date__picker .no-date-selected,
.date__picker .no-date-selected .rs-btn-default {
background-color: #F5F7F9 !important;

}




@media (min-width: 1250px) {


  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    background-color: #000000d4 !important;
    color: var(--text-white-color) !important;
    font-weight: 200 !important;
    font-size: 14px !important;
  }
}

@media (min-width: 1500px) {

  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    background-color: #808080 !important;
    color: var(--text-white-color) !important;
    font-weight: 200 !important;
    font-size: 14px !important;
    opacity: 1 !important;
  }

  .auth-page-top-heading-sign-up {
    margin-top: 60px !important;
    margin-left: -28px !important;
  }

  .register-form {
    margin-left: 29px !important;
  }

  .register-form .row {
    margin: 15px auto !important;
  }

  .auth-page-top-heading {
    margin-top: 76px !important;
    margin-left: 5px !important;
  }

  .auth-page-top-heading-forgot-password {
    margin-top: 51px !important;
  }

  .login-heading {
    color: #1f2a5d !important;
    font-size: 26px !important;
    margin-left: 21px !important;
  }

					
.forgot-heading {
  color: #1f2a5d !important;
  font-size: 26px !important;
  font-weight: 600 !important;
  margin-left: -12px !important;
}

  .auth-page-top-heading-create {
    margin-top: 76px !important;
    margin-left: 26px !important;
    margin-bottom: 33px !important;
  }
  .forgot-pass-btn {
    height: 48px !important;
    width: 47% !important;
    font-size: 14px !important;
  }


.bell-icon-text{
  color: red;
  position: relative;
  bottom: -16px;
  left: 12px;
  font-weight: 500;
  font-size: small;
  height: 1px;
}


}

.login-background-image {
  height: 57% !important;
  width: 60%;
  /* margin: auto; */
}

.login-logo-image {
  height: 20% !important;
  width: 390px;
  /* margin-top: 10%; */
}

.material-switch > input[type='checkbox'] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  top: 2px;
  width: 35px;
}

.material-switch > label::before {
  background: #808080;
  border-radius: 8px;
  content: '';
  height: 17px;
  margin-top: -8px;
  position: absolute;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  width: 35px;
}

.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  content: '';
  height: 13px;
  left: 3px;
  margin-top: -6px;
  position: absolute;
  top: 0px;
  transition: all 0.3s ease-in-out;
  width: 13px;
}

.material-switch > input[type='checkbox']:checked + label::before {
  background: #3498db;
  opacity: 1;
}

.material-switch > input[type='checkbox']:checked + label::after {
  background: #ecf0f1;
  left: 20px;
}

#form-check-input:checked {
  background-color: #1f2a5d !important;
}

.form-check-input:checked[type=radio] {
  border-color: #1f2a5d;
  background-color: #1f2a5d;    
  
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%2322046b%27/%3e%3c/svg%3e") ;

  color: #fff;
}
.form-check-input:checked{

  background-color: #fff !important;    
}
.form-check-input:focus{
  box-shadow: none !important;
}
.form-check-input {
  margin-right: 3px;
}
.tab-content {
  margin-top: 30px;
}

.drawer-container-geofence {
  box-shadow: 10px;
}

.search-bar-div-container-geofence {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  background-color: #e7ecf7;
  border-radius: 4px;
  height: 45px;
  margin-left: 10px;
}

.geofence-dropdown-content.dropdown-custom-style-geofence {
  margin-left: 165px;
  margin-top: 54px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 3px 6px #0000004D;
  border-radius: 4px;
  padding: 23px 15px 15px 15px;
  z-index: 2;
  width: 171px;
}

@media (min-width: 1300px) and (max-width: 1542px) {
  .geofence-dropdown-content.dropdown-custom-style-geofence {
    margin-left: 200px;
    margin-top: 54px;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 3px 6px #0000004D;
    border-radius: 4px;
    padding: 23px 15px 15px 15px;
    z-index: 2;
    width: 220px;
  }
}

@media (min-width: 1700px) and (max-width: 3000px) {
  .geofence-dropdown-content.dropdown-custom-style-geofence {
    margin-left: 297px;
    margin-top: 54px;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 3px 6px #0000004D;
    border-radius: 4px;
    padding: 23px 15px 15px 15px;
    z-index: 2;
    width: 250px;
  }
}

.accordion-button:not(.collapsed) {
  padding: 7px 7px 7px 10px;
}

.custom-header-geofence {
  font: normal normal medium 14px/21px Inter !important;
  background: #eff3f8 0% 0% no-repeat padding-box !important;
}

.acc {
  --bs-accordion-border-color: none !important;
  --bs-accordion-btn-padding-x: none !important;
  /* --bs-accordion-btn-padding-y: 15px !important; */
  /* font-size: 10px !important; */
  --bs-accordion-active-bg: none !important;
}

.accordion-button {
  font-size: 14px !important;
  /* background: #EFF3F8 0% 0% no-repeat padding-box; */
  padding: 7 !important;
  color: #1f2a5d !important;
}

.accordion-body {
  padding-top: 10px !important;
  padding-left: 5px !important;
  padding-bottom: 0px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  height: auto;
  overflow: auto;
  max-height: 30vh;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-body::-webkit-scrollbar {
  width: 6px;
  /* Adjust this value to change the width */
}

.accordion-collapse {
  margin-top: 5px;
}

#form-check-input:checked {
  background-color: #1f2a5d !important;
}

.form-check-input:checked[type=radio] {
  border-color: #1f2a5d;
  /* Change the border color when checked */
  background-color: #1f2a5d;    
  
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%2322046b%27/%3e%3c/svg%3e") ;

  /* Change the background color when checked */
  color: #fff;
  /* Change the text color when checked */
}
.form-check-input:checked{

  background-color: #fff !important;    
}
.form-check-input:focus{
  box-shadow: none !important;
}
.form-check-input {
  margin-right: 3px;
}

.text-style-accordian {
  font: normal normal normal 13px/21px Inter !important;
  letter-spacing: 0px;
  margin-left: 25px;
  color: #1f2a5d;
  margin-bottom: 5px;
}

.overflow-geofence {
  overflow-y: auto;
  overflow-x: hidden !important;
  margin-top: 5px;
  max-height: 70vh;
  height: auto;
}

@media (min-width: 1300px) and (max-width: 1542px) {
  .overflow-geofence {
    overflow-y: auto;
    overflow-x: hidden !important;
    margin-top: 5px;
    max-height: 70vh;
    height: auto;
  }
}

@media (min-width: 1700px) and (max-width: 3000px) {
  .overflow-geofence {
    overflow-y: auto;
    overflow-x: hidden !important;
    margin-top: 5px;
    max-height: 70vh;
    height: auto;
  }
}

#terms-checkbox-geofence {
  background-color: #fff !important;
}

#terms-checkbox-geofence:checked {
  background-color: #1f2a5d !important;
}

.geofnece-dashboard-row {
  width: 100%;
}

.geofnece-container {
  height: 88vh;
}

.geofance_box {
  margin-top: -23px;
  margin-left: 4px;
}

.map-container-geofence {
  height: 100vh;
  width: 100%;
}

/* @media (min-width: 1300px) and (max-width: 1542px){
  .map-container-geofence {
    width: 102.5%;
    height: Calc(90.5vh - 0px);
  }
}
@media (min-width: 1700px) and (max-width: 3000px){
  .map-container-geofence {
    height: Calc(92.7vh - 0px);
    width: 100%;
  }
} */
.reset-map-view-button-geofence-dashboard {
  position: fixed;
  bottom: 35%;
  font-size: 15px;
  z-index: 1500;
  cursor: pointer;
  font-weight: 400;
  right: 10px;
  background-color: #22046b;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: white;
}

.filter-icon {
  background: #22046B 0% 0% no-repeat padding-box;
}

.dsm-open-fs {
  position: fixed;
  bottom: 4%;
  font-size: 35px;
  z-index: 1500;
  cursor: pointer;
  font-weight: 800;
  right: 20px;
  color: black;
  background: white;
  padding: 6px;
}

.dsm-close-fs {
  position: fixed;
  z-index: 1500;
  cursor: pointer;
  font-weight: 800;
  bottom: 50px;
  font-size: 38px;
  right: 18px;
}

.main-title-dashboard {
  font: normal normal 600 18px/36px Inter;
  letter-spacing: 0px;
  color: #1F2A5D;
}

.dropdown-custom-style-geofence .accordion-button::after {
  background-image: url("/public/images/svgicon/arrow-down.png") !important ;
}

.dropdown-custom-style-geofence .accordion-button::before {
  background-image: url("/public/images/svgicon/arrow-up.png") !important ;
}
.geofance__alert__tooltip {
  font-size:10px;
}

.geo__alert-tab-container > .nav-tabs .nav-link.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  top: 27px;
  background-color: #10214B;
}