.card {
    border-radius: 20px;
    background: linear-gradient(127deg, rgba(53, 65, 128, 0.74) 28.26%, rgba(28, 34, 63, 0.50) 91.2%);
    backdrop-filter: blur(60px);
    padding: 20px 15px;
    color: white;
    height: 100%;
    width: 100%;
}

.cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.cardHeader .cardHeading {
    color: white;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    flex: 1 0 auto;
}
.cardHeader .cardAction {
    flex: 0 0 auto;
}

.cardBody {
}
.cardBody .table {
    border-radius: 20px;
    background: linear-gradient(127deg, #060C29 28.26%, rgba(4, 12, 48, 0.50) 91.2%);
    backdrop-filter: blur(60px);
    border: none;
}

.table .tableHead {}
.table .tableBody {}

.tableHead .tableHeadRow {}
.tableHead th.tableHeadCell {
    background-color: transparent !important;
    color: white !important;
    border: none;
    font-weight: 700 !important;
    font-size: 16px !important;
}

.tableBody .tableBodyRow {}
.tableBody td.tableBodyCell {
    background-color: transparent;
    vertical-align: bottom;
    padding-top: 15px;
    padding-bottom: 15px;
}

.vehicleWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5px;
}
.vehicleWrapper .vehicleImage {
    max-width: 100%;
    flex: 0 0 44px;
    max-width: 44px;
    height: auto;
}
.vehicleWrapper .vehicleLabel {
    color: #A0AEC0;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}

.statusLabel {
    font-size: 18px;
    font-weight: 700;
}
.statusLabel.statusLabelActive {
    color: #01B574;
}
.statusLabel.statusLabelInactive {
    color: #EF7B10;
}
.statusLabel.statusLabelTotal {
    color: white;
}
/*conflict-resolution-will be removed later*/