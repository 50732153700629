.al_section-bar-container {
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.al_section-bar-container > .container-fuild-hr {
    padding: 2px 12px;
}

.al_section-bar-container > .container-fuild-hr > .row-hr {
    border-bottom: 2px solid var(--bs-border-color) !important
}

.al__bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 0.75em;
}

.al__upper-bar {
    width: 65%; /* Width of the bars */
    border-radius: 5px 5px 0px 0px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.al__lower-bar {
    width: 65%; /* Width of the bars */
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.al__bar-row {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    margin: 0px 15px;
    justify-content: center;
}

.al__bar-row .col-3 {
    margin-top: 15px;
    /* border-bottom: 1px solid #00000029 !important; */
    border: none; /* Remove any existing border */
    box-shadow: inset 0px -7px 5px -6px #a5a5a5;
    box-sizing: border box;
}

.al__bar-line {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.al_bar-line-bottom {
    opacity: 0.4;
    width: 80%;
    border-bottom: 1px solid white !important;
}

.al__status-header {
    color: #1F2A5D !important;
    font-weight: 500;
    font-size: 16px;
}

.switch-current-data .form-check-input[type='checkbox']{
    border-radius: 2em !important;
    /* border-radius: 1px !important; */

}
.switch-current-data .form-check-input[type='checkbox']:checked {
    background-color: #0d6efd;
}