.rs-picker-daterange-menu,
.vt__vehicle-info__toggle {
    z-index: 1;
}

.vehicle-tracking {
    width: 100%;
    height: calc(100vh - 70px);
    position: relative;
    overflow: hidden;
}
.vehicle-tracking--charts-open .vt__charts {
    left: 0;
}

.vt__header {
    width: 100%;
    height: 80px;
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #FFFFFF ;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    padding-left: 50px;
}

.vt__body {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1 0 auto;
    height: calc(100vh - (70px + 80px + 216px));
    position: relative;
    overflow: hidden;
}

.vt__map {
    flex: 1 0 auto;
    max-width: 100%;
    transition: all 150ms linear;
}
.vt__map .leaflet-routing-container-hide {
    display: none;
}

.vt__vehicle-info__toggle {
    position: absolute;
    right: 0;
    top: 25px;
    background-color: #22046B;
    color: white;
    border-radius: 5px 0px 0px 5px;
    padding: 0px 6px;
    height: 65px;
    transition: all 150ms linear;
}
.vt__vehicle-info {
    position: absolute;
    right: -320px;
    top: 0;
    height: 100%;
    width: 320px;
    background-color: white;
    transition: all 150ms linear;
}
/* open state handling for vehicle info */
.vt__body--vehicle-info-open .vt__map {
    max-width: calc(100% - 320px);
}
.vt__body--vehicle-info-open .vt__vehicle-info__toggle {
    right: 315px;
    border-radius: 5px !important;
}
.vt__body--vehicle-info-open .vt__vehicle-info {
    right: 2px;
}

.vt__charts {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1 0 auto;
    background: #3F3F3F;
    border-radius: 2px;
    position: absolute;
    left: 100%;
    top: 80px;
    width: 100%;
    height: calc(100% - 80px);
    z-index: 1;
    transition: all 300ms linear;
}

.vt__footer {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1 0 auto;
    /*height: 200px;*/
}

.vt__controllers-wrapper {
    flex: 0 0 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.vt__legends-controller {
   /* max-width: 200px;
    flex: 0 0 auto;*/
    flex: 0 0 19%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #FFFFFF ;
}

.vt__address-seekbar {
    flex: 1 0 78%;
    /*flex: 1 0 auto;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #F3F3F3;
}

.vt__vehicleInfo {
    flex: 0 0 35%;
    background: #FFFFFF ;
    box-shadow: 0px 3px 6px #00000029;
}

.vt__legends {
    flex: 0 0 87%;
    background: #FFFFFF ;
    padding: 6px;
}

.vt__controller {
    flex: 0 0 100%;
    background: #22046B ;
    box-shadow: 0px 6px 9px #00000061;
    padding-top: 2%;
}

.vt__address {
    flex: 0 0 102%;
    background: #FFFFFF ;
    box-shadow: 0px 3px 6px #00000026;
    padding: 0px 10px;
}

.vt__seekbar {
    flex: 0 0 102%;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000026;
    position: relative;
}

#mapParent {
    height: calc(100vh - (70px + 80px));
    width: 100%;
    z-index: 0;
}

.vt__loading-routes-container {
    height: calc(100vh - (70px + 80px));
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

@media screen and (min-width: 1150px) and (max-width:1600px) {
    .vt__address-seekbar {
        flex: 0 0 74%;
    }
    .vt__legends-controller {
         flex: 0 0 24%; 
     }
     .vt__legends {
        flex: 0 0 100%;
    }
}