.hello {
  font: normal normal normal 12px/11px Inter !important;
}
.main-midle-text {
  font: normal normal normal 12px Inter !important;
  letter-spacing: 0px !important;
  color: #1F2A5D !important;
}
.custom-tooltip-donut-chart {
  background: #939393;
  opacity: 0.87;
  border-radius: 6px;
  padding: 6px 4px 6px 3px;
  font: normal normal normal 12px/9px Inter;
  letter-spacing: 0px;
  color: #ffffff;
  position: absolute;
  white-space: nowrap;
  z-index: 1001;
}
.circular-donut {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 2px;
}

.recharts-tooltip-wrapper {
  z-index: 1000 !important;
}
