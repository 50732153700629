.new-dark-dashboard .nd__dark-tc-ignition-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    position: relative;
    margin-top: 24px;
}
.new-dark-dashboard .nd__dark-tc-ignition-status::after {
    content: 'Ignition Status';
    position: absolute;
    font-weight: 500;
    top: -18px;
    left: 8px;
    color: #fff;
    font-size: 12px;
}

.new-dark-dashboard .nd__dark-tc-ignition-status .nd__tc-ignition-status-container {
    height: 15px;
    width: 6px;
    flex: 1 0 auto;
    background-color: #de54e3;
}

.new-dark-dashboard .nd__tc-vehicle-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    margin-top: 24px;
}
.new-dark-dashboard .nd_Dark .nd__tc-vehicle-status::after {
    content: 'Vehicle Status';
    position: absolute;
    font-weight: 500;
    top: -18px;
    left: 8px;
    color: #fff;
    font-size: 12px;
}

.new-dark-dashboard .nd__tc-vehicle-status .tc__vehicle-status-container {
    height: 15px;
    width: 6px;
    background-color: #de54e3;
    flex: 1 0 auto;
}

.new-dark-dashboard .nd__tc-vehicle-status .tc__vehicle-status-container.tc__vehiclestatus-statusbar--immobile {
    background-color: #E06000;
}

.new-dark-dashboard .nd__tc-vehicle-status .tc__vehicle-status-container.tc__vehiclestatus-statusbar--live {
    background-color: #15c7a8;
}

.new-dark-dashboard .nd__tc-vehicle-status .tc__vehicle-status-container.tc__vehiclestatus-statusbar--idle {
    background-color: #15c7a8;
}

.new-dark-dashboard .nd__tc-vehicle-status .tc__vehicle-status-container.tc__vehiclestatus-statusbar--charging {
    background-color: #5491F5;
}

.new-dark-dashboard .nd__dark-tc-ignition-status .nd__tc-ignition-status-container.nd__dark-tc-ignition-status-on {
    background-color: #05CD5E;
}

.new-dark-dashboard .nd__dark-tc-ignition-status .nd__tc-ignition-status-container.nd__dark-tc-ignition-status-off {
    background-color: #E30404;
}

.new-dark-dashboard .nd_Dark .nd__tracking-chart {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 15px;

}

/* horizontal scroll css */
.new-dark-dashboard .nd_Dark .nd__tracking-chart::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background: #0B0C12;
}

.new-dark-dashboard .nd_Dark .nd__tracking-chart::-webkit-scrollbar-track {
    background: #5D4F75;
    box-shadow: inset 3px 2px 9px #5D4F75;
}

.new-dark-dashboard .nd_Dark .nd__tracking-chart::-webkit-scrollbar-thumb {
    background: #0B0C12 !important;
    border-radius: 4px;
    border: 1px solid lightblue;
    /* box-shadow: 0px 0px 1px 1px lightblue; */
}


/* Verticle scroll css */
/* .new-dark-dashboard .nd_Dark .nd__vehicle-info-tabs-content::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background: #0B0C12;
}

.new-dark-dashboard .nd_Dark .nd__vehicle-info-tabs-content::-webkit-scrollbar-track {
    background: #5D4F75;
    box-shadow: inset 3px 2px 9px #5D4F75;
}

.new-dark-dashboard .nd_Dark .nd__vehicle-info-tabs-content::-webkit-scrollbar-thumb {
    background: #0B0C12;
    border: 1px solid lightblue;
    border-radius: 4px;
} */

.new-dark-dashboard .nd__tracking-chart .nd__trackingchart_timeline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 12px;
}

.new-dark-dashboard .nd__tracking-chart .nd__trackingchart_timeline p {
    font-size: 0.75rem;
    color: #909090;
    margin-bottom: 0;
}

.new-dark-dashboard .nd__tracking-chart .nd__trackingchart_timeline > div {
    position: relative;
    height: 15px;
    width: 6px;
    flex: 1 0 auto;
}
.new-dark-dashboard .nd_Dark .nd__tracking-chart .nd__trackingchart_timeline > div.nd__timeline_bar::after {
    content: '';
    position: absolute;
    height: 75px;
    width: 1px;
    z-index: 0;
    left: 50%;
    background-color: #33E8FF;
    /* background-color: #909090; */
}
.new-dark-dashboard .nd_Dark .nd__tracking-chart .nd__trackingchart_timeline > div.nd__timeline_bar:first-of-type::after {
    left: 0;
    background-color: #33E8FF;
    /* width: 2px; */
}
.new-dark-dashboard .nd__tracking-chart .nd__trackingchart_timeline > div.nd__timeline_bar:last-of-type::after {
    right: 0;
    left: auto;
}