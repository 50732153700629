.getReportButton {
    background-color: #22046B;
    color: white;
    width: 7vw;
    border-radius: 5px;
    height: 100%;
    font-size: 14px
}

.downloadBtn-analytics {
    /* background: #808080 0% 0% no-repeat padding-box; */
    background: #22046B 0% 0% no-repeat padding-box;
    color: white;
    margin-right: 1vw;
    width: 4vw;
    border-radius: 5px;
    height: 100%;
    font-size: 20px;
    margin-left: 15px;
  }

.no-value-selected .css-1bd069q-control,
.no-value-selected .css-1wmwbaj,
.no-value-selected .css-1mh0ljs-ValueContainer {
   background-color: #F5F7F9 !important;
   color: #1F2a5d !important;
   border: 0;
}   

.geo__analytics__date-picker .no-date-selected,
.geo__analytics__date-picker .no-date-selected .rs-btn-default {
	background-color: #F5F7F9 !important;
}
