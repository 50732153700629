.sucessfullModel {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 403;
}

.sucessfullModelContent {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  max-width: 70%;
  width: 455px;
  text-align: center;
  max-height: 530px;
  height: 300px;
}

.sucessfullModelCloseBox {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
}

.sucessfullModelLabel {
  color: #10204B;
  font-size: 20px;
  margin: 40px 0px;
}

.AddAlertcloseBtn {
  cursor: pointer;
}
