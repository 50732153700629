.nd__dark-vehicle-info-charging-container {
    height: 100%;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: linear-gradient(90deg, rgb(17 16 24 / 0%) 0.28%, rgb(0 0 0) 129.42%) !important;
    border-radius: 0px 20px 20px 0px !important;
    padding: 15px 10px 20px 10px;
}

.nd_Dark .nd__vehicle-info-charging-lables {
    display: flex;
    text-align: center;
    font-size: 0.80em;
    color: #FFFFFF;
}

.nd__vehicle-info-charging-green {
    display: flex;
    text-align: center;
    font-size: 0.90em;
    color: #36B368;
}

.recharts-cartesian-grid-vertical > line:first-child,.recharts-cartesian-grid-vertical > line:last-child {
    display: none;
}

.nd__dark-vehicle-info-charging-container::-webkit-scrollbar {
    width: 5px;
}

.nd__dark-vehicle-info-charging-container::-webkit-scrollbar-track {
    background: #FFFFFF;
    box-shadow: inset 3px 2px 9px #00000029;
}

.nd__dark-vehicle-info-charging-container::-webkit-scrollbar-thumb {
    background: #C2C2C2;
    border-radius: 4px;
}

.nd__dark-vehicle-info-charging-container .nd__dark-custom-tooltip {
    background: #505288;
    padding: 10px 15px;
    border-radius: 5px;
    /* opacity: 0.6; */
}
.nd__dark-vehicle-info-charging-container .recharts-tooltip-wrapper .nd__dark-custom-tooltip-arrow-dark{
    position: absolute;
    width: 0;
    height: 0;
    margin-top: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    z-index: 2000;
    margin-left: 0;
    top: 0;
}

.nd__dark-vehicle-info-charging-container .nd__dark-custom-tooltip p {
    margin: 0;
    color: #FFFF;
    font-size: 0.75rem;
}

.nd__dark-vehicle-info-charging-container .recharts-tooltip-wrapper-right .nd__dark-custom-tooltip-arrow-dark {
    left: -17px;
    right: auto;
    border-right-color: #505288;
    border-left-color: transparent;
}

.nd__dark-vehicle-info-charging-container .recharts-tooltip-wrapper-left .nd__dark-custom-tooltip-arrow-dark {
    left: auto;
    right: -17px;
    border-left-color: #505288;
    border-right-color: transparent;
}

.nd_Dark .recharts-text{
/* .nd__dark-vehicle-info-charging-container .recharts-text{ */
    fill:white;
    font-size: 9px;
}