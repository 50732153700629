.vt__header .multi-select-container {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
}

.no-value-selected .css-13cymwt-control,
.no-value-selected .css-t3ipsp-control,
.no-value-selected .css-jhaf8m-ValueContainer {
    background-color: #F5F7F9 !important;
    border: 0;
    color: #797694 !important;
}

.no-value-selected .css-bqh4aa {
    background-color: transparent !important;
}

.vt__date-picker {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
}

.search-icon {
    margin-right: -12px;
    background-color: #E7ECF7;
    padding: 10.5px;
    color: #1F2A5D;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.vt__header .btn:disabled,
.vt__header .btn.disabled,
.vt__header fieldset:disabled .btn .vt__btn {
    font-size: 14px !important;
}

.vt__header .multi-select-width svg {
    fill: #22046B;
}

.vt__header .multi-select-width #react-select-3-placeholder {
    color: #1F2A5D;
}

.vt__date-picker svg {
    fill: #22046B;
}

.vt__date-picker .no-date-selected,
.vt__date-picker .no-date-selected .rs-btn-default {
 background-color: #F5F7F9 !important;

}
