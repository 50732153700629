.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw;   
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4) !important;
}
.modalWrapper {
    position: relative;
    height: auto;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 60vw;
    padding: 16px;
}
.modalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.modalCloseButton {
    position: absolute;
    top: 4px;
    right: 16px;
    background: transparent;
}
.modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
}
.modalSaveButton {
    border: unset;
    flex: 0 0 auto;
    color: #ffffff;
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #22046b;
}

.tabsContainer {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    width: 100%;
    border-bottom: 2px solid #E3E0E0;
}
.tab {
    background-repeat: no-repeat;
    background-size: contain;
    height: 36px;
    width: 146px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #8F9BD4;
    cursor: pointer;
    font-weight: 700;
}
.tab.active {
    border-bottom: 5px solid #1F2A5D;
    color: #1F2A5D;
    position: relative;
    top: 2.2px;
}
.tabsContent {
    min-height: 240px;
    width: 100%;
}
