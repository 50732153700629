.vt__legend__title{
    height: 20px;
    text-align: left;
    font: normal normal 600 14px Inter;
    letter-spacing: 0px;
    color: #1F2A5D;
}

.vt__legend__data{
    flex: 0 0 50%;
    display: flex;
    margin-top: 0px;
    padding-right: 0px;
    padding-left : 0px;
    align-items: center;
}

.vt__legend__data__text{
    padding-left: 8px;
    bottom: 3px;
    text-align: left;
    font: normal normal 500 11px Inter;
    color: #1F2A5D;
}

.vt__legend_box{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
}

.vt__legend__colorbox__ion{
    width: 10px;
    height: 11px;
    background: #00CD5E ;
}

.vt__legend__colorbox__ioff{
    width: 10px;
    height: 11px;
    background: #E30505;
}
.vt__legend__colorbox__inon-com{
    width: 10px;
    height: 11px;
    background: #DE54E2;
} 

.vt__legend__colorbox__no-data {
    width: 10px;
    height: 11px;
    background: #B31F85;
}

.vt__legend__colorbox__live{
    width: 10px;
    height: 11px;
    background: rgb(21, 199, 168);
}

.vt__legend__colorbox__idle{
    width: 10px;
    height: 11px;
    background: #F29900;
}

.vt__legend__colorbox__immobilize{
    width: 10px;
    height: 11px;
    background: rgb(224, 96, 0);
}

.vt__legend__colorbox__charging{
    width: 10px;
    height: 11px;
    background: rgb(84, 145, 245);
}

.vt__legend__colorbox__non-communication{
    width: 10px;
    height: 11px;
    background: rgb(222, 84, 226);
}
