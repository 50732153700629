.btn_color_second {
    background-color: #EFF3F8 !important;
    color: #1f2a5d !important;
  }

 .page_item {
    color: #fff !important;
  }
  

  .count_btn {
    background: none !important;
    color: #fff !important;
    border:none !important;
    padding: 10px;
    font-size: 13.5px;
  }

  .count_btn .active,
  .active > .count_btn {
      border: none !important;
      padding: 13px 21.5px;
      background-color: transparent !important;
      background-image: url("/public/images/PaginationBlur.svg") !important;
      color: white !important;
      background-repeat: no-repeat !important;
  }
  .right_icon{
    margin-top: -3px;
    width: 34px;

  }
  .left_icon{
    width: 36px;
  } 
 /*conflict-resolution-will be removed later*/