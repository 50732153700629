.nd__filter-select {
    width: 110px;
    height: 40px;
}

.nd__filter-select,
.dropdown * {
    z-index: 10;
}

.nd__filter-select .dropdown-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 12px;
    justify-content: space-around;
    padding: 8px 0px;
    border-radius: 5px;
    font-weight: 500;
    margin-top: 3px;
}

.nd__filter-select .dropdown-content {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07); 
    background-color: #242644; 
    opacity: 0.56px;
    margin-top:'5px';
    border: 2px solid  rgba(51, 250, 255, 0.57);
    border-radius: 5px;
    padding: 10px;

}

.nd__filter-select .dropdown-content .item {
    padding: 6px;
    cursor: pointer;
    font-size: 12px;
}

.nd__filter-select .dropdown-content .item:hover {
    background: #17152B;
}

.nd__filter-select button {
    z-index: -1;
    display: block;
    width: 300px;
    margin: 0 auto;
}
.nd__filter-select .item {
color: #fff;
}