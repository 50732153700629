.immobilize-nav-link .nav-link.active {
  color: #10204b !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.immobilize-nav-link .nav-link {
  position: relative !important;
  color: #b1bcd5 !important;
  font-size: 16px !important;
  padding-right: 0px !important;
  font-weight: 500 !important;
}
.immobilize-tab-container > .nav-tabs {
  border-bottom: 2px solid #e7ecf7 !important;
  padding: 5px !important;
}

#immobilize-tab-example-tab-non_comm_report,
#immobilize-tab-example-tab-non_comm_log {
  padding-right: 0% !important;
  top: 0.8px;
}

#immobilize-tab-example-tab-non_comm_log {
  margin-left: 40px;
}
#immobilize-tab-example-tab-history {
  margin-left: 40px;
}

.immobilize-tab-container > .nav-tabs .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  top: 31px;
  background-color: #10204b !important;
}

.tab-container {
  height: 80vh !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
