.wrapper,
.secondaryPageWrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
}

.heading,
.secondaryPageHeading {
    font-size: 1.7rem;
    color: #F2F2F2;
    font-weight: 700;
    margin-bottom: 0;
}

.secondaryPageHeading {
    font-size: 1.25rem;
}

.btnNavigateBack {
    background-color: transparent;
    height: 36px;
    width: 36px;
}
.btnNavigateBack img {
    object-fit: cover;
    width: 100%;
    height: auto;
}
/*conflict-resolution-will be removed later*/