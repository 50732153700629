.drp_SingleDatePicker .rs-picker-toolbar-ranges {
    display: none;
}

.rs-picker-daterange-panel-show-one-calendar .rs-picker-daterange-header {
    display: block !important;
}

.rs-picker-daterange-panel-show-one-calendar {
    padding-top: 0px !important;
}