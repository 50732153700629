.oem-trip-report-body {
  padding: 0px 18px 0px 18px !important;
}
.oem-trip-report-box {
  box-shadow: 0px 3px 6px #00000029;
}
#oem-trip-report-model th {
  background: #22046b 0% 0% no-repeat padding-box !important;
}

#oem-trip-report-model th p {
  background: #22046b 0% 0% no-repeat padding-box !important;
  color: white !important;
  margin-bottom: 0px !important;
  margin: 0px 15px 0px 0px;
}

#oem-trip-report-model th svg {
  background: #22046b 0% 0% no-repeat padding-box !important;
  color: white !important;
}
.oem-trip-report-table-header {
  border: none !important;
}
.row-i-icon-trip-report {
  height: 15px;
  width: 15px;
}
.tooltip-oem {
  background-color: #220460 !important;
}
.dwn-btn-oem-model {
  color: #ffffff;
  padding: 0px 13px;
  border-radius: 4px;
  font-size: 21px;
  height: 45px;
  border: unset;
  background-color: #22046b;
}

#oem-trip-report-model th {
  background-color: #22046b !important;
  color: white !important;
}

.popup-close {
  cursor:pointer;
  display:flex;
  flex-direction:row-reverse;
  border-radius:50%;
  padding:3px;
  background-color: white;
}

.popup-close:hover {
 background-color: #D5D5D5; 
}

