.nd_vehicle-info-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}
.vehicle-info-popup-close {
    z-index: 999 !important;
}

.nd__vehicle-info-container-header {
    flex: 0 0 auto;
    margin-bottom: 16px;
    width: 100%;
}

.nd__vehicle-info-container-status {
    flex: 0 0 auto;
    margin-bottom: 16px;
    width: 100%;
}

.nd__dark-vehicle-info-container-tabs {
    flex: 1 1 auto;
    background: #111;
    overflow: hidden;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    width: 100%;
}

.nd__vehicle-info-tabs-main {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    padding: 0px 5px;
    max-height: 90vh;
    /* max-height: 100vh; */
}
.nd_Dark  ::-webkit-scrollbar {
    height: 8px !important;
    width: 8px !important;
    background: #0B0C12 !important;
}

.nd_Dark ::-webkit-scrollbar-track {
    background: #5D4F75 !important;
    box-shadow: inset 3px 2px 9px #5D4F75 !important;
}

.nd_Dark ::-webkit-scrollbar-thumb {
    background: #0B0C12 !important;
    border:1px solid #add8e699 !important;
    border-radius: 4px !important;
}





.nd__vehicle-info-tabs-header {
    flex: 0 0 auto;
    width: 100%;
}

.nd__vehicle-info-tabs-content {
    flex: 1 1 auto;
    margin-right: 4px;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 50px;
    /* height: 600px; */
}

.nd__vehicle-info-tabs-header-container {
    display: flex;
    align-items: center;
    padding: 16px 0;
    justify-content: space-between;
}

.nd__vehicle-info-tabs-content::-webkit-scrollbar {
    width: 5px;
}

.nd__vehicle-info-tabs-content::-webkit-scrollbar-track {
    background: #FFFFFF;
    box-shadow: inset 3px 2px 9px #00000029;
}

.nd__vehicle-info-tabs-content::-webkit-scrollbar-thumb {
    background: #C2C2C2;
    border-radius: 4px;
}





.nd_Dark .nd__vehicle-tabs {
    min-height: 200px;
    width: 100%;
    padding: 0px 15px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
}
.nd_chargingBtn_dark{
    padding: 10px;
    margin-bottom: 1rem;
}
.dark_trackingBtn_div{
    position: absolute;
    top: 20px;
}
.dark_trackingBtn_div img{
    margin-left: 15px;
}

.nd_modal_alert{
    z-Index: 1115 !important;
    display: block;
    backdrop-filter: blur(10px) ;
    background-color: rgba(0, 0, 0, .4) ;
}