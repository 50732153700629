.createDepartmentButton {
    width: 12.5rem;
}
.tooltip_inner_container {
    color:white;
  }

  .popup {
    background-color: #242644 !important;
    border: 1px solid #1a6e70;
    position: absolute;
    top: 7px;
    left: 32px;
    z-index: 1000;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 7px;
    color: #fff;
    border-radius: 10px;
  }
  .popup_ul {
    padding-left: 0;
    text-align: left;
    padding: 5px;
    margin-bottom: unset !important
  }
  .popup_ul li {
    width: 75px;
    padding-left: 12px;
    padding-top: 10px;
  }

  .popup_ul li:hover {
    background-image: url("/public/images/adminActive.png") !important;
    cursor: pointer;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: -7px 8px;
    background-size: 90px;
    
  }


  .Create_Button {
     margin-top: 10px;
     color: #fff;
     background-blend-mode: overlay, normal;
     background: linear-gradient(355deg, rgba(7,10,77,1) 0%, rgba(54,91,218,1) 100%, rgba(56,94,221,1) 100%)
     ,radial-gradient(circle, rgba(7,10,77,1) 0%, rgba(54,91,218,1) 100%, rgba(56,94,221,1) 100%);
     box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.40), 0px 0px 5px 0px #2e6ca6 inset, 0px -1px 8px 0px #2e6ca6 inset;
     border: none;
     padding: 7px 10px 10px 10px;
     font-weight: 500;
     font-size: 16px;
     border: 2px solid #215688; 
   }

   .Create_Button:hover {
    animation: color-slide 0.4s ease forwards;
  }
  
  @keyframes color-slide {
    0% {
      background: linear-gradient(180deg, rgba(46 108 166, 0.90) -22.92%, rgba(46 108 166, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #C8D1FE 0%, #2e6ca6 100%);
  
    }
  
    100% {
      background: linear-gradient(90deg, rgba(46 108 166, 0.90) -22.92%, rgba(46 108 166, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #C8D1FE 22%, #2e6ca6 69%);
  
    }
  }
  .department_container{
    background: #060B28;
    padding: 5px 4rem;
    border-radius: 20px;

  }

  .paginationContainer{
    margin-right: 30px;
  }
  /*conflict-resolution-will be removed later*/