.mainContainer {
    position: absolute;
    bottom:25px;
    right:-25px
}

.messageBubble {
    position: relative;
    top:45px;
    left:20px
}

.messageContainer {
    width: 466px;
    height: 100px;
    /* flex-shrink: 0; */
    border-radius: 28px;
    background: #03A65A;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px; /* 194.444% */
    letter-spacing: -0.63px;
    /* margin-left: 20px; */
}

.messageText {
    width:290px;
    height:40px;
    line-height: 1.5rem;
    padding-left: 20px;
}

.closeIcon {
    position: relative;
    bottom: 25px;
    left: 25px;
    cursor: pointer
}