.group-listing {
    height: calc(100vh - 65px);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    padding-top: 15px;
}

.gl__sidebar {
    flex: 0 0 40%;
    height: 100%;
    border-radius: 20px 20px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: linear-gradient(142deg, rgba(27, 51, 81, 0.30) 19.63%, rgba(155, 97, 149, 0.30) 83.01%);
    box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    border-top-right-radius: 10px;
    overflow: hidden;
}
.gl__sidebar-space {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
}


.gl__sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gl__sidebar-title {
    color: white;
    font-weight: 600;
}

.gl__sidebar-body-list {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    margin-top: 8px;
    padding-bottom: 112px;
}

.gl__sidebar-body-list::-webkit-scrollbar {
    width: 8px;
}

.gl__sidebar-body-list::-webkit-scrollbar-track {
    background: #5D4F75 !important;
}

.gl__sidebar-body-list::-webkit-scrollbar-thumb {
    background: #0B0C12 !important;
    width: 6px;
}

.gl__map-container {
    height: 100%;
    flex: 1 0 60%;
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    border-top-left-radius: 10px;
    position: relative;
    overflow: hidden;
}

.gl__sidebar-body-details {
    height: 100%;
    width: 100%;
    margin-top: 8px;
}

.group_vehicle_listing {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 5px 5px 0px;
    gap: 8px;
    flex-wrap: nowrap;
}
.group_listing_live, .group_listing_idle, .group_listing_charging, .group_listing_parked, .group_listing_non-communication {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 2px 10px;
    flex: 1 0 auto;
}