.multi-select-menu-buttons {
    height: 30px;
    font-size: 12px !important;
    font-weight: normal;
    background-color: #22046B;
    color: #ffffff;
    border: unset;
    padding: 0px 6px;
    border-radius: 4px;
}

.menu-list-max-option-limit {
    color: red;
    padding-left: 20px
  }

