#error-popover .popover-arrow{
    transform: rotate(90deg)!important;
    position: absolute!important;
    top: -12px!important;
    left: 106px!important;
  }
  
  #error-popover{
    position: absolute!important;
    top: 36px!important;
    right: -86px;
  }  
  /*conflict-resolution-will be removed later*/