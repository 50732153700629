.modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(10px) !important;
    height: calc(100vh + 16px);
    width: calc(100vw + 16px);
    position: fixed;
    top: -16px;
    left: -16px;
    z-index: 9999999;
}
.lightModalContainer {
    background-color: rgba(0, 0, 0, 0.35) !important;
    backdrop-filter: none !important;
}
.lightModalContainer .modalWrapper {
    background: white;
    border-radius: 4px;
}
.lightModalContainer .content {
    gap: 24px;
}
.lightModalContainer .content .statusIcon {
    height: 86px;
    width: 86px;
}
.lightModalContainer .content .message {
    font-size: 1.25rem;
    font-weight: 500;
    color: #10204B;
}
.lightModalContainer .modalCloseWrapper {
    top: 0
}


.modalWrapper {
    background: linear-gradient(328deg, rgb(117 9 255 / 24%) 6.73%, rgb(255 255 255 / 24%) 100.28%);
    stroke-width: 1px;
    stroke: #FFF;
    filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.16));
    backdrop-filter: blur(26px);
    flex-direction: column;
    height: auto;
    width: 35vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border-image: linear-gradient(214deg, rgb(250, 249, 251) 6.73%, rgba(255, 255, 255, 0.15) 100.28%) !important;
    padding: 50px;
    position: relative;
}
.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
}

.modalCloseWrapper {
    position: absolute;
    right: 10px;
    top: 10px;
}
.modalCloseIcon {
    width: 35px;
    height: auto;
    cursor: pointer;
}



.lightThemeModalContiner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(214.25deg, rgb(0 0 0 / 54%) 106.73%, rgb(0 0 0 / 16%) 100.28%);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}

.lightThemeModalWrapper {

    background:  #FFFFFF;
    backdrop-filter: blur(26px);
    border-radius: 10px;
    padding: 20px 60px;
    box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 100px 100px 0px rgba(0, 0, 0, 0.25);
}

.lightThemeModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.lightThemeModalTitle {
    flex: 1;
    color:  #10214B;;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
   line-height: normal;
   text-align: center;
}

.lightThemeModalCrossIcon {
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 22px;
}

  

.lightThemeModalContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    /* align-items:center; */
}  

.confirmationModalContainer .content .message {
    text-align: center;
}

.confirmationModalContainer .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 16px;
}

.confirmationModalContainer .panelButton {
    border: unset;
    flex: 0 0;
    color: #ffffff;
    padding: 7px 21px;
    border-radius: 4px;
    font-size: 0.875rem;
    background-color: #22046b;
}