.rs-picker-daterange-calendar-group {
    min-width: 360px !important;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar:nth-child(1) .rs-calendar-time-dropdown {
    min-width: 360px !important;
    top: 8%;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar:nth-child(1) .rs-calendar-btn-close {
    top: 88%;
    min-width: 360px !important;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar {
    display: unset !important;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar:nth-child(1) .rs-calendar-header .rs-calendar-header-month-toolbar {
    position: relative;
    left: -10%;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar:nth-child(2) .rs-calendar-header .rs-calendar-header-month-toolbar,
.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar:nth-child(2) .rs-calendar-body {
    visibility: hidden !important;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar:nth-child(2) .rs-calendar-header .rs-calendar-header-time-toolbar {
    float: right;
    position: absolute;
    top: -83.5%;
    right: 5%;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar:nth-child(2) .rs-calendar-time-dropdown {
    top: -76%;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar:nth-child(2) .rs-calendar-btn-close {
    top: 4%;
    position: absolute;
}

.rs-picker-daterange-menu .rs-picker-daterange-header {
    display: none;    
}

.rs-picker-daterange-menu .rs-picker-daterange-panel {
    padding-top: 10px;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-group .rs-calendar .rs-calendar-header-month-toolbar button{
    color: #8E8E93 !important;
}

.rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 1px solid transparent !important;
}