
.popup-close {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 20px;
    color: #1f2a5d;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
  }
  
  .popup-close:hover {
    color: #1f2a5d;
  }

  .updatepopup-content-gmap {
    padding: 0px !important;
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    width: 800px;
    height: 70vh;
    overflow-y: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .popup-title{
        z-index: 1;
        height: 40px;
        width: 100%;
        position: absolute;
        background: lightgray 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px  #00000029;
        opacity: 0.8;
        font-size: 18px;
        padding-left: 25px;
        font-weight: 600;
        color: #1F2A5D;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
    }

    .Address-bar{
        position: absolute;
        z-index: 1;
        width: 40%;
        background: #FFFFFF;
        opacity: 1;
        height: auto;
        padding: 10px;
        bottom: -8%;
        margin-bottom: 8%;
    }

    .Add-text-bar{
        word-wrap: wrap;
        color: #1F2A5D;
        font-size: 12px;
        font-weight: 500;
    }

    .Add-coordinate-bar{
        font-size: 12px;
        font-weight: 500;
        color: #616161;
    }