/* scroll css customization*/

::-webkit-scrollbar {
  height: 12px;
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track:horizontal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 2px 9px #00000029;
  opacity: 1;
}
::-webkit-scrollbar-track:vertical {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 2px 9px #00000029;
  opacity: 1;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
  background: #c2c2c2 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}
::-webkit-scrollbar-thumb:vertical {
  background: #c2c2c2 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.vob-search-icon {
  margin-left: 36px;
  color: #797694;
}

.vob-search-input {
  background-color: #e7ecf7;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  height: 45px;
  padding: 0px 10px;
  color: #1f2a5d;
}

.vob-btn {
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 0px 4px !important;
  height: 45px !important;
  width: 150px !important;
  border-radius: 4px !important;
}

.dwn-btns {
  color: #ffffff;
  padding: 0px 13px;
  border-radius: 4px;
  font-size: 21px;
  height: 35px;
  border: unset;
  background-color: #22046b;
}

.vob-btns
{
  font-size: 12px !important;
  font-weight: normal !important;
}


.bulk-upload-dropdown .dropdown-toggle::after {
  margin-left: 20px !important;
}

.row-btn {
  background-color: unset;
  border: unset;
}
.row-icon {
  color: #22046B;
  height: 18px;
  width: 20px;
}

.view-row-btn {
  border: unset;
  background-color: unset;
  color: #6a91fa;
  text-decoration: underline;
}

.th-border-bottom {
  /* border-bottom: 1px solid #707070; */
  opacity: 1;
}

.bulk-upload-dropdown .dropdown-menu.show {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000069;
  opacity: 1;
  border: unset;
  width: 202px;
  transform: translate3d(0px, 56.3333px, 0px) !important;
  padding: 5px;
}

.bulk-upload-dropdown .dropdown-item {
  font-size: 14px;
}

.bulk-upload-dropdown .dropdown-item:active {
  background: #e7ecf7 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  color: #1f2a5d !important;
}

.bulk-upload-dropdown .dropdown-item:hover {
  text-decoration: none;
  background: #e7ecf7 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  color: #1f2a5d !important;
}
