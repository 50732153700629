.mainContainer {
    padding: 30px;
}

.dashboardStatContainer {
    padding: 20px;
    padding-bottom: 0;
    border-radius: 20px;
    background: linear-gradient(127deg, rgba(53, 65, 128, 0.74) 28.26%, rgba(28, 34, 63, 0.50) 91.2%);
    backdrop-filter: blur(60px);
}

.dashboardStasHeading {
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: white;
}

.dashboardStatCard {
    margin-bottom: 1.5rem;
}
/*conflict-resolution-will be removed later*/