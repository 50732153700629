.saveBtn {
    border-radius: 10px;
border: 1px solid var(--Button-Dark-Outline-Signup, rgba(0, 0, 0, 0.50));
background: linear-gradient(180deg, rgba(254, 200, 241, 0.4) -22.92%, rgba(237, 146, 215, 0.00) 26.73%), radial-gradient(96.63% 140.33% at 76.68% 66.67%, rgba(96, 46, 166, 0.00) 0%, rgba(201, 119, 214, 0.50) 100%);
background-blend-mode: overlay, normal;

/* Sign Up button */
box-shadow: 0px -1px 8px 0px #9375B6 inset, 0px 0px 5px 0px #FFACE4 inset, 0px 10px 10px 0px rgba(0, 0, 0, 0.10);
display: flex;
width: 110px;
height: 38px;
padding: 14px 26px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
cursor: pointer;
}

.saveBtnContainer {
    width: 50vw;
    display:flex;
    justify-content:center;
    align-items:center;
}

.saveBtn.disabled {
    opacity: 0.5; /* Reduce opacity to make it faded out */
    pointer-events: none; /* Disable pointer events to prevent clicks */
}

.templateListWrapper {
    width: 50vw;
    margin-bottom: 15px;
}

.cardsWrapper {
    max-height: 50vh;
    overflow: auto;
}

.cardContainer {
    flex: 1 0 33.33% !important;
    max-width: calc(33.33% - 12px) !important;
}