.container {
    position: relative;
    border-radius: 12px;
    padding: 15px 30px;
}

.defaultContainer {
    border: 1px solid rgba(51, 66, 255, 0.00);
    background: rgba(31, 44, 100, 0.70);
    box-shadow: 0px 100px 100px 0px rgba(0, 0, 0, 0.25), 0px 40px 80px 0px rgba(0, 0, 0, 0.50);
}
.defaultContainer::before {
    content: '';
    position: absolute;
    top: 0; 
    left: 10%;
    width: 80%;
    height: 1px;
    background: linear-gradient(90deg, transparent, #A6F9FF, transparent);
    z-index: 10;
}

.secondaryContainer {
    background: linear-gradient(180deg, rgba(51, 250, 255, 0.57) 0%, rgba(84, 145, 245, 0.15) 100%);
    z-index: 1;
}
.secondaryContainer::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: inherit;
    background: #242644;
    z-index: -1;
}
