.circular {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 9px;
}
.circle-container {
    position: relative;
  }
.chart-area-card {
    display: flex;
    flex-direction: column;
    margin: 15px;
    width: 98%;
    height: 490px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    border-radius: 4px;
}

.chart-area-card .xLabel>tspan,
.chart-area-card .yLabel>tspan {
    fill: #1F2A5D;
    font: normal normal 500 14px Inter
}

.chart-area-card .yAxis>.recharts-cartesian-axis-ticks>.recharts-cartesian-axis-tick>text>tspan {
    fill: #1F2A5D;
    font: normal normal 500 10px Inter
}

.form-check-input:checked[type=radio],
.form-check-input[type=radio] {
    height: 17px;
    width: 17px;
    border: 2px solid #22046B !important;
    background-size: 18px 18px;
    /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%2322046b%27/%3e%3c/svg%3e") !important */
}

.form-check-input[type='checkbox']:checked{
    background-color: #22046B !important;
    border-color:#22046B !important ;
    margin: 0px;
    margin-right: 7%;
    box-shadow:none;
}

.form-check-input[type='checkbox'] {
    margin: 0px;
    margin-right: 7%;
    box-shadow:none;
}

.radio-checkbox-text {
    font: normal normal 500 12px Inter;
    letter-spacing: 0px;
    color: #1F2A5D;
    cursor: pointer;
    margin-left: 7px;
}

.radio-checkbox-text-heading{
font: normal normal 500 14px Inter;
letter-spacing: 0px;
color: #1F2A5D;
cursor: pointer;
margin-left: 7px;
}

.radio-checkbox-text-legend {
    font: normal normal normal 14px/21px Inter;
    letter-spacing: 0px;
    cursor: pointer;
    margin-left: 7px;
    color: #1F2A5D;
}

.radio-container {
    padding: 1% 0% 2% 50px;
    display: flex;
    width: 22%;
    justify-content: space-around;
}

.checkbox-main-container {
    /* margin-left: 1%; */
    display: flex;
    flex-direction: column;
    flex: 0.9 0.9;
    align-items: flex-start;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    padding-top: 2.7%;
    padding-left: 1%;
    padding-bottom: 2%;
    height: max-content;
    max-height: calc(63vh - 120px);
    overflow-y: auto;
}
.checkbox-main-container::-webkit-scrollbar {
  width: 12px !important;
}

.checkbox-main-container::-webkit-scrollbar-track {
  background: #FFFFFF !important;
}

.checkbox-main-container::-webkit-scrollbar-thumb {
  background: #C2C2C2 !important;
  border: 3px solid transparent !important;
  border-radius: 9px !important;
  background-clip: content-box !important;
}

.checkbox-custom-container {
    display: flex;
    margin-bottom: 12px;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

.checkbox-header{
    font: normal normal 500 18px Inter;
    color: #1F2A5D;
    margin-bottom: 2%;
}

.chart-container {
    display: flex;
    flex-direction: row;
    flex: 1 1;
}
.no-data-map-trip{
    position: relative;
    margin-top: 253px;
  }
  .custom-map-hr-trip{
    margin-left: 60px;
  }
  .no-data-analytics-trip {
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 20px/30px Inter !important;
    color: #bcc6dd;
    position: relative;
    bottom:100px ;
  }
  .hr-line-no-data-trip{
    border-left: 2px solid #e5e5ea !important;
  }
  .vertical-line-no-data-trip {
    border-left: 2px solid #e5e5ea !important;
    height: 300px; /* Adjust the height as needed */
    margin-top:-316px;
  }
  .custom-map-time-trip{
    display: flex;
    justify-content: center;
    font: normal normal 500 18px Inter !important;
    color: #1F2A5D;
    margin-top: 5px;
  }
  .custom-map-count-trip{
    font: normal normal 500 18px Inter !important;
    color: #1F2A5D;
    position: absolute;
    bottom: 206px;
    transform: rotate(270deg);
    left: 1px;
  }