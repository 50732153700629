.group__create__model{
    border-radius: 0 !important;
}
.create__group__label{
color: #1F2A5D;
font-weight: 600;
font-size: 16px;
padding: 2rem 0 2rem 0;
}
.group__create__btn{
    padding-top: 2rem;
}
.create-btn {
    font-weight: 500 !important;
    font-weight: normal !important;
    font-size: 14px !important;
    padding: 0px 4px !important;
    height: 35px !important;
    width: 100px !important;
    border-radius: 4px !important;
    border: unset;
    background-color: #22046B;
    color: #ffffff;
    margin-left: 3rem !important;
}
.groups-cancel-btn {
    font-weight: 500 !important;
    font-size: 14px !important;
    padding: 0px 4px !important;
    height: 35px !important;
    width: 100px !important;
    border-radius: 4px !important;
    border: unset;
    background-color: #eff3f8;
    color: #1f2a5d;
}

.group__create__input {
    width: 420px !important;
}
.group__create__input  {
    
    background: #E7ECF7 !important;
    color: #797694 !important;
    font-weight: 400 !important;
    border: none !important;
    height: 48px !important;
    border-radius: 0px !important;
    opacity: 1 !important;
    
}
.no-value-selected-input .group__create__input{
    background: #F5F7F9 !important;
  }
.border-radius-group_create div {
    border-radius: 0px !important;
  }