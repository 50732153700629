.file-drawer {
  width: 440px;
  margin-left: 13px;
  height: 160px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1.4px dashed #707070;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
  text-align: center;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #1f2a5d;
}

.browse-div {
  padding: 0 4px;
  color: #5594fd;
}
