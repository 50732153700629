.addAlertContainer {
  display: block;
  margin-top: 2rem;
}
.AddAlertButtonBox {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.AddAlertButton {
  background: #22046b;
  height: 36px;
  font-family: Poppins !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  padding: 10px 12px;
  margin-right: 16px;
}
.AddVehicleButton {
  background: #22046b;
  height: 36px;
  font-family: Poppins !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  padding: 10px 12px;
  margin: 0px 10px;
}

.disabledAddVehicleButton {
  background-color: #9e9e9e;
  cursor: not-allowed;
  opacity: 0.6;
}
.AddAlertIcon {
  padding-right: 5px;
}

.alertDetailCard {
  background: #fff;
  padding: 10px 0px;
  width: 98%;
  box-shadow: 0px 0px 4px 4px rgba(217, 224, 255, 0.5);
  border-radius: 8px;
  margin: 0px 0px 0px 3px;
}
.alertDetailsTable {
  border-bottom: 1px solid #22046b;
}
.selectColumn {
  display: flex;
  align-items: center;
  text-align: center;
}
.selectColumn input {
  accent-color: #22046b;
}
.selectColumn p {
  margin-bottom: 0px;
  padding-left: 3px;
  font-family: Poppins !important;
  font-size: 12px;
  color: #1f2a5d;
}
.alertDataList td {
  text-align: center;
  vertical-align: middle;
  height: 42px;
  padding: 0.25rem 0.5rem !important;
}

.alertTableHeader {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #22046b;
}
.alertDataListDisabled,
.alertDataListDisabled button {
  cursor: not-allowed;
}
.alertDataListDisabled button img {
  filter: grayscale(1);
}
.alertEditIcon {
  background: none !important;
}
.alertCountstatusZero {
  color: #bc0078 !important;
}

.alertCountstatus {
  color: #22046B !important;
}

.saveBtnBox {
  display: flex;
  justify-content: flex-end;
}

.submitButtonStyles{
  border-top: 1px solid #9B6FC1;
  padding-top: 1rem;
  margin-top: 2rem;
}
.saveBtnLocation {
  margin-right: 16px;
  margin-bottom: 10px;
  background-color: #9e9e9e;
  color: #fff;
  width: 69px;
  height: 36px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-family: Poppins !important;
}