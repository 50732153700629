.modal-content,
.modal-header {
  border: unset !important;
  padding-bottom: 0px !important;
}

.modal-title {
  font-size: 15px !important;
  font-weight: 700 !important;
  text-align: center !important;
  margin-bottom: 25px !important;
  color: #1F2A5D !important;
}

.modal-body {
  padding-top: 0px !important;
}

.modal-footer {
  border: unset !important;
  padding-top: 0px !important;
  display: block !important;
}

.btn-close {
  color: #acacac !important;
  padding: 3px !important;
  height: 10px !important;
  width: 10px !important;
  margin-top: -3px !important;
  margin-right: -3px !important;
}

.save-btn {
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 0px 4px !important;
  height: 35px !important;
  width: 60px !important;
  border-radius: 4px !important;
  border: unset;
  background-color: #22046B;
  color: #ffffff;
}

.save-btn-disabled {
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 0px 4px !important;
  height: 35px !important;
  width: 60px !important;
  border-radius: 4px !important;
  border: unset;
  background-color: #acacac;
  color: #ffffff;
}

.error-msg {
  margin-right: 10px !important;
}

.width-86 {
  width: 86%;
}

.width-12 {
  width: 12%;
}

.inputfields {
  height: 48px !important;
}

.inputfields-forgot {
  height: 48px !important;
}

.modal-btn {
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 0px 4px !important;
  height: 35px !important;
  width: 75px !important;
  border-radius: 4px !important;
  border: unset;
}

.save-btn {
  background-color: #1f2a5d;
  color: #ffffff;
}

.cancel-btn {
  background-color: #eff3f8;
  color: #1f2a5d;
}

.modal-title {
  font-size: 16px !important;
}
.asterisk-red {
  color: red;
  padding-left: 1px;
}

.registration-number-container{
  font-size: 12px;
  color: #1f2a5d;
  padding-left: 40px;
}

/* remove default browser placeholder when not focused */
input[type='date']:not(:focus):in-range::-webkit-datetime-edit-year-field,
input[type='date']:not(:focus):in-range::-webkit-datetime-edit-month-field,
input[type='date']:not(:focus):in-range::-webkit-datetime-edit-day-field,
input[type='date']:not(:focus):in-range::-webkit-datetime-edit-text {
  color: transparent;
}

/* iOS background fix */
& > input[type='date']:empty {
  background-color: #f9f9f6;
}

/* iOS fix for text alignment */
& > input::-webkit-date-and-time-value {
  text-align: left;
  color: var(--sg-black);
}

/* iOS date-placeholder fix */
& > input[type='date']:not(:focus):empty:after {
  position: absolute;
  color: #aaa;
  content: attr(placeholder);
}

.date-section {
  margin-left: -25px;
  margin-right: 21px;
}

.read-only-field {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Single-Vehicle-inputfields {
  background: #E7ECF7 !important;
  color: #797694 !important;
  font-weight: 400 !important;
  border: none !important;
  height: 48px !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
     -webkit-background-clip: text;
     -webkit-text-fill-color: #797694 !important;
     transition: background-color 5000s ease-in-out 0s;
     box-shadow: inset 0 0 20px 20px #E7ECF7 !important;
 }