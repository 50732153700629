.analytics-main-text {
    font: normal normal 600 18px/39px Inter;
  letter-spacing: 0px;
  color: #1F2A5D;
  }
  .analytics-main-container {
    box-shadow: 0px 3px 6px #00000029;
    margin-left: 0px;
    overflow: auto;
    max-height: 73vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @media (min-width: 1300px) and (max-width: 1542px) {
    .analytics-main-container {
      max-height: Calc(78vh - 0px);
      overflow: auto;
      overflow-x: hidden;
    }
  }
  @media (min-width: 1700px) and (max-width: 3000px) {
    .analytics-main-container {
      max-height: Calc(82vh - 0px);
      overflow: auto;
      overflow-x: hidden;
    }
  }
  .analytics-trends-report {
    padding: 0px 17px 17px 17px;
  }
  
  /*trend.js*/
  .trend-row {
    box-shadow: 0px 3px 6px #00000029;
    padding: 15px 0px 15px 0px;
    margin-left: 5px;
  }
  .trend-main-text {
    font: normal normal 500 16px/27px Inter;
    color: #1f2a5d;
  }
  .no-data-analytics {
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 16px/30px Inter;
    color: #bcc6dd;
    position: relative;
    bottom:100px ;
  }
  .custom-map-hr{
    margin-left: 60px;
  }
  .no-data-map-analytics{
    position: relative !important;
    margin-top: 340px !important;
  }
  .custom-map-time{
    display: flex;
    justify-content: center;
    font: normal normal 500 16px/25px Inter;
    color: #1F2A5D;
    margin-top: 5px;
  
  }
  .custom-map-count{
    font: normal normal 500 16px/25px Inter;
    color: #1F2A5D;
    position: absolute;
    bottom: 206px;
    transform: rotate(270deg);
    left: 20px;
  }
  
  .vertical-line-no-data {
    border-left: 2px solid #e5e5ea !important;
    height: 300px; /* Adjust the height as needed */
    margin-top:-316px;
  }
  .hr-line-no-data{
    border-left: 2px solid #e5e5ea !important;
  }
  
  
  /*OperationsTrendChart.js*/
  
  .text-color-analytics {
    font: normal normal 500 16px/21px Inter;
    color: #1f2a5d;
    margin-right: 25px;
  }
  .circle-ingress {
    background-color: #1F2A5D; /* For the first circle */
    border-radius: 50%;
    width: 15px; /* Adjust the width and height as needed */
    height: 15px;
    display: inline-block;
    margin-right: 6px;
  }
  .circle-egress {
    background-color: #D17FAE; /* For the first circle */
    border-radius: 50%;
    width: 15px; /* Adjust the width and height as needed */
    height: 15px;
    display: inline-block;
    margin-right: 6px;
  }
  
  /*Filter.js*/
  
  /*Report.js*/
  .report-row {
    padding: 0px 0px 0px 0px !important;
    margin-left: 5px;
  }
  .report-main-text {
    font: normal normal 500 16px/27px Inter;
    color: #1f2a5d;
  }
  .report__table__col{
    box-shadow: 0px 3px 6px #00000029 !important;
  }
  #analytics-fence-data th {
    background: #22046b 0% 0% no-repeat padding-box !important;
  }
  
  #analytics-fence-data th p {
    background: #22046b 0% 0% no-repeat padding-box !important;
    color: white !important;
    margin-bottom: 0px !important;
    margin: 0px 15px 0px 0px;
  
  }
  
  #analytics-fence-data th svg {
    background: #22046b 0% 0% no-repeat padding-box !important;
    color: white !important;
  }
  
  /* make th content vertically center */
  #analytics-fence-data th {
    vertical-align: middle !important;
  }
  .select-dropdown-analytics{
    margin-right: -6px;
  }
  .custom-tooltip{
    font: normal normal normal 12px/18px Inter !important;
  }
  