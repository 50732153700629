button.dropdownToggle {
    background: transparent !important;
    width: auto !important;
    min-width: auto !important;
    height: auto !important;
}
button.dropdownToggle::after {
    display: none !important;
}

.dropdownMenu {
    background: linear-gradient(180deg, rgba(51, 250, 255, 0.57) 0%, rgba(84, 145, 245, 0.15) 100%) !important;
    z-index: 1 !important;
    padding: 16px 12px !important;
    min-width: 120px !important;
    height: auto !important;
}
.dropdownMenu::before {
    content: '' !important;
    position: absolute !important;
    top: 1px !important;
    left: 0px !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: inherit !important;
    background: #242644 !important;
    z-index: -1 !important;
}

.dropdownMenuItem {
    margin-bottom: 12px !important;
    position: relative !important;
    background: linear-gradient(94.08deg, rgb(196 65 244) 0%, rgb(114 38 142 / 70%) 100%) !important;
    z-index: 1 !important;
    border-radius: 10px !important;
    color: white !important;
    font-size: 0.75rem !important;
    transition: all 0.2s linear !important;
}
.dropdownMenuItem::before {
    content: '' !important;
    position: absolute !important;
    top: 1px !important;
    left: 1px !important;
    width: calc(100% - 2px) !important;
    height: calc(100% - 2px) !important;
    border-radius: inherit !important;
    background: #242644 !important;
    z-index: -1 !important;
    transition: all 0.2s linear !important;
}
.dropdownMenuItem:last-of-type {
    margin-bottom: 0 !important;
}

.dropdownMenuItem:hover {
    color: white !important;
    background: linear-gradient(94.08deg, rgb(196 65 244) 0%, rgb(114 38 142 / 70%) 100%) !important;
}
.dropdownMenuItem:hover::before {
    background: transparent !important;
}