.hr-line-blue-hr-width-historical-data {
    width: 150px;
    max-width: 150px;
  }
  
  #history-table-historical-data .download-btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  #history-table-historical-data th {
    vertical-align: top;
    padding-bottom: 0 !important;
    padding-top: 19px !important;
  }
  
  .data-points-multi-select-width {
    width: 200px;
    max-width: 240px;
    font-size: 14px;
    height: 45px !important;
  }
  
  .selectLayout-historical-data {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 20px;
  }
  .report__historic__heading{
    font-size:16px;
    font-weight:500
  }

  .historic__header-hr .hr-line {
    width: 100% !important;
    max-width: 100% !important;
  }

 .no-value-selected-historic .css-13cymwt-control,
 .no-value-selected-historic .css-t3ipsp-control,
 .no-value-selected .css-1gr2brf,
 .no-value-selected-historic .css-1nbwlqa-ValueContainer {
     background-color: #F5F7F9 !important;
     color: #797694 !important;
     border: 0;
 }


 .historic__data__date-picker .no-date-selected,
 .historic__data__date-picker .no-date-selected .rs-btn-default {
  background-color: #F5F7F9 !important;
 }

 .records-history-radio-container{
  display: flex;
  justify-content: space-between;
 }
 .records-history-radio-button{
  display: flex;
  width: 15%;
  justify-content: space-between;

 }
  
 .historical-data-analytics thead tr {
  border-bottom: 3px solid var(--bs-border-color);
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2)
 }