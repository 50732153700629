.mainContainer {
    height: 91vh;
    width: 93vw;
    /* background-color: #242644;   */
    background: rgb(6, 11, 40);
}

.usersInfoContainer {
    width: 315px;
    height: 91px;
    flex-shrink: 0;
    border-radius: 12px;
    background: linear-gradient(127deg, rgba(53, 65, 128, 0.74) 28.26%, rgba(28, 34, 63, 0.50) 91.2%);
    backdrop-filter: blur(60px); 
    align-self: flex-end;   
}

.usersInfoText {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.usersInnerText {
    display:flex;
    justify-content:space-between;
    padding-top:20px;
    padding-right:20px;
    padding-left:20px
}



.tableContainer {
    width: 93%;
    display:flex;
    justify-content:center;
    align-items:'center';
}

.tableParentContainer {
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:'center';
    margin-top:35px;
}

.tooltip_inner_container {
    color:white;
  }

  .popupadmin {
    background-color: #242644 !important;
    box-shadow: 0px 3px 6px #33FAFF !important;
    position: absolute;
    top: 28px;
    left: -105px;
    z-index: 1000;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 10px;
    color: #fff;
    border-radius: 10px;
  }
  .popupadmin_ul {
    padding-left: 0;
    text-align: left;
    padding: 5px;
    margin-bottom: unset !important
  }
  .popupadmin_ul li {
    width: 75px;
    padding-left: 6px;
    padding-top: 10px;
  }

  .popupadmin_ul li:hover {
    background-image: url("/public/images/adminActive.svg") !important;
    cursor: pointer;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: -7px 8px;
    background-size: 90px;
    
  }

  .popup {
    background-color: #242644 !important;
    border: 1px solid #1a6e70;
    position: absolute;
    top: 7px;
    left: 32px;
    z-index: 1000;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 7px;
    color: #fff;
    border-radius: 10px;
  }
  .popup_ul {
    padding-left: 0;
    text-align: left;
    padding: 5px;
    margin-bottom: unset !important
  }
  .popup_ul li {
    width: 75px;
    padding-left: 12px;
    padding-top: 10px;
  }

  .popup_ul li:hover {
    background-image: url("/public/images/adminActive.png") !important;
    cursor: pointer;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: -7px 8px;
    background-size: 90px;
    
  }


  .department_container{
    background: #060B28;
    padding: 5px 4rem;
    border-radius: 20px;

  }

  
  .paginationContainer{
    margin-right: 30px;
  }

  .approveBtn {
    display: flex;
    width: 110px;
    height: 32px;
    padding: 14px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    cursor: pointer;

    border-radius: 10px;
    border: 1px solid var(--Button-Dark-Outline-Signup, rgba(0, 0, 0, 0.50));
    background: var(--Button-Dark-TopMenu, linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(237, 146, 215, 0.00) 26.73%), radial-gradient(96.63% 140.33% at 76.68% 66.67%, rgba(96, 46, 166, 0.00) 0%, rgba(201, 119, 214, 0.50) 100%));
    background-blend-mode: overlay, normal;

    /* Sign Up button */
    box-shadow: 0px -1px 8px 0px #9375B6 inset, 0px 0px 5px 0px #FFACE4 inset, 0px 10px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .declineBtn {
    display: flex;
    width: 110px;
    height: 32px;
    padding: 14px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    cursor: pointer;

    border-radius: 10px;
    border: 2px solid rgba(47, 7, 7, 0.28);

    /* Sign Up button */
    box-shadow: 0px -1px 8px 0px #9375B6 inset, 0px 0px 5px 0px #FFACE4 inset, 0px 10px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .actionColParent {
    display:flex;
    width:280px;
    justify-content:space-between;
    align-items:center
  }