.searchControl {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 400;
    width: 240px;
}
.searchWrapper {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 8px 4px;
    border-radius: 5px;
    flex-grow: 1;
    margin-left: 0px;
    border: 2px solid #92a1e5;
    flex-grow: 0;
    border-radius: 12px;
    max-width: 100%;
}
.searchIcon {
    margin-right: 5px;
    color: #555;
    margin: 0px 10px;
}
.searchInput {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    font-size: 0.875rem;
}

.controlsRow {
    position: absolute;
    top: 60px;
    left: 10px;
    z-index: 400;
    display: flex;
    align-items: center;
    gap: 12px;
}
.shapeControlsWrapper {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    background-color: #ffffff;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    padding: 8px 16px;
    border-radius: 6px;
}
.shapeControlsWrapper > p {
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0;
    color: #1F2A5D;
}

.shapeControls {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid #D1E0FF;
    padding: 2px;
    border-radius: 2px;
    gap: 2px;
}
.shapeControl {
    background-color: transparent;
    padding: 4px 4px;
    display: inline-flex;
    align-items: center;
    border-radius: 2px;
}
.shapeControl img {
    width: 20px;
    height: auto;
}
.shapeControl:hover {
    background-color: #E7ECF7;
}
.shapeControl.shapeControlSelected {
    /* background-color: #22046B; */
    background-color: #E7ECF7;
}

.editControlsWrapper {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background-color: #ffffff;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    padding: 8px 12px;
    border-radius: 6px;
}
.editControl {
    background-color: transparent;
    padding: 0;
    display: inline-flex;
    align-items: center;
}
.editControl img {
    width: 28px;
    height: auto;
}

.createMapWrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

/* Hide Leaflet Draw controls */
:global(.leaflet-draw.leaflet-control) {
    display: none !important;
}
