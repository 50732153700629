.groupsDelete-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
  }
  
  .groupsDelete-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex-direction: column;
    height: 30vh;
    width: 28vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    padding: 10px;
  }
  
  .modal-text {
    font-size: 14px;
    font-weight: 600;
    color: #22046B;
  }
  
  .button-container {
    margin-top: 30px;
    width: 18vw;
    display: flex;
    justify-content: space-around;
  }
  
  .modal-button {
    height: 40px;
    border-radius: 7px;
    cursor: pointer;
    width: 90px;
    text-align: center;
    display: flex; /* Add this line */
    align-items: center; /* Add this line */
    justify-content: center; /* Add this line */
    margin: auto;
  }
  
  .modal-button.yes {
    background-color: #22046B;
    color: white;
    font-size: 12px;
  }
  
  .modal-button.no {
    background-color: #808080;
    color: white;
    font-size: 12px;
  }
  