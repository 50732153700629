.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modalContent {
    background-color: white;
    width: 90%;
    height: 85%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    border-radius: 5px;
  }
  
  .modalBody {
    display: flex;
    justify-content: space-between;
  }
  
  .groupSelection {
    width: 22%;
    border: 1px solid #92A1E5;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 2px rgba(217, 224, 255, 0.8);
    padding: 20px;
    text-align: left;
    height: 545px;
  }
   .createdGroups {
    width: 22%;
    border: 1px solid #92A1E5;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 2px rgba(217, 224, 255, 0.8);
    text-align: left;
    height: 450px;
    margin-top: 66px;
  }
  .groupData {
    width: 50%;
    border-radius: 5px;
    text-align: left;
    height: 545px;
  }
  .groupData h3 {
    color: #1F2A5D;
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 700;
    padding: 20px 0px;
  }
  .createdGroups h3 {
    color: #FFFFFF;
    font-size: 16px;
    font-family: Inter !important;
    background: #22046B;
    border-radius: 4px 4px 0px 0px;
    padding: 10px;
  }

  .groupSelection h3{
    color: #1F2A5D;
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 700;
    padding: 20px 0px;
    border-bottom: 1px solid #C9C9C9;
  }
  
  .closeBtn {
    text-align: right;
    cursor: pointer;
  }

  .searchContainerGeofence {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    flex-grow: 1;
    margin-left: 0px;
    border: 2px solid #92a1e5;
    flex-grow: 0;
    height: 40px;
    border-radius: 11px;
    max-width: 210px;
  }
  .vobSearchIcon {
    margin-right: 5px;
    color: #555;
    margin: 0px 12px;
  }
  .vobSearchInput {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
  }
  .groupHeader{
    display: flex;
    background: #EDF2FF;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 6px 10px;
  }
  .groupCheckbox{
    display: flex;
    align-items: center;
    gap: 7px;
  }
  .groupCheckbox input{
    accent-color: #22046B;
  }
  .groupHeaderTitle{
    font-size: 14px;
    color: #1F2A5D;
  }
  .subGroupItem{
    display: flex;
    align-items: center;
    background: #EDF2FF;
    gap: 7px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 6px 10px;
  }
  .subGroupItem input{
    accent-color: #22046B;
  }
  .groupDataTable th{
    background: #22046B !important;
    color: #fff !important;
    padding: 10px !important;
  }
  .fetchGroupData td{
    padding: 7px 10px !important;
  }
  .createdGroupsList{
    background: #C2CBF0;
    padding: 6px 10px;
    width: 200px;
    border-radius: 20px;
    color: #1F2A5D;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .assignVehicleSaveButton{
  width: 79px;
  height: 36px;
  font-family: Poppins !important;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;
  position: relative;
  bottom: 20px;
  }
  .vechicleCardTitle{
    color: #1F2A5D;
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
  
  /* manually vechile assign  */
  .filterHeaderBox{
    display: flex;
    gap: 30px;
    margin: 30px 0px;
  }
  .manualVehicleTable{
    border: 1px solid #ebebeb;
  }
  .AssignVehicltableList td{
    padding: 10px;

  }
  .checkedBox{
    accent-color: #22046B;
  }
  .checkedBoxColor {
    accent-color: white;
  }
  .checkedBoxColor:checked {
    background-color: #22046B;
    border-color: #22046B;
  }

  