.vt__address-info-container {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #1F2A5D;
    height: 100%;
}
.vt__address_info-container .nd__vehicle-info-headers-icons-address{
    width: 16px;
    height: auto;
}

.vt__address-info-container p {
    margin: 0;
    font-size: 0.75rem;
    /* flex: 0 0 auto; */
}

.vt__address-info-lat-lon {
    color: #616161 !important;
    font-size: 12px !important;
}

.vt__address-info-container .ndvh__share-icon {
    background-color: white;
    padding: 4px;
    border-radius: 3px;
}

.vt__address-info-container .nd__vehicle-info-headers-icons {
    height: 20px;
}

.vt__address-info-container .nd__vehicle-info-headers-icons-address{
    width: 16px;
    height: auto;
}

.vt__address-info-container .nd_vehicle-info-header-share-popover {
    width: 410px;
    background-color: #F8F8F8;
    border-bottom: 1px solid #0660C642;
    box-shadow: 0px 3px 6px #0660C642;
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    margin-left: 35px;
    margin-top: -10px;
    display: none; /* Initially hidden */
    z-index: 20000 !important;
}

.vt__address-info-container .nd_vehicle-info-header-share-popover:before {
    content: "";
    position: absolute;
    top: 10px;
    left: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #F8F8F8 transparent transparent;
    z-index: 20000 !important;
}

.vt__address-info-container .nd__vehicle-info-popover-header {
    font-size: 14px;
    text-align: center;
}

.vt__address-info-container .nd__vehicle-info-popover-conetent > .container-fuild-hr {
    padding: 5px 12px;
}

.vt__address-info-container .nd__vehicle-info-popover-conetent > .container-fuild-hr > .row-hr {
    border-bottom: 2px solid var(--bs-border-color) !important
}

.vt__address-info-container .nd__vehicle-info-popover-conetent a {
    font-size: 12px;
}

.vt__address-info-container .na__vehicle-info-copy {
    height: 16px;
    width: 16px;
}

.vt__address-info-container .nd__vehicle-info-header-flex {
    display: flex;
    align-items: center;
}

.vt_address-custom-tooltip {
    color: #FFFFFF;
    opacity: 1 !important;
    background-color: #22046b;
    font-size: 25px;
    z-index: 10;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    border-radius: 4px;
    height: 19px;
  }