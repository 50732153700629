.groups-main-text {
    padding: 17px 0px 12px 0px;
    font: normal normal 600 18px/39px Inter;
    letter-spacing: 0px;
    color: #1F2A5D;
}

.overflow-group {
    max-height: 74vh;
    overflow: auto;
    overflow-x: hidden;
}

.create__group__add-btn {
    font-weight: normal !important;
    font-size: 12px !important;
    padding: 0px 4px !important;
    height: 45px !important;
    width: 150px !important;
    border-radius: 4px !important;
}

.group_row {
    box-shadow: 0px 3px 6px #00000029;

}

.groups__row__td {
    margin-bottom: 0 !important;
}

.group__active_tr {
    box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.2) !important;
    border-bottom: 3px solid var(--bs-border-color) !important;
}

.grp__disable-button{
    background-color: #808080 !important;
}

.grp__checkbox-accent-color {
    accent-color: #22046B !important;
    cursor: pointer
}