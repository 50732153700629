.accordionContainer {
  width: 100%;
  max-width: 600px;
  margin: 15px auto;

}
.accordionSection {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.accordionHeader {
  background-color: #f9f9f9;
  padding: 10px 20px 10px 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
}

.accordionHeader img {
  width: 20px;
  height: 20px;
}
.accordionHeadTitle {
  font-size: 16px;
  color: #1f2a5d;
  font-family: Poppins !important;
  font-weight: 600;
  margin-bottom: 0px;
}
.amenitiesTitle{
margin-bottom: 15px;
}
.accordionContent {
  margin: 10px;
  background-color: #fff;
  padding: 7px;
}

.locationContentCard {
  margin: 10px 14px;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
}

.inputGroup {
  margin-bottom: 15px;
  display: flex;
  align-items: baseline;
}
.inputGroupError {
  margin-bottom: 5px;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  color: #1f2a5d !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.inputGroup .FenceNameInput {
  width: 70%;
  padding: 3px 8px;
  border: 1px solid #e7ecf7;
  background: #e7ecf7;
  font-size: 14px;
  font-family: Poppins !important;
  color: #1f2a5d;
  min-height: 45px;
  border-radius: 3px;
  font-weight: 500;
}
.inputGroup select {
  width: 70%;
  padding: 3px 8px;
  border: 1px solid #e7ecf7;
  background: #e7ecf7;
  font-size: 14px;
  font-family: Poppins !important;
  color: #1f2a5d;
  font-weight: 500;
  height: 40px;
}
.inputGroupSpan{
  padding-right: 10px;
}

.FenceNameInput{
  width: 247px !important;
}
.fenceDetailsLabel {
  width: 35%;
}

.amenities {
  margin-top: 20px;
}

.amenitiesIconContainer {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 10px;
  gap: 7px;
}
.amenitiesIconContainer p {
  font-size: 14px;
  color: #1f2a5d;
  font-family: Poppins !important;
  margin-bottom: 0px;
}
.amenitiesIconContainer input[type="checkbox"] {
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #c2cbf0;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  background-color: #fff;
}

.amenitiesIconContainer input[type="checkbox"]:checked {
  background-color: #22046b;
  border: 2px solid #22046b;
}

.amenitiesIconContainer input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: block;
}

.amenitiesCheckbox {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.saveBtnBox {
  display: flex;
  justify-content: flex-end;
}
.submitButtonStyles{
  border-top: 1px solid #9B6FC1;
  padding-top: 1rem;
  margin-top: 2rem;
}

.saveBtnDisabled {
  background-color: #9e9e9e;
  color: #fff;
  width: 69px;
  height: 36px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 12px;
  font-family: Poppins !important;
}


.saveBtn {
  background-color: #22046B;
  color: #fff;
  width: 69px;
  height: 36px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 12px;
  font-family: Poppins !important;
}

.locationStepContent {
  display: flex;
  gap: 10px;
  align-items: baseline;
  justify-content: space-between;
}
.locationStepLabel {
  font-size: 12px;
  color: #808fd0;
  font-family: Poppins !important;
  margin-bottom: 0px;
  width: 11%;
}
.locationStepDetail {
  font-size: 12px;
  color: #808fd0;
  font-family: Poppins !important;
  margin-bottom: 0px;
  width: 85%;
}
.locationStepSpan {
  color: #808fd0;
}

.locationCordinatesBox {
  padding: 20px;
}
.locationCordinatesContent {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.locationCordinatesLabel {
  font-size: 12px;
  color: #1f2a5d;
  font-family: Poppins !important;
  margin-bottom: 0px;
  width: 25%;
}

.locationCordinatesDetail {
  font-size: 12px;
  color: #1f2a5d;
  font-family: Poppins !important;
  margin-bottom: 0px;
  width: 65%;
}
.saveBtnLocation {
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: #9e9e9e;
  color: #fff;
  width: 69px;
  height: 36px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-family: Poppins !important;
}
.enabledSaveLocation {
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: #22046B;
  color: #fff;
  width: 69px;
  height: 36px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-family: Poppins !important;
}
.errorMessageDiv{
  display: flex;
  gap: 6px;
  margin-bottom: 10px;
  align-items: center;
  position: relative;
  font-size: 12px;
  color: #FF3333;
  width: 60%;
}
.supportBox{
  width: 40%;
} 
.errorMessageContainer{
  display: flex;
}
.saveButtonLocation{
  margin: 10px;
}
