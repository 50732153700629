.fenceDashboard {
    height: 100vh;
    width: 100%;
    position: relative;
}

.fenceDashboard * {
    font-family: Poppins !important;
}

.fenceTypeFilterWrapper {
    position: absolute;
    top: 15px;
    left: calc(340px + 30px + 15px);
    z-index: 599;
    gap: 15px;
    display: flex;
    align-items: center;
    overflow-y: auto;
    padding: 10px 0;
}

.fenceTypeFilterButton {
    flex: 0 0 auto;
    background-color: white;
    cursor: pointer;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    padding: 8px;
    display: inline-flex;
    align-items: center;
    gap: 2px;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 8px;
}
.fenceTypeFilterButton img {
    flex: 0 0 20px;
    width: 20px;
    height: auto;
}

.fenceTypeFilterButton.selected {
    background-color: #efefef;
}