.vehicle-onboard-modal-body .header-container .title {
    margin-right: 0.25rem;
  }
  
  /* .btn-close {
    color: #acacac !important;
    padding: 3px !important;
    height: 10px !important;
    width: 10px !important;
    margin-top: -25px !important;
    margin-right: 2px !important;
  } */
  
  .error-msg {
    margin-right: 10px !important;
  }
  
  .width-86 {
    width: 86%;
  }
  
  .width-12 {
    width: 12%;
  }
  
  .sub-heading {
    font: normal normal medium 24px/35px Inter;
    letter-spacing: 0px;
    color: #1F2A5D;
    opacity: 1;
    border-bottom: 3px solid #1F2A5D;
  }
  
  #vehicle-onboarded-model th p {
    background: inherit;
  }
  
  
  .th-border-bottom {
    width: 1562px;
    /* height: 70px; */
    /* UI Properties */
    background: #E7ECF7 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding-top: 10px !important
  }
  
  .table-header {
    background: #E7ECF7 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
  }
  
  .vehicle-onboard-modal-body .table-header > th {
    background: unset !important;
    border: none !important;
  }
  
  .vehicle-onboard-modal-body .header-container .hr-line-blue-hr-width {
    width: 200px;
    max-width: 200px;
    vertical-align: middle;
  }
  
  .mr-2 {
    margin-right: 0.5rem !important;
  }
  
  .hr-line-blue-vobView-width {
    width: 212px;
    max-width: 212px;
  }
  #vehicle-onboarded-model th{
    background-color: #22046b !important;
  }
  