.al_filter-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.al_filter-flex > button {
    margin: 0px 5px;
    background-color: #EEEEEE;
    padding: 5px 10px;
    font-size: 0.75em;
    border-radius: 2px;
    color: #1F2A5D;
}

.al_filter-flex > button.al__button_active {
    background-color: #22046B;
    color: #ffffff;
}