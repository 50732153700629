.show-password {
  margin-top: -35px;
  width: 10px;
  margin-left: 10px;
}

.form-label {
  color: #1f2a5d !important;
  font-size:14px !important;
  width: 50% !important;
  font-weight: 500 !important;
  
}

.inputfields {
background: #E7ECF7 !important;
color: #797694 !important;
font-weight: 400 !important;
border: none !important;
height: 48px !important;
border-radius: 4px !important;
opacity: 1 !important;
}
.inputfields:focus {
border: none !important;
box-shadow: 1px !important;
outline: none !important;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #1f2a5d;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.popup-close:hover {
  color: #1f2a5d;
}
.updatepass-title{
  text-align: center !important;
  color: #fff;
}

.password-toggle {
margin-right: 10px !important;
margin-top: -70px;
cursor: pointer;
}

.password-input-wrapper {
display: flex;
align-items: center;
}

.password-toggle-icon {
/* margin-left: -16px; */
margin-right: 50px;
cursor: pointer;
}

.updatepopup-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 40px 30px 40px;
  overflow-y: hidden;
  border-radius: 4px;
  border: 1px solid rgba(51, 66, 255, 0.00);
  background: #3E4067;
  box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.50), 0px 100px 100px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}

/* Close button */
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  background: linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602EA6 0%, #C977D6 100%);
  background-blend-mode: overlay, normal;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999; 
}

.updatepopup-content::before{
  content: '';
  position: absolute;
  top: 0; 
  left: 10%;
  width: 80%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #A6F9FF, transparent);
  z-index: 10;
}

.update-save-btn {
padding-top: 35px !important;
}


.cursor-pointer {
  cursor: pointer !important;
}

.chagepassword-success {
    width: 30px;
    right: 10px;
    position: absolute;
    bottom: 12px;
    color: #18cb06 !important;
    margin-right:5px
}
.chagepassword-error {
  width: 30px;
  right: 9px ;
  position: absolute;
  margin-top: -32px ;
  color: #e21212 !important;
}
.confirm-password-exclamation-icon {
  width: 30px;
  right: 11px;
  position: absolute;
  bottom: 12px;
  color: #e21212 !important;
}
/* CSS file or style block */
.text-green {
  color: #18cb06 !important; 
}

.text-red {
  color: #e21212 !important;
}

.update_password_eye_icon {
  color: #22046B ;
}

@media (min-width: 1250px) {
  .form-label {
    color: #1f2a5d !important;
    font-size: 14px !important;
    width: 50% !important;
    font-weight: 700 !important;
}


}

@media (min-width: 1500px) {
  .form-label {
    color: #1f2a5d !important;
    font-size: 14px !important;
    width: 50% !important;
    font-weight: 500 !important;
}
}

/* override the main section css for user profile page */
.main-section{
    position: relative;
    background: transparent;
    transition: all 0.5s ease;
    padding-left: 0px;
    border-radius: 0px;
    height: 0vh;
}
.update__pass__btn{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    background: linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602EA6 0%, #C977D6 100%);
    background-blend-mode: overlay, normal;
    color: #ffffff;
}

.update-save-btn .btn:disabled,
.update-save-btn .btn.disabled,
.update-save-btn fieldset:disabled .btn .vt__btn {
    font-size: 12px !important;
}