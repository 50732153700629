.submitButton {
    border-radius: 10px;
    border: 2px solid rgb(189, 64, 234);
    background: linear-gradient(180deg, rgb(203 46 166) -22.92%, rgb(161 0 122 / 0%) 26.73%), radial-gradient(96.63% 140.33% at 76.68% 66.67%, rgb(72 14 149 / 37%) 0%, rgb(208 18 238 / 52%) 100%);
    box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 80%), 0px 0px 0px 0px #fface400 inset, 0px 0px 0px 0px;
    height: 40px;
    width: 90px;
    padding: 6px;
    margin-left: 43%;
    margin-top: 15px;
}

.submitButton:hover {
    animation: 0.4s ease forwards;
}
/*conflict-resolution-will be removed later*/