.nd_dark_loader_container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0) !important;
    backdrop-filter: blur(3px) !important;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
}

.hidden{
    display: none;
}
.nd_dark_loader_box{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(0px) !important;
    z-index: 10000;
}


.loaderContainer {
  position: relative;
  width: 53px;
  height: 53px;
  backdrop-filter: blur(0px) !important;
}

.square {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  position: absolute;
  transition: all 2.1s ease-in-out;
}

.purple {
    background: linear-gradient(to right, #611659, #FC16A9);
    animation: rotate 3s linear infinite;
  }

#square1 {
  top: 0;
  left: 0;
  transition: transform 2.1s ease-in-out;
  animation: changePosition1 2.1s linear infinite;
  
}

#square2 {
  top: 0;
  right: 0;
  transition: transform 2.1s ease-in-out;
  animation: changePosition2 2.1s linear infinite;
}

#square3 {
  bottom: 0;
  left: 0;
  transition: transform 2.1s ease-in-out;
  animation: changePosition3 2.1s linear infinite;
}

#square4 {
  bottom: 0;
  right: 0;
  transition: transform 2.1s ease-in-out;
  animation: changePosition4 2.1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(315deg);
  }
}

@keyframes changePosition1 {
  0% {
    transform: translateX(0px) translateY(0px) rotate(45deg);
    opacity: 1;
     }
  25% {
    transform: translateX(15px) translateY(15px);
  }
  50% {
    transform: rotate(45deg);
  }
  75% {
    transform: rotate(315deg);
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(315deg);
    opacity: 1;
  }
}
@keyframes changePosition2 {
  0% {
    transform: translateX(0px) translateY(0px) rotate(45deg);
    opacity: 0;
  }
  25% {
    transform: translateX(-15px) translateY(15px);
    opacity: 1;
  }

  50% {
    transform: rotate(45deg);
  }
  75% {
    transform: rotate(315deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(315deg);
    opacity: 1;
  }
}

@keyframes changePosition3 {
  0% {
    transform: translateX(0px) translateY(0px) rotate(45deg);
    opacity: 0;
  }
  25% {
    transform: translateX(15px) translateY(-15px);
    opacity: 1;
  }
  50% {
    transform: rotate(45deg);
    opacity: 1;
  }
  75% {
    transform: rotate(315deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(315deg);
    opacity: 1;
  }
}

@keyframes changePosition4 {
  0% {
    transform: translateX(0px) translateY(0px) rotate(45deg);
    opacity: 0;
  }
  25% {
    transform: translateX(-15px) translateY(-15px);
    opacity: 1;
  }
  50% {
    transform: rotate(45deg);
  }
  75% {
    transform: rotate(315deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0px) translateY(0px) rotate(315deg);
    opacity: 1;
  }
}



