.vt-chart-main {
    display: flex;
    justify-content: stretch;
    margin: 20px;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-y: scroll;
    padding-left: 4%;
}

.vt-chart-content-frame{
    margin: 15px;
    background: #3F3F3F;
    width: 30%;
    padding: 8px;
}

.vt-chart-content {
    background: #2C2C2C ;
    border-radius: 20px;
    width: auto;
    padding-right: 15px;
    padding-bottom: 5px;
}

.vt-chart-title-green {
    font: normal normal 500 12px Inter;
    color: #33D69F;
    padding: 12px 4px 12px 20px;
}

.vt-chart-title-blue {
    font: normal normal 500 12px Inter;
    color: #642AD6;
    padding: 12px 4px 12px 20px;
}


.recharts-cartesian-axis-tick {
    font-size: 10px;
    /* font-size: 11px; */
    font-family: Roboto, sans-serif;
    color: #FFFFFF;
}

.vt-chart-close-img {
    padding: 30% 0% 0% 30%;
    cursor: pointer;
    height: 60px;
}

.vt-chart-close-x {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    height: 17px;
}

.vt-chart-title-disable {
    font: normal normal 500 12px Inter;
    color: #5B5959;
    padding: 12px 4px 12px 25px;
}

.vt-chart-disableMessage {
    text-align: center;
    font: normal normal 600 18px Inter;
    letter-spacing: 0px;
    color: #5B5959;
    position: relative;
    top: -40%;
    left: 6%
}

.vt-chart-main::-webkit-scrollbar {
    width: 8px;
}

.vt-chart-main::-webkit-scrollbar-track {
    background: #616161 !important
}

.vt-chart-main::-webkit-scrollbar-thumb {
    background: #3F3F3F !important;
    outline-color: #616161;
    outline-style: double;
}