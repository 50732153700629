.groups__edit__text{
    color: #1F2A5D;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}
.groups__edit__count__text{
    color: #1F2A5D;
    font-size: 12px;
   }
   .groups__add__count__text{
    color: #1F2A5D;
    font-size: 12px;
    padding-left: 10px;
   }
.groups__edit__conut__para{
    font-weight: 600;
    margin: 15px 1px;

}
.groups__edit__conut__span{
    font-weight: 600;
    margin: 16px 5px;
}
.group__edit__arrow{
    font-weight: 600;
    margin: 15px 1px;
}

#groups__add__table tbody{
    width: 100% !important;
}

#groups__add__table {
    width: 100% !important;
    position: relative !important;
}

#groups__add__table th {
    width: 10% !important;
}

#groups__add__table td {
    width: 10% !important;    
}

#groups__add__table .select-col {
    width: 10% !important;
}

#groups__add__table .select-col, #groups__add__table td, #groups__add__table tr {
    width: 193.46px !important;
}

/* #groups__add__table{
    height: 350px !important;
} */

#groups__add__table th{
    background-color: #E7ECF7 !important;
}

#groups__add__table th p{
    background-color: #E7ECF7 !important;
    color: #1F2A5D !important;
    margin-bottom: 0px !important;
}

#groups__add__table th svg{
    background-color: #E7ECF7 !important;
    color: #1F2A5D !important;
}

/* make th content vertically center */
#groups__add__table th{
    vertical-align: middle !important;
}
.sub__group__table{
    padding: 0 0 2rem 0 !important;
    width: 55% !important;
    margin-left: 15rem;
}

.groups__table--wrapper {
    box-shadow: 0px 3px 6px #00000029;
}

.overflow-group .geofence-dropdown-content {
    margin-left: 0 !important;
    margin-top: 0 !important;
    top: 100%;
    right: 0;
}