.new-dark-dashboard .ndcm__wrapper {
    position: static;
    transform: translateX(0);
    width: 520px;
    height: 220px;
}

.new-dark-dashboard .ndcm__container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: -15px;
}

.new-dark-dashboard .ndcm__container img {
    max-width: 100%;
    height: auto;
}

.new-dark-dashboard .ndcm__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 999;
}

.new-dark-dashboard .ndcm__tooltip::after {
    content: attr(data-label);
    background-color: #505288;
    color: white;
    font-size: 10px;
    white-space: nowrap;
    padding: 2px 4px;
    border-radius: 3px;
    margin-left: 5px;
    position: absolute;
    left: 105%;
    display: none;
}
.new-dark-dashboard .ndcm__tooltip::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #505288;
    position: absolute;
    left: 105%;
    top: 50%;
    transform: translateY(-50%);
    display: none;
}
.new-dark-dashboard .ndcm__tooltip:hover::after,
.new-dark-dashboard .ndcm__tooltip:hover::before {
    display: flex;
}
.new-dark-dashboard .ndcm__battery-charge.ndcm__tooltip::after {
    left: 130%;
} 
.new-dark-dashboard .ndcm__battery-charge.ndcm__tooltip::before {
    left: 130%;
    top: 10px;
}

/* Voltage stats */
.new-dark-dashboard .ndcm__battery-voltage {
    position: absolute;
    left: 80px;
    z-index: 999;
    top: 90px;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 2px;
}
.new-dark-dashboard .ndcm__battery-voltage span {
    color: white;
    font-size: 8px;
    line-height: 1.15;
    font-weight: 500;
}
.new-dark-dashboard .ndcm__battery-voltage img {
    width: 14px;
    height: auto;
}
.new-dark-dashboard .ndcm__battery-voltage .ndcm__battery-voltage--value {
    font-size: 12px;
    line-height: 1.15;
    font-weight: 500;
    color: #33E8FF;
    position: static;
}

/* DTE stats */
.new-dark-dashboard .ndcm__dte {
    position: absolute;
    left: 80px;
    z-index: 999;
    top: 125px;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 3px;
}
.new-dark-dashboard .ndcm__dte span {
    color: white;
    font-size: 8px;
    line-height: 1.15;
    font-weight: 500;
}
.new-dark-dashboard .ndcm__dte img {
    width: 20px;
    height: auto;
}
.new-dark-dashboard .ndcm__dte .ndcm__dte--value {
    font-size: 12px;
    line-height: 1.15;
    font-weight: 500;
    color: #33E8FF;
}

/* Charge stats */
.new-dark-dashboard .ndcm__battery-charge {
    position: absolute;
    right: 105px;
    z-index: 999;
    top: 100px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 44px;
}
.new-dark-dashboard .ndcm__battery-charge span {
    color: white;
    font-size: 8px;
    line-height: 1.15;
    font-weight: 500;
    margin-top: 13px;
}
.new-dark-dashboard .ndcm__battery-charge img {
    width: 20px;
    height: auto;
}
.new-dark-dashboard .ndcm__battery-charge--value {
    position: absolute;
    right: 90px;
    z-index: 999;
    top: 113px;
    color: #33E8FF;
    font-size: 15px;
    line-height: 1.15;
    font-weight: 500;
}

/* Odometer Stats */
.new-dark-dashboard .ndcm__odometer-count {
    position: absolute;
    color: white;
    background-color: transparent;
    z-index: 999;
    top: 155px;
    left: 205px;
    font-size: 11px;
    width: 88px;
    height: 20px;
    padding: 2px;
    display: inline-flex;
    border-radius: 3px;
}
.new-dark-dashboard .ndcm__odometer-count div {
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    flex-shrink: 0;
    line-height: 1.75;
    text-align: center;
    font-weight: 500;
    position: relative;
    margin-right: 2px;
}
.new-dark-dashboard .ndcm__odometer-count div:last-child {
    margin-right: 0;
}
.new-dark-dashboard .ndcm__odometer-count div img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.new-dark-dashboard .ndcm__odometer-count div span {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    border: 0;
}

/* Speed Stats */
.new-dark-dashboard .ndcm__speed-background {
    position: absolute;
    z-index: 999;
    left: 210px;
    top: 110px;
    height: 30px !important;
    width: 85px;
}
.new-dark-dashboard .ndcm__speed-value {
    font-size: 11px;
    color: white;
    font-weight: 500;
    position: absolute;
    z-index: 9999;
    line-height: 1.15;
    left: 225px;
    top: 120px;
    display: inline-flex;
}
.new-dark-dashboard .ndcm__speed-tick {
    position: absolute;
    z-index: 999;
    top: 100px;
    left: 100px;
}
.new-dark-dashboard .ndcm__speed__needle {
    position: absolute;
    z-index: 999;
    top: 60px;
    left: 170px;
    transform: rotate(90deg) scale(-1, 1);
}
.new-dark-dashboard .ndcm__speed__needle--center {
    width: 10px;
    height: 10px;
    background-color: #C441F4;
    display: inline-flex;
    border-radius: 50%;
    position: absolute;
    top: 136px;
    left: 245px;
    z-index: 999;
}

/* Ignition Stats */
.new-dark-dashboard .ndcm__ignition-on,
.new-dark-dashboard .ndcm__ignition-off {
    position: absolute;
    left: 240px;
    top: 180px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.new-dark-dashboard .ndcm__ignition-off {
    left: auto;
    right: 155px;
}

.new-dark-dashboard .ndcm__ignition-on img,
.new-dark-dashboard .ndcm__ignition-off img {
    width: 20px;
    height: auto;
    opacity: 0.45;
}
.new-dark-dashboard .ndcm__ignition-on.active img,
.new-dark-dashboard .ndcm__ignition-off.active img {
    opacity: 1;
}
.new-dark-dashboard .ndcm__ignition--na img {
    filter: grayscale(1);
}

.new-dark-dashboard .ndcm__ignition-on span,
.new-dark-dashboard .ndcm__ignition-off span {
    font-size: 12px;
    color: white;
}