.outerContainer {
    background-color: #F6F6F6;
    height: calc(100vh - 70px);
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
}

.alertAnalyticsHeading {
    background-color: white;
    height: 50px;
    width: 140px;
    margin-left: 40px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 550;
    color: #22046B;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.alert-nav-link .nav-link {
	position: relative !important;
	color: #9CAACF ! important;
    font-size: 16px !important;
    padding-right: 0px !important;
    font-weight:500  !important;
}

.alert-nav-link .nav-link.active {
	color: #1F2A5D !important;
    font-size: 16px !important;
    font-weight:500  !important;
}
.navigatingTabs {
    background-color: white;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.al__alarm-analytics-tab-container {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
    padding: 10px;
}

.al__alarm-analytics-tab-container > .tab-content {
    height: calc(100% - 37px);
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
    margin-top: 10px !important;
}

.al__alarm-analytics-tab-content-container {
    height: 100%;
    width: 100%;
    padding: 10px;
}

.al__-analyticsmain-content {
    height: 100%;
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
}

.al__alarm-analytics-filter {
    flex: 0.1;
}

.al__alarm-analytics-content {
    flex: 0.9;
    overflow-x: hidden;
    overflow-y: auto;
}

.al__alarm-analytics-section-bar-container {
    width: 100%;
    padding: 0px 5%;
    margin-bottom: 15px;
}

.outerContainer-row-1 {
    flex: 0.1;
}

.outerContainer-row-2 {
    flex: 0.9;
    overflow: hidden;
}

.al__alarm-analytics-tab-container > .nav-tabs .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    top: 28px;
    background-color: #10214B;
}

.outerContainer > .nav-tabs {
    border-bottom: none !important;
    background-color: #F6F6F6 !important;
    width: 100%;
    padding-top: 5px;
    flex: 0.1;
}

.outerContainer > .tab-content {
    width: 100%;
    flex: 0.9;
    overflow: hidden;
    margin-top: 0px !important;
}

.outerContainer .nav-tabs > .nav-item >  #al_reports-tab-tab-alert{
    padding: 0px 15px !important;
    margin: 5px 0px 0px 10px;
    height: 100%;
    width: 100px;
}

.outerContainer > .nav-tabs  .nav-link.active::after{
    background-color:  transparent !important;
}

#al_reports-tab-tabpane-alert {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.al__battery-graph-container > .container-fuild-hr {
    padding: 2px 12px;
}

.al__battery-graph-container > .container-fuild-hr > .row-hr {
    border-bottom: 2px solid var(--bs-border-color) !important
}

#al_reports-analytics-tabs-tab-alert_analytics {
    padding-right: 0px !important;
}

#al_reports-analytics-tabs-tab-alert_logs {
    padding-right: 0px !important;
    margin-left: 50px;
}

.al__alarm-analytics-content::-webkit-scrollbar {
    width: 12px !important;
}

.al__alarm-analytics-content::-webkit-scrollbar-track {
    background: #FFFFFF !important;
}

.al__alarm-analytics-content::-webkit-scrollbar-thumb {
    background: #C2C2C2 !important;
    border: 3px solid transparent !important;
    border-radius: 9px !important;
    background-clip: content-box !important;
}