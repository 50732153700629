/* layout and header */
.panelContainer {
    padding: 0px;
    width: 100%;
    max-width: 340px;
    background: #ffffff;
    height: calc(100% - calc(70px + 30px));
    border-radius: 12px;
    position: absolute;
    top: 15px;
    z-index: 402;
    left: 15px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.panelHeader {
    padding: 15px;
    border-bottom: 6px solid #e7ecf7;
    flex: 0 0 auto;
    width: 100%;
}

.panelHeaderRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.panelHeaderTitle {
    font-size: 1rem;
    font-weight: 700;
    color: #1f2a5d;
    margin: 0;
}

.panelButton {
    border: unset;
    flex: 0 0 auto;
    color: #ffffff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.75rem;
    background-color: #22046b;
}

.panelIconButton {
    border: unset;
    color: #ffffff;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #22046b;
}
.panelIconButton > img {
    height: auto;
    width: 20px;
}

.panelBody {
    padding: 8px;
    overflow: auto;
    flex: 1 0 auto;
    height: calc(100vh - calc(340px + 70px + 30px));
    width: 100%;
}
.panelBody::-webkit-scrollbar {
    width: 10px !important;
}
.panelBody::-webkit-scrollbar-track {
    background: #f0f0f0 !important;
}
.panelBody::-webkit-scrollbar-thumb {
    background: #22046b !important;
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    background-clip: content-box !important;
}

.fenceCardDivider {
    margin: 0.25rem 0;
}

/* search */
.panelSearchWrapper {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 8px 4px;
    border-radius: 5px;
    flex-grow: 1;
    margin-left: 0px;
    border: 2px solid #92a1e5;
    flex-grow: 0;
    border-radius: 12px;
    max-width: 100%;
    flex: 1 1 auto;
}

.panelSearchIcon {
    margin-right: 5px;
    color: #555;
    margin: 0px 10px;
}

.panelSearchInput {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    font-size: 0.875rem;
}

/* filters */
.filterWrapper {
    position: relative;
}

.filterContainer {
    right: 0;
    top: calc(100% + 5px);
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
    z-index: 2;
    width: 240px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    display: none;
}
.filterContainerExpanded {
    display: block;
}

.filterCheckbox {
    padding-left: 10px;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 8px;
    background-color: transparent;
}

.filterCheckbox > img {
    flex: 0 0 16px;
    width: 16px;
    height: auto;
    margin-top: 2px;
}

.filterCheckbox > span {
    color: #1f2a5d !important;
    font-size: 0.875rem !important;
    text-align: left;
    flex: 1;
}

/* filter accordion */
.accordionItem {
    border: 0 !important;
    border-radius: 0.375rem;
}
.accordionItem:last-of-type {
    margin-bottom: 0;
}

.accordionHeader > button {
    border: 0 !important;
    border-radius: 0.375rem !important;
    padding: 8px 16px !important;
    outline: none !important;
    box-shadow: none !important;
}
.accordionHeader > button:focus {
    outline: none !important;
    box-shadow: none !important;
}
.accordionHeader > button[aria-expanded="true"] {
    background-color: #E7ECF7 !important;
}

.accordionBody {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 4px !important;
    padding-bottom: 6px !important;
}
