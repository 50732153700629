.popover {
    background-color: #505288 !important;
    z-index: 10000 !important; 
}

.popover :first-child::after {
    border-right-color: #505288 !important;
}
.popover :first-child::after {
    border-left-color: #505288 !important;
}

.popoverHeader {
    background-color: #505288;
    color: white;
    border: 0;
}

.popoverBody {
    background-color: #505288;
    border-radius: 5px;
}