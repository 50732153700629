.hr-line-blue-ar-width {
    width: 135px;
    max-width: 135px;
  }

  .selectLayout{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 20px;
  }
  .alarm__log__date-picker .no-date-selected,
  .alarm__log__date-picker .no-date-selected .rs-btn-default {
     background-color: #F5F7F9 !important;
  }

  .container-fluid .alarmLogsFilter  {
    margin-top: 10px;
  }