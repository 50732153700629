.mr-2 {
  margin-right: 0.5rem !important;
}

.hr-line-blue-vobView-width {
  width: 160px;
  max-width: 300px;
}
.formLabel {
  color: #1f2a5d !important;
  font-size:14px !important;
  text-align: start;
}

.form-label-group.required .formLabel {
  display: flex;
  align-items: center;
}

.rdl-filter {
  margin-bottom: 10px;
  background-color: #e7ecf7;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  height: 45px;
  padding: 0px 10px;
}
.rdl-btn{
  background-color: #1f2a5d;
  color: #fff;
}


.rolesubmit{
  font-weight: 400 !important;
  font-size: 14px !important;
  justify-content: center;
}

.rdl-btn:hover{
  background-color: #1f2a5d !important;
  color: #fff !important;
}

.form-label-group.required .formLabel::after {
  content: "";
  margin-left: 5px;
}
.formLabel {
  color: #1f2a5d !important;
  font-size:14px !important;
  text-align: start;
  font-weight: 500 !important;
}

.formLabel-single-vehicle{
  color: #1f2a5d !important;
  font-size:14px !important;
  text-align: start;
}

.form-label-group.required .formLabel {
  display: flex;
  align-items: center;
}

.form-label-group.required .formLabel::after {
  content: "";
  margin-left: 5px;
}
.row .form-label-group.required .formLabel,
.row .form-label-group.required select {
  width: 100%;
}

.signupLabel{
  font-weight: 500;
}
.custom-dual-listbox {
  justify-content: space-around;
  padding: 10px;
}

.side-heading {
  text-align: center;
  flex-basis: 100px;
}
.content-dule-listbox{
  justify-content: space-around;
}