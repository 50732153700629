.otpModalContiner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(10px) !important;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
  }
  
  .otpModal {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(328deg, rgb(117 9 255 / 24%) 6.73%, rgb(255 255 255 / 24%) 100.28%);
    stroke-width: 1px;
    stroke: #FFF;
    filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.16));
    backdrop-filter: blur(26px);
    flex-direction: column;
    height: 55vh !important;
    width: 35vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border-image: linear-gradient(214deg, rgb(250, 249, 251) 6.73%, rgba(255, 255, 255, 0.15) 100.28%) !important;

  }
  .otpCrossIcon {
    width: 35px; 
    cursor: pointer;  
    position: absolute;
    right: 10px;
    top: 10px;
  }
  
  .modalTextOtpConfirm{
    margin-top: 20px;
    text-align: center;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 15px;
  }
  
 .buttonOtpContainer {
    margin-top: -6px;
    margin-left: 20px;
    /* width: 18vw; */
    /* display: flex;
    justify-content: right; */
  }
  .otpTimerContainer{
    display: flex;
    width: 73%;
    justify-content: space-around;
    padding-bottom: 50px;
  }
  .otpSuccessIcon {
    width: 30px;
    right: 70px;
    position: absolute;
    margin-top: -33px;
    color: #18cb06 !important;
  }
  .otpErrorMessage {
    color: #EF7B10;
    white-space: nowrap;
    font-size: 10px;
    margin-top: 10px;  
  }

  .resendOtp {
    font-size: 12px;
    margin-top:10px;
    margin-left:10px;
    text-decoration: underline;
    color: #00DAEA;
    cursor:pointer
  }

  .resendOtp:hover {
    color: #d1108c !important;
  }

  .resendOtp:focus {
    color: #00DAEA !important;
    text-decoration: underline !important;
  }

  .resendOtp.disabledResendOtp,
  .resendOtp.disabledResendOtp:hover,
  .resendOtp.disabledResendOtp:focus {
    cursor: not-allowed;
    color:rgba(201, 201, 201, 1) !important;
  }

  .otpContainer{
    margin-top: 10px;
  }

  .otpTimer{
    color: #EF7B10;
    font-size: 12px;
  }
  /*conflict-resolution-will be removed later*/