.nd_Dark .nd__vehicle-info-toggle-container {
    flex: 1 0 auto;
    max-width: 60%;
    padding: 0 8px;
    color: #F7F7F7;
}

.nd_Dark .nd__tabs-container {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    /* border-radius: 50px; */
}

.nd__switch-tab {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 50px;
    transition: background-color 0.3s ease;
    font-size: 14px;
    font-weight: 500;
    color:#10214B
}

.nd__switch-tab.nd__active {
    background-color: #EDC5F3;
}