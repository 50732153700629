.backdrop_bg{
  background: transparent !important;
  backdrop-filter: brightness(0.85);
}
.vehicle-onboard-modal-body .header-container .title {
  margin-right: 0.25rem;
}

.error-msg {
  margin-right: 10px !important;
}

.width-86 {
  width: 86%;
}

.width-12 {
  width: 12%;
}

.sub-heading {
  font: normal normal medium 24px/35px Inter;
  letter-spacing: 0px;
  color: #1f2a5d;
  opacity: 1;
  border-bottom: 3px solid #1f2a5d;
}

#assign-onboarded-model th p {
  background: inherit;
  margin-bottom: 2px;
}
#assign-onboarded-model td {
  border-bottom-width: 2px;
}

.th-border-bottom {
  width: 1562px;
  background: #e7ecf7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding-top: 10px !important;
}

.table-header {
  background: #e7ecf7 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.vehicle-onboard-modal-body .table-header > th {
  background: unset !important;
  border: none !important;
}

.vehicle-onboard-modal-body .header-container .hr-line-blue-hr-width {
  width: 200px;
  max-width: 200px;
  vertical-align: middle;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.hr-line-blue-vobView-width {
  width: 212px;
  max-width: 212px;
}
#assign-onboarded-model th {
  background-color: #22046b !important;
}
