.al_section-pi-chart-container {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px;
}

.al_section-pi-chart-flex {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.al_section-pi-chart-header {
    color: #1F2A5D ;
    font-weight: 500;
    font-size: 16px;
}

.al_section-pi-chart-legend-flex {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.70em;
}

.al__driving-container > .container-fuild-hr {
    padding: 2px 12px;
}

.al__driving-container > .container-fuild-hr > .row-hr {
    border-bottom: 2px solid var(--bs-border-color) !important
}

.al__battery-container > .container-fuild-hr {
    padding: 2px 12px;
}

.al__battery-container > .container-fuild-hr > .row-hr {
    border-bottom: 2px solid var(--bs-border-color) !important
}