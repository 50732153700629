.defaultRoot {
  border-radius: 10px;
  padding: 12px 24px;
  transition: .2s ease;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.15;
  color: #fff;
}
.defaultRootDisabled {
  cursor: not-allowed;
}

.fullwidth {
  width: 100%;
}

.root {
  width: 100%;
  color: #fff;
  background: linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 73.67%, #3b186d 0%, #eb71ff 53%);
  background-blend-mode: overlay, normal;
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.40), 0px 0px 5px 0px #FFACE4 inset, 0px -1px 8px 0px #9375B6 inset;
  border: none;
  height: 40px;
}
.rootDisabled {
  background: linear-gradient(180deg, rgba(49, 58, 91, 0.54) -19.57%, #56577A 98.8%, rgba(52, 79, 177, 0.9) 130.43%) !important;
  box-shadow: none;
}

.root:hover {
  animation: root-color-slide 0.4s ease forwards;
}

@keyframes root-color-slide {
  0% {
    background: linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602EA6 0%, #C977D6 100%);
  }

  100% {
    background: linear-gradient(90deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #c464d3 22%, #3b186d 69%);
  }
}

.rootPink {
  background: #BC2EF0;
}
.rootPink:disabled {
  background: linear-gradient(180deg, rgba(49, 58, 91, 0.54) -19.57%, #56577A 98.8%, rgba(52, 79, 177, 0.9) 130.43%);
}

.rootPinkOutlined {
  background: linear-gradient(94.08deg, #C441F4 1.25%, #72268E 96.61%);
  position: relative;
  border-radius: 10px;
  z-index: 1;
}
.rootPinkOutlined::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 8px;
  background: var(--outlined-background, 'blue');
  z-index: -1;
  transition: all 0.2s linear;
}
.rootPinkOutlinedDisabled {
  background: linear-gradient(180deg, rgba(49, 58, 91, 0.54) -19.57%, #56577A 98.8%, rgba(52, 79, 177, 0.9) 130.43%);
}

.rootBlue {
  background: #4C50EA;
}
.rootBlueDisabled {
  background: linear-gradient(180deg, rgba(49, 58, 91, 0.54) -19.57%, #56577A 98.8%, rgba(52, 79, 177, 0.9) 130.43%) !important;
}

.rootBlueOutlined {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  background: linear-gradient(86.05deg, #4C50EA 0.97%, #2B2D84 98.98%);
}
.rootBlueOutlined::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 8px;
  background: var(--outlined-background, 'blue');
  z-index: -1;
  transition: all 0.2s linear;
}

.rootLink {
  color: rgba(57, 255, 255, 0.50);
  font-weight: 500;
  text-decoration-line: underline;
  background: transparent;
}
.rootLinkDisabled {
  color: #56577A;
}

.rootPinkOutlinedModal {
  background-color: transparent;
  border-radius: 10px;
  border: 3px solid rgb(189, 64, 234);
  color: white;
  font-size: 12px;
}
.rootPinkModal{
  border-radius: 10px;
  border: 2px solid rgb(189, 64, 234);
  background: linear-gradient(180deg, rgb(203 46 166) -22.92%, rgb(161 0 122 / 0%) 26.73%), radial-gradient(96.63% 140.33% at 76.68% 66.67%, rgb(72 14 149 / 37%) 0%, rgb(208 18 238 / 52%) 100%);
  box-shadow:  0px 10px 10px 0px rgb(0 0 0 / 80%), 0px 0px 0px 0px #fface400 inset, 0px 0px 0px 0px;
  font-size: 12px;
  color: white;
}