#groups__table__data th {
	background: #22046b 0% 0% no-repeat padding-box !important;
}

#groups__table__data th p {
	background: #22046b 0% 0% no-repeat padding-box !important;
	color: white !important;
	margin-bottom: 0px !important;
	margin: 0px 15px 0px 0px;
}

#groups__table__data th svg {
	background: #22046b 0% 0% no-repeat padding-box !important;
	color: white !important;
}

/* make th content vertically center */
#groups__table__data th {
	vertical-align: middle !important;
}

.group__row {
	box-shadow: 0px 0px 4px #00000029;
    line-height: 22px;
}

.sub__groups__view__table td {
	background: #e7ecf7 !important;
	opacity: 1;
}

.sub__groups__view__table {
	margin-bottom: 20px;
	vertical-align: middle;
}

.sub__groups__view__table td:first-child {
	background: #fff !important;
}

.sub__groups__view__table td {
	padding: 4px !important;
}
.groups__row__td {
	margin-bottom: 0 !important;
	
}
.groups__row__td_name{
	color: #0000EE;
    text-decoration: underline;
	cursor: pointer;
}
.groups_assinee_td{
	background: #E7ECF7;
	margin: 0px 40px;
    border-radius: 5px;
    padding: 2px;
}
.groups__pluse {
	box-shadow: 0px 0px 5px #00000029;
	border-radius: 50%;
	padding: 2px 2px 4px 2px;
}

.popupGroups {
	background-color: #ffff !important;
	box-shadow: 0px 3px 6px #00000029 !important;
	position: absolute;
	left: 70px;
	z-index: 1000;
	width: fit-content;
	padding: 3px 6px;
}

.popupGroups__subGroup {
	background-color: #ffff !important;
	box-shadow: 0px 3px 6px #00000029 !important;
	position: absolute;
	top: 35px;
	left: 58px;
	z-index: 1000;
	width: fit-content;
	padding: 3px 6px;
}

.popupGroups_ul {
	padding-left: 0;
	text-align: left;
	padding: 5px;
	margin-bottom: unset !important
}

.popupGroups__subGroup .popupGroups_ul li {
	width: fit-content;
	padding: 2px 5px;
}

.popupGroups .popupGroups_ul li {
	width: 75px;
	padding-left: 2px;
}

.popupGroups_ul li:hover {
	background-color: #e7ecf7;
	cursor: pointer;
}

.popupSubGroups_ul {
	padding-left: 0;
	text-align: left;
	padding: 0px;
}
.popupSubGroups_ul li {
	padding: 2px 5px;
}

.popupSubGroups_ul li:hover {
	background-color: #e7ecf7;
	cursor: pointer;
}

.popupGroupsData {
	background-color: #ffff !important;
	box-shadow: 0px 2px 10px 0px #00000029 !important;
	position: absolute;
	top:-30px;
	left: 70px;
	z-index: 9999;
	width: 98px;
	height: 80px;
	padding: 3px 6px;
}

#groups__table__data {
	border-collapse: separate;
	border: 1px solid #eeeeee;
}

#groups__table__data th {
	width: 150px;
	text-align: center;
	padding: 5px;
}

#groups__table__data td {
	width: 150px;
	text-align: center;
	padding: 10px 14px !important;
}

/* .groups__line {
	border-bottom: 1px dotted red;
	border-left: 1px dotted red;
	position: relative;
}

.groups__middle__line {
	margin-top: 25px;
	border-bottom: 1px dotted red;
	border-left: 1px dotted red;
	position: relative;
	margin-left: -15px;
} */

/* .create__groups__tooltips .tooltip-inner {
	background-color: #ffffff !important;
	box-shadow: 0px 6px 9px #0000004d;
	color: #1f2a5d;
	font-size: 12px !important;
	font-weight: 400 !important;
	max-width: 214px !important;
	width: 185px;
	padding: 20px;
	margin-left: 130px !important;
}

.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: #ffffff !important;
} */

.subgroup-line-col {
	position: relative;
}
.subgroup-line-col::before,
.subgroup-line-col::after {
	content: '';
	position: absolute;
	top: 50%;
	width: 50%;
	left: 50%;
	height: 1px;
	border: 2px dashed #22046B;
	border-style: dashed; 
    border-spacing: 51px; 
	border-radius: 8px; 
}
.subgroup-line-col::before {
	height: calc(100% + 13px);
    width: 1px;
    left: 50%;
    top: -38px;
}
.subgroup-line-col-0::before {
	height: calc(100% - 25px);
    width: 1px;
    left: 50%;
    top: 0px;
}
