.tabsContainer {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 20px;
}

.tab {
    background-image: url('/public/images/svgicon/inactiveTab.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 36px;
    width: 146px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
}

.tab.active {
    background-image: url('/public/images/svgicon/activeTab.svg');
}

.defaulttabsContainer {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    border-bottom: 2px solid #424B7E;
}

.defaulttab {
    background-repeat: no-repeat;
    background-size: contain;
    height: 36px;
    width: 146px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #424B7E;
    cursor: pointer;
    font-weight: 700;
}

.defaulttab.active {
    border-bottom: 5px solid #604497;
    color: #fff;
    position: relative;
    top: 2.2px;
    
}
/*conflict-resolution-will be removed later*/