.leaflet-control-attribution {
    font-size: 55px;
  }

  .leaflet-control-zoom-out {
    font-size: 0px;
  }
  .leaflet-control-zoom-in {
    font-size: 0px;
  }

  .leaflet-control-layers-toggle{
    display: flex;
    flex-direction: column;
  }

  .leaflet-tile-pane {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .leaflet-control-container .leaflet-bottom,
    .leaflet-control-container .leaflet-right {
      position: absolute;
      right: 10px;
      top:65%;
      z-index: 400;
    }

  .cluster-marker {
    color: #fff;
    background: #1978c8;
    border-radius: 50%;
    padding: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .marker-cluster-small,
  .marker-cluster-medium,
  .marker-cluster-large,
  .marker-cluster-small div,
  .marker-cluster-medium div,
  .marker-cluster-large div {
    background-image: url("/public/images/makercluster.svg")  !important ;
    background-color:transparent
  }

  .marker-cluster span{
    color: #fff;
    font-weight: bold;
  }

  /* .custom-marker-highlighter {
    border: 2px solid #1f2a5d !important;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .custom-marker-popup {
    background-color: #fff;
    border-radius: 5px;
  }

  .custom-marker-popup .leaflet-popup-content-wrapper{
    border-radius: 5px;
    text-align: center;
  }

  .custom-marker-popup .leaflet-popup-content {
    margin: 8px 8px 8px 8px;
  }

  .leaflet-container {
    height: 88vh;
  }
  .leaflet-draw-toolbar {
    transform: rotate(90) !important
  }
  .editControl {
    transform: rotate(90) !important;
  }
   .leaflet-bar {
    transform: rotate(90) !important
   }
    .leaflet-draw-toolbar-top {
      transform: rotate(90) !important
    }