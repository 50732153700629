.save-btn-geofence {
  background-color: #22046b;
  color: #ffffff;
  margin-left: 5px;
}

.cancel-btn-geofence {
  background-color: #eff3f8;
  color: #1f2a5d;
  margin-left: 7px;
}
.error-message-geofence-image-upload {
  color: #ff0000;
  white-space: nowrap;
  font-size: 13px;
  text-align: left;
  margin-top: 5px;
  margin-left: 16px;
}
