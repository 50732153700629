.fl_vehicle_subs_row {
    margin-top: 10px !important;
    font-size: 14px;
    padding: 5px;
    align-items: center;
}

#submitButtonVerifyAndSubscribe {
    border-radius: 4px !important;
    background-color: #22046B !important;
    font-weight: normal !important;
    font-size: 14px;
}