.alarmAnalytics-table > tbody > tr > td {
    padding: 5px;
}

.al__pop-pup-table-content {
    padding: 30px !important;
    height: fit-content !important;
    width: 75vw !important;
    margin-left: 11px;
}

.al__pop-pup-table-content > .filters-data {
    margin-top: 5px !important;
    padding: 5px !important;
}

.al__pop-pup-table-content > .filters-data > .table-body-container {
    margin-top: 15px !important;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
    max-height: none !important;
    overflow-y: unset !important;
}

.al__pop-pup-table-content > .filters-data > .table-body-container > .border-top {
    border-top: none !important;
}

.al__dwn-btn {
    background-color: #22046b !important;
    position:absolute;    
    top: 22px;
    right:11px
}

.dwn-btn.al__dwn-btn[disabled] {
    background-color: #808080 !important;
    color: white;
  }

.al__pop-pup-table-content > .popup-close {
    color: #ACACAC !important;
    font-size: 25px !important;
    position: absolute;
    top: 0;
}


.alarmAnalytics-table th {
    padding: 10px !important;
}
.alarmAnalytics-table{
    margin-top: 42px !important;
}

.no-data-text {
    color: #C6C6C6;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.44px;
    margin-top: 10px;
}
.filters-header-date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
}
.filters-header-date span {
    background-color: #EFF3F8;
    color: #102F4B;
    padding: 6px 4px;
}
.filters-header-date span:first-of-type {
    padding-right: 0;
}