.card {
    min-height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border-radius: 20px;
    padding: 10px 20px;
    background: linear-gradient(127deg, rgba(53, 65, 128, 0.74) 28.26%, rgba(28, 34, 63, 0.50) 91.2%);
    backdrop-filter: blur(60px);
}

.heading {
    font-size: 0.9rem;
    color: #A0AEC0;
    font-weight: 500;
}

.card .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.card .cardContent .cardLink {
    text-decoration: none;
    font-size: 0.8rem;
    color: #FFFFFF;
    font-weight: 300;
    transition: all 0.2s ease;
}

.card .cardContent .cardLink:hover,
.card .cardContent .cardLink:focus {
    color: #00CFFD !important;
}

.card .cardImage {
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card .cardImage img {
    max-width: 100%;
}
/*conflict-resolution-will be removed later*/