.container {
    padding: 30px 45px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.headerWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
    gap: 30px;
}
.headerWrapper p {
    color: #FFF;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    margin-top: 15px;
}
.headerWrapper > div {
    min-width: 420px;
}

.contentWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    gap: 20px;
    flex: 1;
    position: relative;
}

.column {
    border-radius: 5px;
    padding: 0;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}
.columnHeader {
    border-radius: 5px;
    background: #17152B;
    padding: 15px;
}
.columnHeader > p {
    color: #39FFFF;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
}
.columnHeaderError {
    color: #EF7B10;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin: 10px 5px;
}

.columnBody {
    padding: 30px 20px;
    margin: 1px;
    overflow: auto;
}
.columnBody::-webkit-scrollbar {
    width: 10px !important;
}
.columnBody::-webkit-scrollbar-track {
    background: #393939 !important;
}
.columnBody::-webkit-scrollbar-thumb {
    background: #0B0C12 !important;
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    background-clip: content-box !important;
}

.columnFooter {
    padding: 30px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.columnFooter > button {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
}

/* column modules list */
.columnModuleList {
    flex: 0 0 260px;
}
.columnModuleList .columnBody {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.columnModuleList .moduleListItem {
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 400;
    background-color: transparent;
    flex: 1 0 100%;
    text-align: left;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease;
}
.columnModuleList .moduleListItem > img {
    opacity: 0;
    transition: all 0.2s ease;
}
.columnModuleList .moduleListActiveItem {
    background: #17152B;
    position: relative;
}
.columnModuleList .moduleListActiveItem > img {
    opacity: 1;
}

/* column submodules list and action types */
.columnSubmodulesActionTypes .columnHeader {
    display: flex;
    align-items: center;
}
.columnSubmodulesActionTypes .columnHeader p:first-child {
    flex: 0 0 200px;
    text-align: left;
}
.columnSubmodulesActionTypes .columnHeader p:last-child {
    flex: 1 1 100%;
    text-align: left;
}
.columnSubmodulesActionTypes .columnBody {
    gap: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.columnSubmodulesActionTypes .rowWrapper {
    display: flex;
    align-items: center;
}
.columnSubmodulesActionTypes .rowWrapper .submoduleWrapper {
    flex: 0 0 200px;
    font-size: 14px;
}
.columnSubmodulesActionTypes .rowWrapper .actionTypesWrapper {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: nowrap;
}
.columnSubmodulesActionTypes .rowWrapper .actionTypesWrapper > * {
    margin-right: 20px;
    font-size: 14px;
}

/* column assigned modules */
.columnAssignedModules {
    flex: 0 0 300px;
}
.columnAssignedModules .columnBody {
    display: flex;
    flex-direction: column;
    flex: 1;    
}
.columnAssignedModules .columnBody > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
}
.columnAssignedModules .assignedModuleListItem {
    border-radius: 20px;
    background: #17152B;
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 400;
    flex: 1 0 100%;
    text-align: left;
    padding: 8px 16px; 
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-start;
}
.columnAssignedModules .assignedModuleListItem > img {
    width: 14px;
    height: auto;
}