.activeModalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(10px) !important;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
  }
  
  .activeModal {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(328deg, rgb(117 9 255 / 24%) 6.73%, rgb(255 255 255 / 24%) 100.28%);
    stroke-width: 1px;
    stroke: #FFF;
    filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.16));
    backdrop-filter: blur(26px);
    flex-direction: column;
    height: 45vh;
    width: 35vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .activeCrossIcon{
    width: 35px; 
    cursor: pointer;  
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .activeModalText {
 
  color:  #FFF;
  font-family: Inter;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  }
  .activeModalTextConfirm{
    margin-top: 10px;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
  }
  
  .buttonContainer {
    margin-top: 30px;
    width: 18vw;
    display: flex;
    justify-content: space-around;
  }
  
  .modal_button {
    height: 40px;
    border-radius: 7px;
    cursor: pointer;
    width: 90px;
    text-align: center;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    margin: auto;
  }
  
  .yes {
    border-radius: 10px;
    border: 2px solid rgb(189, 64, 234);
    background: linear-gradient(180deg, rgb(203 46 166) -22.92%, rgb(161 0 122 / 0%) 26.73%), radial-gradient(96.63% 140.33% at 76.68% 66.67%, rgb(72 14 149 / 37%) 0%, rgb(208 18 238 / 52%) 100%);
    box-shadow:  0px 10px 10px 0px rgb(0 0 0 / 80%), 0px 0px 0px 0px #fface400 inset, 0px 0px 0px 0px;
    font-size: 12px;
    color: white;

  }
  
  .no{
    background-color: transparent;
    border-radius: 10px;
    border: 3px solid rgb(189, 64, 234);
    color: white;
    font-size: 12px;
  }
  