.dark-dashboard-main-container {
    background-color: #131524;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.dark-sidebar-container {
    top: 0;
    left: 0;
    height: 100%;
    background-color: #131524;
    width: calc(100vw - 95vw);
    overflow: hidden;
  
}

.dark-sidebar-logo {
    height: calc(100vh - 90vh);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark-sidebar {
    height: calc(100vh - 10vh);
    background-image: url('/public/images/darkLayout/sideBar.svg');
    background-repeat: no-repeat;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-routes {
    margin-top: 53px;
}

.navlink__active_dark{
    background-image: url('/public/images/darkLayout/blur.svg');
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.dark_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 10px 15px 0px 13px;
    text-decoration: none !important;
    gap: 3px;
    transition: all 0.5s;
    height: 62px;
  }
  
.dark_navlink__active {
    background-image: url('/public/images/rightSideBar/active.svg');
    /* background-image: url('../assets/dashboardNewIcons/routes/active.svg'); */
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100px 100px;
    background-position: -21px -20px;
    /* background-position: -9px -20px; */
    z-index: 9999;
}

.dark-topbar-container {
    height: 70px;
    width: calc(100vw - 5vw);
    
}
.new-dashboard-map_height{
    margin-top: 11px !important;
}
.dark-topbar-background{
    width: 100%;
    position: absolute;
}
.dark-topbar-background-img{
    height: 86px;
}

.dark-top-pannel {
    height: 100%;
    width: 100%;
    /* position: relative;
    z-index: 1; */
}

.nd__dark-search-bar {
    margin: 20px 0px 0px -64px;
    z-index: 999;
}

.search-dark-vehicle {
    width: 300px;
    height: 30px;
    top: 49px;
    left: 120.84px;
    border-radius: 4px;
    border: 1px solid #424666;   
    background: linear-gradient(180deg, #2E335A 0%, #1C1B33 100%),
    linear-gradient(0deg, #424666, #424666);
    color: rgba(255, 255, 255, 0.50);
    padding-left: 30px;
}

.dark-main-section {
    height: calc(100vh - 10vh);
    width: calc(100vw - 5vw);
    position: absolute;
    bottom: 0px;
    right: 0;
}
.new_dark_dashboard_header{
    padding-top: 10px !important;
}
.dark-main-section .dark-main-content {
    height: calc(100vh - 80px);
    width: calc(100vw - 5vw);
}
.dark-main-section .user-management-scroll {
   overflow-y: scroll;
}
.dark-main-section .dark-main-content::-webkit-scrollbar {
    width: 10px !important;
}

.dark-main-section .dark-main-content::-webkit-scrollbar-track {
    background: #393939 !important;
}

.dark-main-section .dark-main-content::-webkit-scrollbar-thumb {
    background: #0B0C12 !important;
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    background-clip: content-box !important;
}
.nd__dark-search-icon {
    position: relative;
    left: 23px;
    color: rgba(255, 255, 255, 0.50);

}

 input:focus-visible{
    outline: 0 !important;
}