.nd__vehicle-info-filter-control-container-parent {
    flex: 1 0 auto;
    max-width: 40%;
}

.nd__vehicle-info-filter-control {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: linear-gradient(180deg, #EFF3F8, #EFF3F8, #EFF3F8) 0% 0% no-repeat padding-box;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;

}

.nd__filter-controls-container-flex>.container-fluid {
    margin-top: 0 !important;
}

.nd__filter-controls-container {
    display: flex;
    justify-content: space-between;
    border-radius: 50px;
}

.nd__vehicle-info-filter-control-field {
    background: #EFF3F8;
    border-radius: 5px;
    text-align: center;
    font-size: 0.75em;
    padding: 5px;
}

.nd__vehicle-info-filter-control>svg {
    fill: #10214B !important;
    height: 10px;
    width: 10px;
}

.nd__filter-controls-row {
    display: flex;
    align-items: center;
}

.nd_Dark .nd__filter-select {
    /* border: 0; */
    background-color: transparent;
    color: #44FEFF;
    /* border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    padding: 6px 12px;
    margin-right: 12px; 
    outline: none;
    */
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #51D6F3;
    background: radial-gradient(85.17% 121.05% at 50% 89.47%, rgba(77, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(180deg, #65b3ca00 3.95%, rgba(56, 255, 255, 0.00) 93.42%);
    background-blend-mode: normal, overlay;
}