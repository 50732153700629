.frame {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.frame .div {
    background: rgb(116, 72, 151);
    background: linear-gradient(0deg, rgb(154 56 135) 20%, rgb(76 57 113) 100%);
    overflow: hidden;
    width: 1920px;
    height: 100vh;
    position: relative;
}

.frame .overlap {
    position: absolute;
    width: 2297px;
    height: 1491px;
    top: -219px;
    left: -243px;
}

.frame .overlap-group {
    position: absolute;
    width: 1096px;
    height: 564px;
    top: 110px;
    left: 0;
}

.frame .ellipse {
    width: 450px;
    height: 450px;
    top: 0;
    left: 0;
    border-radius: 268px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    background-color: rgba(31, 42, 93, 0.05);
    ;
}

.frame .we-are-under {
    position: absolute;
    top: 345px;
    left: 390px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 55px;
    letter-spacing: 1.28px;
    line-height: 70px;
}

.frame .logo-left {
    position: absolute;
    width: 250px;
    height: 130px;
    top: 109px;
    left: 303px;
}

.frame .ellipse-2 {
    width: 385px;
    height: 385px;
    top: 1106px;
    left: 1912px;
    border-radius: 192.5px;
    box-shadow: 0px 15px 30px #00000040;
    position: absolute;
    background-color: rgba(31, 42, 93, 0.20);
    ;
}

.frame .overlap-2 {
    position: absolute;
    width: 1326px;
    height: 1200px;
    top: 0;
    left: 879px;
}

.frame .overlap-group-2 {
    position: absolute;
    width: 1076px;
    height: 1095px;
    top: 0;
}

.frame .ellipse-3 {
    width: 400px;
    height: 400px;
    top: 260px;
    left: 655px;
    border-radius: 245.5px;
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
    ;
    position: absolute;
    background-color: rgba(31, 42, 93, 0.20);
    ;
}

.frame .ellipse-4 {
    width: 230px;
    height: 230px;
    top: 182px;
    left: 100px;
    border-radius: 133px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    ;
    position: absolute;
    background-color: rgba(31, 42, 93, 0.20);
    ;
}

.frame .vector {
    position: absolute;
    width: 300px;
    height: 180px;
    top: 533px;
    left: 526px;
}

.frame .img {
    position: absolute;
    width: 250px;
    height: 150px;
    top: 680px;
    left: 400px;
    z-index: 2;
}

.frame .vector-2 {
    position: absolute;
    width: 280px;
    height: 170px;
    top: 400px;
    left: 200px;
}

.frame .vector-3 {
    position: absolute;
    width: 220px;
    height: 110px;
    top: 600px;
    left: 210px;
}

.frame .vector-4 {
    position: absolute;
    width: 270px;
    height: 150px;
    top: 600px;
    left: 430px;
}

.frame .group {
    position: absolute;
    width: 480px;
    height: 570px;
    top: 220px;
    left: 300px;
    z-index: 2;
}

.frame .ellipse-5 {
    width: 200px;
    height: 200px;
    top: 690px;
    left: -110px;
    border-radius: 133px;
    box-shadow: 0px 5px 15px #00000040;
    position: absolute;
    background-color: rgba(31, 42, 93, 0.20);
    ;
}

.frame .ellipse-7 {
    position: absolute;
    width: 420px;
    height: 420px;
    top: 718px;
    left: 217px;
    background-color: rgba(31, 42, 93, 0.20);
    border-radius: 210.5px;
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
    z-index: 0;
}

.frame .text-wrapper {
    position: absolute;
    width: 490px;
    top: 485px;
    left: 400px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0.48px;
    word-spacing: 2px;
}

.frame .ellipse-6 {
    position: absolute;
    width: 421px;
    height: 421px;
    top: 564px;
    left: -84px;
    background-color: rgba(31, 42, 93, 0.20);
    ;
    border-radius: 210.5px;
    box-shadow: 0px 15px 30px #00000040;
}

@media (min-width: 1700px) and (max-width: 3000px) {
    .frame .ellipse {
        width: 491px;
        height: 511px;
        top: 0;
        left: 0;
        border-radius: 268px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        position: absolute;
        background-color: rgba(31, 42, 93, 0.05);
        ;
    }

    .frame .logo-left {
        position: absolute;
        width: 297px;
        height: 146px;
        top: 115px;
        left: 314px;
    }
    .frame .we-are-under {
        position: absolute;
        top: 397px;
        left: 416px;
        font-family: "Inter", sans-serif;
        font-weight: 600;
        color: #ffffff;
        font-size: 66px;
        letter-spacing: 1.28px;
        line-height: 80px;
    }

    .frame .text-wrapper {
        position: absolute;
        width: 540px;
        top: 568px;
        left: 431px;
        font-weight: 600;
        color: #ffffff;
        font-size: 19.3px;
        letter-spacing: 1.48px;
        word-spacing: 4px;
    }

    .frame .ellipse-6 {
        position: absolute;
        width: 469px;
        height: 508px;
        top: 677px;
        left: -76px;
        background-color: rgba(31, 42, 93, 0.20);
        border-radius: 227.5px;
        box-shadow: 0px 15px 30px #00000040;
    }
    .frame .ellipse-4 {
        width: 267px;
        height: 269px;
        left: -12px;
    
    }

    .frame .overlap-group-2 {
        left: 256px;
        top: 18px;
    }

    .frame .group {
        position: absolute;
        width: 571px;
        height: 691px;
        top: 202px;
        left: 233px;
        z-index: 2;
    }
    .frame .ellipse-3 {
        width: 400px;
        height: 473px;
        top: 260px;
        left: 655px;
        border-radius: 245.5px;
        box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
        position: absolute;
        background-color: rgba(31, 42, 93, 0.20);
    }

    .frame .vector {
        position: absolute;
        width: 325px;
        height: 214px;
        top: 583px;
        left: 535px;
    }

    .frame .img {
        position: absolute;
        width: 302px;
        height: 175px;
        top: 759px;
        left: 349px;
        z-index: 2;
    }

    .frame .ellipse-7 {
        position: absolute;
        width: 497px;
        height: 525px;
        top: 815px;
        left: 392px;
        background-color: rgba(31, 42, 93, 0.20);
        border-radius: 326.5px;
        box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
        z-index: 0;
    }
    .frame .vector-3 {
        position: absolute;
        width: 250px;
        height: 128px;
        top: 663px;
        left: 128px;
    }

    .frame .vector-2 {
        position: absolute;
        width: 327px;
        height: 198px;
        top: 422px;
        left: 117px;
    }
    
    
    
    
}