.vt_controller_buttons{
    width: 28px;
    height: 28px;
    background: transparent linear-gradient(135deg, #22046B 0%, #C577AA 100%) ;
    border-radius: 4px;
    margin: 4px 13px;
}
.vt_controller_showspeed{
    width: 28px;
    height: 28px;
    background: transparent;
    border-radius: 4px;
    margin: 4px 13px;
    padding: 2px 0px 0px 6px;
    color: white;
    cursor:"none";
}

.vt_controller__box{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.vt_controller__image{
    background: transparent;
    width: 18px;
    cursor: pointer;
}

/* added style for reset due to small svg size */
.vt_controller__image_reset {
    background: transparent;
    width: 28px;
    cursor: pointer;
}

.vt_controller__image__disabled{
    background: transparent;
    width: 18px; 
    cursor: not-allowed !important;
}

.vt_controller__image_rt_180{
    background: transparent;
    width: 18px;
    cursor: pointer;
    rotate: 180deg;
}

.vt_controller__image_rt_180_diabled{
    background: transparent;
    width: 18px;
    cursor: not-allowed !important;
    rotate: 180deg;
}

.vt__play-pause-control, .vt__speed-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
}