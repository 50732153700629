.new-dashboard {
    height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* gap: 15px; */
}
 .nd__sidebar {
    height: 100%;
    flex: 0 0 35%;
    border-radius: 20px 20px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: linear-gradient(142deg, rgba(27, 51, 81, 0.30) 19.63%, rgba(155, 97, 149, 0.30) 83.01%);
    box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    border-top-right-radius: 10px;
    overflow: hidden;
}

.nd__sidebar-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nd__sidebar-body-list {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    margin-top: 15px;
}

.nd__sidebar-body-list::-webkit-scrollbar {
    width: 8px;
}

.nd__sidebar-body-list::-webkit-scrollbar-track {
    background: #5D4F75 !important;
}

.nd__sidebar-body-list::-webkit-scrollbar-thumb {
    background: #0B0C12 !important;
    width: 6px;
}

.nd__map-container {
    height: 100%;
    flex: 1 0 55%;
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    border-top-left-radius: 10px;
    position: relative;
    overflow: hidden;
}

.new-dashboard-map {
    height: calc(100vh - 70px);
    width: 100%;
}

.nd__sidebar-body-details {
    height: 100%;
    width: 100%;
    margin-top: 11px;
}


.new-dashboard-map {
    height: calc(100vh - 70px);
    width: 100%;
}

.nd__sidebar-body-details {
    height: 100%;
    width: 100%;
    margin-top: 11px;
}

.new-dashboard-dark {
    height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.nd__vehicle-details-and-visuals-container {
    height: 100%;
    width: calc(100% - 30%);
    margin-top: 11px;
}

.nd__vehicle-info-continer {
    height: 100%;
    width: calc(100% - 70%);
}

.nd__vehicle-details-and-visuals-container .nd__dark-vehicle-details-container {
    height: calc(100% - 40%);
    width: 100%;
    background-color: #111;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.nd__vehicle-details-and-visuals-container .nd__vehicle-visuals-container {
    height: calc(100% - 60%);
    width: 100%;
    background-color:  #111;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.nd__dark-vehicle-details-container .nd__dark-vehicle-details {
    width: calc(100% - 55%);
    background-color: #111;
    padding: 0 15px;
}

.nd__dark-vehicle-details-container .nd__vehicle-map {
    height: 100%;
    width: calc(100% - 45%);
    background-color: #111;
}

.nd__vehicle-visuals-container .nd__vehicle-webcam {
    height: 100%;
    width: calc(100% - 45%);
}

.nd__vehicle-visuals-container .nd__vehicle-cluster-meter {
    height: 100%;
    width: calc(100% - 45%);
    background-color: #111111;
}
/*conflict-resolution-will be removed later*/