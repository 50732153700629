.UploadFileContainer{
  margin-top: 15%;
  z-index: 9999 !important;
}
.modelHeader{
  padding: 25px 18px !important;
  display: block !important;
}
.modelHeaderClose{
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 13px;
}
.modelFooter{
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-bottom: 20px;
  gap: 14px;
}
.modelCancelButton{
  background: #EFF3F8;
  color: #1F2A5D;
  font-family: Poppins !important;
  font-size: 12px;
  width: 83px;
  height: 40px;
  border-radius: 5px;
}
.modelSaveButton{
  background: #22046B;
  color: #FFFFFF;
  font-family: Poppins !important;
  font-size: 12px;
  width: 83px;
  height: 40px;
  border-radius: 5px;
}

.errorModal {
margin-top: 15%;
z-index: 9999 !important;
}
.closeButtonError{
  display: flex;
  justify-content: flex-end;
  margin: 18px;
}


.title {
  font-size: 1.5rem;
  color: #22046b; 
}

.successMessage {
  display: flex;
  align-items: center;
  border: 1px solid #90E1A0;
  color: #155724; 
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.errorMessage {
  display: flex;
  flex-direction: column;
  background-color: #f8d7da; 
  border: 1px solid #f5c6cb; 
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}


.closeButton {
  cursor: pointer;
}
.accordianItem2{
  border: 1px solid #E94240 !important;
}
.errorIcon{
  margin-left: -9px;
  margin-right: 10px;
}
.successIcon{
  margin-right: 10px;
}
.errorIconCollapse{
  margin-right:10px
}


.vehicleItem {
display: flex;
justify-content: space-between;
align-items: center;
padding: 5px 0;
font-size: 16x;
}

.copyButton {
background: none;
border: none;
cursor: pointer;
display: flex;
align-items: center;
justify-content: flex-end;
}

.copyButtonBox {
border-left: 1px solid #ddd; 
padding-left: 10px;
}

.vehicleItem span {
flex-grow: 1; 
}

.accordianContainer{
display: flex;
flex-direction: column;
}

