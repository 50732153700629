.operation-type-button {
    background: #E7ECF7 0% 0% no-repeat padding-box;
    width: 150px;
    padding: 7px 0px 7px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #1F2A5D;
    cursor: pointer;
    border-radius: 2px;
}

.operation-type-button.selected {
    font-size: 14px;
    color: #FFFFFF;
    background-color: #22046B;
}   