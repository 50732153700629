.btn-cancle-reject{
    margin-right: 15px;
    background-color: #EEEEEE;
    color: #1F2A5D;
    padding: 5px 15px 5px 15px;
    }
    .btn-delete-reject{
    margin-left: 15px;
    background-color: #1F2A5D;
    color: #ffffff;
    padding: 5px 15px 5px 15px;
    }
    .delete-reject-text{
     font: normal normal 500 18px/27px Inter;
    letter-spacing: 0px;
    color: #1F2A5D;
    }