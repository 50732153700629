.seekbar-container {
    width: 100%;
}

.vts__seekbar-wrapper {
    padding: 10px;
}
.vts__seekbar-wrapper .vts__range-wrapper {
    position: relative;
}
.vts__seekbar-wrapper .vts__range-wrapper .vts__range-progress {
    position: absolute;
    left: 0px;
    top: 11px;
    height: 6px;
    background: #1F2A5D;
    border-radius: 10px 0 0 10px;
}
.vts__seekbar-wrapper .vts__range-wrapper .vts__range-picker {
    width: 100%;
    appearance: none;
    border-radius: 10px;
    height: 7px;
}

.vts__seekbar-wrapper .vts__seekbar-timeline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    text-align: center;
}

.vts__seekbar-wrapper .vts__seekbar-timeline p {
    margin: 0;
    color: #797979;
    font-size: 12px;
    font-weight: 500;
}
.vts__seekbar-wrapper .vts__seekbar-timeline p + p {
    margin-top:  3px;
}

.vts__statusbar-wrapper {
    background-color: #F3F3F3;
    padding: 15px 10px;
}

.vts__vehiclestatus-statusbar,
.vts__ignition-statusbar {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}
.vts__ignition-statusbar {
    margin-bottom: 10px;
    border-radius: 10px;
}
.vts__ignition-statusbar .vts__statusbar-slot {
    height: 10px;
    background-color: #DE54E3;
    flex: 1 0 auto;
}
.vts__vehiclestatus-statusbar .vts__statusbar-slot {
    height: 10px;
    background-color: #DE54E3;
    flex: 1 0 auto;
}
.vts__vehiclestatus-statusbar .vts__statusbar-slot.vts__vehiclestatus-statusbar--immobile {
    background-color: rgb(224, 96, 0)
}
.vts__vehiclestatus-statusbar .vts__statusbar-slot.vts__vehiclestatus-statusbar--live {
    background-color: rgb(21, 199, 168);
}
.vts__vehiclestatus-statusbar .vts__statusbar-slot.vts__vehiclestatus-statusbar--idle {
    background-color: #F29900;
}
.vts__vehiclestatus-statusbar .vts__statusbar-slot.vts__vehiclestatus-statusbar--charging {
    background-color: rgb(84, 145, 245);
}

.vts__vehiclestatus-statusbar .vts__statusbar-slot.vts__vehiclestatus-statusbar--ncom {
    background-color: #DE54E2;   
}

.vts__ignition-statusbar .vts__statusbar-slot.vts__statusbar-slot--on {
    background-color: #00CD5E;
}
.vts__ignition-statusbar .vts__statusbar-slot.vts__statusbar-slot--off {
    background-color: #E30505;
}

.vts__tracking-indicator {
    height: 63px;
    border-right: 1px dotted #111;
    position: absolute;
    left: 17.5px;
    top: 15px;
}