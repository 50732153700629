.notification-panel {
    max-height: 400px;
    width: 370px;
    position: absolute;
    top: 72px;
    right: 1px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 9999;
}
.notification-dark-panel {
    max-height: 400px;
    width: 370px;
    position: absolute;
    top: 89px;
    right: 1px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 9999;
}

.notification-bar {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border: #e7e7e7 1px solid;
    border-radius: 4px;
    width: 98%;
    height: max-content;
    margin: 10px 5px;
    padding: 10px 15px;
}

.bar-row1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bar-title-div {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    min-width: 140px;
}

.bar-date {
    text-align: left;
    font: normal normal 500 12px Inter;
    color: #6D6D6D;
    margin-bottom: 0;
    margin-top: 0;
}

.bar-status-date{
    position: absolute;
    right: 20px;
    top: 16px;
    text-align: left;
    font: normal normal 500 12px Inter;
    color: #6D6D6D;
    margin-bottom: 0;
    margin-top: 0;
}

.bar-title {
    text-align: left;
    font: normal normal 600 16px Inter;
    color: #10214B;
    margin-bottom: 8px;
    line-height: 1.1;
}

.bar-separartor {
    height: 0px;
    width: 100%;
    border: 1px solid #CDD7E4;
    margin-top: 5px;
    margin-bottom: 5px;
}

.bar-progress {
    height: 10px;
    width: 1%;
    border: 1px solid #CDD7E4;
    background: #55bd71;
    margin-top: 5px;
    margin-bottom: 5px;
}

.bar-progress-border {
    height: 10px;
    width: 327px;
    position: absolute;
    border: 1px solid #CDD7E4;
    margin-top: 5px;
    margin-bottom: 5px;
}

.bar-row2 {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.bar-message {
    text-align: left;
    font: normal normal 500 12px Inter;
    color: #6D6D6D;
    flex: 1 1 auto;
    margin-bottom: 0;
}

.bar-status {
    height: 25px;
    flex: 0 0 auto;
    color: #FFFFFF;
    font: normal normal 500 12px Inter;
    justify-content: center;
    display: inline-flex;
    min-width: 95px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 2px;
    background: #009b88;
    margin-top: 18%;
}

.bar-click-here {
    text-decoration: underline;
    font-style: oblique;
    cursor: pointer;
    color: blueviolet;
}

.notification-panel::-webkit-scrollbar {
    width: 4px !important;
}

.notification-panel::-webkit-scrollbar-track {
    background: #FFFFFF !important;
}

.notification-panel::-webkit-scrollbar-thumb {
    background: #C2C2C2 !important;
    border: 3px solid transparent !important;
    border-radius: 6px !important;
    background-clip: content-box !important;
}
