.heading-gt {
	color: #1f2a5d;
}


.alert-tab-container {
	background: #fff;
	box-shadow: 0px 3px 6px #00000029;
	padding: 10px 3rem;
	margin-left: -30px;
}


.alert-recent-th {
	color: #1f2a5d ;
	top: 229px;
	left: 284px;
	width: 70px;
	height: 28px;
	background: none;
	text-align: center;
	font: normal normal 500 14px/20px Inter;
	letter-spacing: 0px;
}

.alert-recent-td {
	font: normal normal normal 12px/20px Inter !important;
	color: #1f2a5d  !important;
}

.alert-recent-td-span {
	background: none !important;
}

.alert-recent-btn {
	background: none !important;
}


.dwn-history-btn {
	color: #ffffff;
	padding: 0px 13px;
	border-radius: 4px;
	font-size: 21px;
	height: 45px;
	border: unset;
	background-color: #22046B ;
}

.dwn-history-btn-disabled {
	background-color: #808080;
	color: #ffffff;
	padding: 0px 13px;
	border-radius: 4px;
	font-size: 21px;
	height: 45px;
	border: unset;
}

.alert-history-select-container {
	display: flex;
	justify-content: space-between;
	padding: 5px 0 5px 0;
}

/* .alert-history-select {
	width: 15%;
} */

.alert-history-radio-container {
	display: flex;
	width: 34%;
	justify-content: space-between
}

.alert-recent-history-tr {
	color: #1F2A5D;
	vertical-align: baseline;
}

.alert-history-getReport {
	width: 10%;
}

.alert-history-dwn {
	/* margin-left: 2rem; */
}

.alert-history-table-container {
	width: 100%;
	box-shadow: 0px 3px 6px #00000029;
}



.alert__date-picker .no-date-selected,
.alert__date-picker .no-date-selected .rs-btn-default {
	background-color: #F5F7F9 !important;
}


.alert-radio-input:checked[type=radio] {
	accent-color: #22046B
}


.alert-radio-label {
	position: relative;
	top:-2px;
}

.no-value-selected .css-1bd069q-control,
.no-value-selected .css-1rguokk-control,
.no-value-selected .css-14pogwu,
.no-value-selected .css-1ymyizl-ValueContainer {
   background-color: #F5F7F9 !important;
   color: #F5F7F9 !important;
   border: 0;
}

.no-value-selected .css-1bd069q-control,
.no-value-selected .css-1pr4nsb,
.no-value-selected .css-m1j589-ValueContainer{
   background-color: #F5F7F9 !important;
   color: #F5F7F9 !important;
   border: 0;
}

.history-tab-container .tab-content {
	margin-top: 10px;
}

.charging-tab-container .tab-content {
	margin-top: 15px;
}