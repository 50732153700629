.container {
  height: calc(100vh - 100px);
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  row-gap: 15px;
  margin: 15px;
}

.formWrapper {
  flex: 0 0 440px;
}

.mapWrapper {
  flex: 1 0 auto;
  overflow: hidden;
  border-radius: 12px;
}

.tabLink {
  padding: 10px 0px;
  text-align: center;
  cursor: pointer;
  color: #8f9bd4;
  font-size: 20px;
  font-family: Inter !important;
  font-weight: 600;
}

.activeTab {
  border-bottom: 4px solid #1F2A5D;
  padding-left: 10px 0px;
  color: #1F2A5D;
  position: relative;
}
.tabsBorder {
  border-bottom: 1px solid #e3e0e0;
  position: relative;
  top: 36px;
}
.formContainer {
  width: 97%;
    flex: 0 0 440px;
    position: relative;
}

.mapWrapper {
    flex: 1 0 auto;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
}
.mapWrapperDisabled {
    background-color: rgba(255, 255, 255, 0.45);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: none;
    pointer-events: none;
}

.tabPanel {
  height: calc(100vh - 120px);
  overflow: auto;
}
.tabPanel::-webkit-scrollbar {
  width: 10px !important;
}
.tabPanel::-webkit-scrollbar-track {
  background: #f0f0f0 !important;
}
.tabPanel::-webkit-scrollbar-thumb {
  background: #22046b !important;
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  background-clip: content-box !important;
}