.mainContainer{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer {
    max-width: 26rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 40px 30px 40px;
    overflow-y: hidden;
    border-radius: 20px;
    border: 1px solid rgba(51, 66, 255, 0.00);
    background: rgba(31, 44, 100, 0.70);
    box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.50), 0px 100px 100px 0px rgba(0, 0, 0, 0.25);
    position: relative;
}

.loginContainer::before{
    content: '';
    position: absolute;
    top: 0; 
    left: 10%;
    width: 80%;
    height: 1px;
    background: linear-gradient(90deg, transparent, #A6F9FF, transparent);
    z-index: 10;
}

.submit {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    background: linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602EA6 0%, #C977D6 100%);
    background-blend-mode: overlay, normal;
    color: #ffffff;
}

.signIn {
    color: rgba(255, 255, 255, 0.70);
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}

.modalTextForgotConfirm {
    margin-top: 20px;
    color: #FFF !important;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 94px;

}

.resetContainer {
    height: auto;
    width: 27rem;
    display: flex;
    flex-direction: column;
    padding: 40px 80px 0 80px;
    overflow-y: hidden;
    border-radius: 20px;
    border: 1px solid rgba(51, 66, 255, 0.00);
    background: rgba(31, 44, 100, 0.70);
    box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.50), 0px 100px 100px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    padding-bottom: 0.9375rem;
}

.resetContainer::before{
    content: '';
    position: absolute;
    top: 0; 
    left: 10%;
    width: 80%;
    height: 1px;
    background: linear-gradient(90deg, transparent, #A6F9FF, transparent);
    z-index: 10;
}

.forgotContainer{
    padding: 5px 106px; 
}
.forgotform{
    padding: 40px 35px 10px 0;
}
.forgotbutton {
    width: 89% !important;
    margin-top: 10px;
    margin-bottom: 45px;
    
}
.forgotText{
    font-size: 14px;
    color: white;
}
.signupText,
.backToSignIn {
    color: #00CFFD;
    font-size: 13px;
    text-decoration: underline !important;
    font-weight: 600;
}
.signupText:hover,
.backToSignIn:hover {
    color: #00CFFD !important;
    text-decoration: underline !important;
}

.forgotPasswordText{
    color: #00CFFD;
    font-size: 12px;
    text-decoration: none !important;
}
.forgotPasswordText:hover{
    color: #00CFFD !important;
    text-decoration: underline !important;
}

.rememberMeText {
    color: white;
    font-size: 12px;
}

.contactUs, .emailUs {
    font-size: 13px;
    color: white;
    font-weight: 600;
    margin-bottom: 5px;
}
.telephoneText,
.emailText {
    color: #00CFFD;
    font-size: 13px;
    text-decoration: none !important;
}
.emailText {
    text-decoration: underline !important;
}
.telephoneText:hover,
.emailText:hover {
    color: #00CFFD !important;
    text-decoration: underline !important;
}
/*conflict-resolution-will be removed later*/