.modal-content,
.modal-header {
  border: unset !important;
  padding-bottom: 0px !important;
}

.modal-title {
  font-size: 15px !important;
  font-weight: 700 !important;
  text-align: center !important;
  margin-bottom: 25px !important;
  color: #1f2a5d !important;
}

.modal-body {
  padding-top: 0px !important;
}

.modal-footer {
  border: unset !important;
  padding-top: 0px !important;
  display: block !important;
}

.btn-close {
  color: #acacac !important;
  padding: 3px !important;
  height: 10px !important;
  width: 10px !important;
  margin-top: -3px !important;
  margin-right: -3px !important;
}

.save-btn {
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 0px 4px !important;
  height: 35px !important;
  width: 60px !important;
  border-radius: 4px !important;
  border: unset;
  background-color: #22046B;
  color: #ffffff;
}

.save-btn-disabled {
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 0px 4px !important;
  height: 35px !important;
  width: 60px !important;
  border-radius: 4px !important;
  border: unset;
  background-color: #acacac;
  color: #ffffff;
}

.error-msg {
  margin-right: 10px !important;
}

.width-86 {
  width: 86%;
}

.width-12 {
  width: 12%;
}
