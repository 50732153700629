.mobilizeCommandStatusBtn {
  border-radius: 4px;
  color: #ffffff;
  background-color: #f63f3f;
  width: 125px;
  height: 36px;
  margin-top: 5px;
}

.mobilizeTable th {
  color: #10204b !important;
  font-size: 16px !important;
  vertical-align: middle;
}

.mobilizeTable td {
  color: #10204b !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.mobilizeTable {
  border-collapse: separate;
  border-spacing: 1.2px 0px;
}

.immobilize-non-com-status {
  padding: 8px 18px 8px 18px;
  font-weight: 400;
  color: #f0142f;
  background-color: #fcd5d9;
  border-radius: 4px;
}

.immobilize-charging-status {
  padding: 8px 20px 8px 20px;
  font-weight: 400;
  color: #1e5eff;
  background-color: #c8d6fc;
  border-radius: 4px;
}

.immobilize-parked-status {
  padding: 8px 25px 8px 25px;
  font-weight: 400;
  color: #78550e;
  background-color: #ffdc96;
  border-radius: 4px;
}

.immobilize-live-status {
  padding: 8px 35px 8px 35px;
  font-weight: 400;
  color: #06a561;
  background-color: #c4f8e2;
  border-radius: 4px;
  text-align: center;
}
.immobilize-idle-status {
  padding: 8px 25px 8px 25px;
  font-weight: 400;
  color: #000;
  background-color: #eff3f8;
  border-radius: 4px;
  width: 100px;
}

.mobilizeActionBtn {
  width: 125px;
  height: 50px;
  border-radius: 6px;
  background-color: #22046b;
  color: #ffffff;
}

.mobilizeReasonBtn {
  padding: 10px;
  border-radius: 11px;
  background-color: #22046b;
  color: #ffffff;
  margin-left: 21%;
  width: 32%;
  position: absolute;
  top: 75%;
}
.reasonBtnForMobilize {
  width: 149px;
  height: 50px;
  border-radius: 6px;
  background-color: #cecece;
  color: #535c68;
}

.dwnRefresh {
  width: 125px;
  height: 46px;
  top: 332px;
  left: 1572px;
  padding: 12px 8px 12px 8px;
  gap: 16px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  background-color: #ffffff;
  border: 1px solid #ffe9f6;
  font-weight: 500;
  color: #10204b;
  margin-right: 10px;
  text-align: center;
}

.reasonOtherTextarea {
  width: 377px;
  height: 128px;
  top: 170px;
  left: 78px;
  gap: 0px;
  background: #efe1fc;
  border: none;
  border-radius: 4px;
  margin-top: 15px;
  padding: 15px;
  color: #10204b !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  resize: none;
}

.reasonOtherTextarea:focus-visible {
  outline: none !important;
}

/* tooltip-css */

.popover {
  background-color: #22046b !important;
  z-index: 10000 !important;
}

.popover :first-child::after {
  border-right-color: #22046b !important;
}
.popover :first-child::after {
  border-left-color: #22046b !important;
}

.popoverHeader {
  background-color: #22046b;
  color: white;
  border: 0;
}

.popoverBody {
  background-color: #22046b;
  border-radius: 5px;
}

.immobilize-communication-status {
  padding: 8px;
  font-weight: 400;
  color: #F0142F;
  background-color: #fcd5d9;
  border-radius: 4px;
  width: 100px;
}