/* scroll css customization*/

::-webkit-scrollbar {
  height: 12px;
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track:horizontal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 2px 9px #00000029;
  opacity: 1;
}
::-webkit-scrollbar-track:vertical {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 2px 9px #00000029;
  opacity: 1;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
  background: #c2c2c2 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}
::-webkit-scrollbar-thumb:vertical {
  background: #c2c2c2 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.ncr-search-icon {
  margin-right: 20px;
  color: #797694;
}

.ncr-search-input {
  background-color: #e7ecf7;
  border-radius: 4px;
  border: none;
  outline: none;
}

th,
td {
  color: #1f2a5d !important;
}

.hr-line-blue-nc-width {
  width: auto;
  max-width: max-content;
}

.hr-line-ncl-width {
  width: 350px;
  max-width: 350px;
}

.hr-line-ncl2-width {
  width: 47%;
  max-width: 47%;
}

.hr-line-blue-ncl-width {
  width: 262px;
  max-width: 262px;
}

#uncontrolled-tab-example-tab-non_comm_report , #uncontrolled-tab-example-tab-non_comm_log{
  padding-right: 0% !important;
  top: 0.8px;
}

#uncontrolled-tab-example-tab-non_comm_log{
  margin-left: 40px;
}
#uncontrolled-tab-example-tab-history{
  margin-left: 40px;
}

.non_comm_report-tab-container > .nav-tabs .nav-link.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  top: 31px;
  background-color: #10214B;
}

.container-fluid .nonCommLogsFilter{
  margin-top: 10px
}

.search-bar-div.connectivity-search-bar-div {
  min-width: 200px;
}
.search-bar-div.connectivity-search-bar-div .vob-search-icon {
  margin-left: 20px;
}
.dwnRefresh {
  width: 125px;
  height: 46px;
  top: 332px;
  left: 1572px;
  padding: 12px 8px 12px 8px;
  gap: 16px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  background-color: #ffffff;
  border: 1px solid #ffe9f6;
  font-weight: 500;
  color: #10204b;
  margin-right: 10px;
  text-align: center;
}

.non-comm-table td {
  font-size: 13px !important;
  font-weight: 600;
  color: white !important;
}

.non-comm-table td:first-child {
  color: black !important;
}

.fixed-width {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  font-weight: 800;
}
