.nd__tracking-legend-card {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 15px;
    border-radius: 4px;
}

.nd__tlc__ignition-on, .nd__tlc__ignition-off, .nd__tlc__ignition-no-data {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px
}

.nd__dark-tlc__ignition-on .nd__tlc_ignition-box {
    height: 9px;
    width: 35px;
    background-color: #00CD5E;
}

.nd__dark-tlc__ignition-off .nd__tlc_ignition-box {
    height: 9px;
    width: 35px;
    background-color: #E30505;
}

.nd__dark-tlc__ignition-no-data .nd__tlc_ignition-box {
    height: 9px;
    width: 40.03px ;
    background-color: #DE54E2;
}

.nd__dark-tlc__ignition-on p, .nd__dark-tlc__ignition-off p, .nd__dark-tlc__ignition-no-data p {
    margin: 0;
    font-size: 0.75rem;
    color: #FFFFFF;
    margin-left: 10px;
}

.nd__tlc__ignition-no-data .nd__tlc_ignition-box {
    height: 9px;
    width: 35px;
    background-color: #C9C9C9;
}

.nd__tlc__vehicle-status {
    width: 232px;
}

.nd__tlc__ignition-status, .nd__tlc__divider, .nd__tlc__vehicle-status {
    height: 100%;
    height: 100%;
    color: #10214B;
    font-size: 16px !important;
    font-weight: 500;   
}

.nd__tlc__divider {
    width: 3px;
}

.nd__tlc__vehicle-status-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0px 10px;
}

.tlc__status-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px
}

.nd_Dark .tlc__status-container p {
    margin: 0;
    font-size: 0.75rem;
    color: #fff;
}

.tlc__outer-circle {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 2px;
}

.tlc__inner-circle {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.tlc__vehicle-status-icon {
    display: inline-flex;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: relative;
}
.tlc__vehicle-status-icon .tlc__vehicle-status-tip {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    top: -7px;
    border-bottom-width: 6px;
    border-bottom-style: solid;
}

.nd_Dark .nd__dark-tlc__vehicle-status-text {
    color :#33E8FF;
    font-size: 14px;
}
.nd__dark-tlc__vehicle-status{
    color :#33E8FF;
    font-size: 14px;
    padding: 0px 10px;
}

.nd__dark-tlc__vehicle-status-text-child {
    color:#FFF;
}